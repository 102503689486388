import moment from 'moment';
import React, { Component } from 'react';
import BigNumber from "bignumber.js";
class Functions {
}

Functions.dateForm = function (date) {
    return date ? date.replace(/T.+/, '') : '';
}
Functions.dateUsForm = function (date) {
    return date ? date.replace(/T.+/, '').replace(/(.+)-(.+)-(.+)/, '$2/$3/$1') : '';
}
Functions.dateTimeUsForm = function (date) {
    return date ? date.replace('T', '-').replace(/\..+/, '').replace(/(.+)-(.+)-(.+)-(.+)/, '$2/$3/$1 $4') : '';
}
Functions.crfDateForm = function (date) {
    let format = "MM/DD/YYYY"
    if (date) {
        format = format.replace(/YYYY/, date.getFullYear());
        format = format.replace(/MM/, date.getMonth() + 1);
        format = format.replace(/DD/, date.getDate());
    }
    return date ? format : '';
}

Functions.dollarForm = function (amount) {
    return amount != undefined ? "$" + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : amount;
}
Functions.qtyForm = function (amount) {
    return amount != undefined ? amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : amount;
}
Functions.percentageForm = function (x) {
    return x != undefined ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%" : x;
}

// Functions.decimalAddition = function (a, b) {
//     if (a == undefined || b == undefined) {
//         return 0
//     }
//     if (!isFinite(a) || !isFinite(b)) {
//         return 0
//     }
//     if ((a.toString().indexOf('e') != -1) || (b.toString().indexOf('e') != -1)) {
//         return 0
//     }

//     var aArr = a.toString().split('.'), bArr = b.toString().split('.');
//     var aDecimalPart = (aArr.length === 2) ? aArr[1].length : 0;
//     var bDecimalPart = (bArr.length === 2) ? bArr[1].length : 0;
//     var longDecimalPart = (aDecimalPart > bDecimalPart) ? aDecimalPart : bDecimalPart;

//     var transCalcNumber = (a * Math.pow(10, longDecimalPart)) + (b * Math.pow(10, longDecimalPart));

//     return this.orgFloor(transCalcNumber / Math.pow(10, longDecimalPart), 100);
// }

// Functions.decimalSubtraction = function (a, b) {
//     if (a == undefined || b == undefined) {
//         return 0
//     }
//     if (!isFinite(a) || !isFinite(b)) {
//         return 0
//     }
//     if ((a.toString().indexOf('e') != -1) || (b.toString().indexOf('e') != -1)) {
//         return 0
//     }

//     var aArr = a.toString().split('.'), bArr = b.toString().split('.');
//     var aDecimalPart = (aArr.length === 2) ? aArr[1].length : 0;
//     var bDecimalPart = (bArr.length === 2) ? bArr[1].length : 0;
//     var longDecimalPart = (aDecimalPart > bDecimalPart) ? aDecimalPart : bDecimalPart;

//     var transCalcNumber = (a * Math.pow(10, longDecimalPart)) - (b * Math.pow(10, longDecimalPart));

//     return this.orgFloor(transCalcNumber / Math.pow(10, longDecimalPart), 100);
// }

// Functions.decimalMultiplication = function (a, b) {
//     if (a == undefined || b == undefined) {
//         return 0
//     }
//     if (!isFinite(a) || !isFinite(b)) {
//         return 0
//     }
//     if ((a.toString().indexOf('e') != -1) || (b.toString().indexOf('e') != -1)) {
//         return 0
//     }

//     var aArr = a.toString().split('.'), bArr = b.toString().split('.');
//     var aDecimalPart = (aArr.length === 2) ? aArr[1].length : 0;
//     var bDecimalPart = (bArr.length === 2) ? bArr[1].length : 0;

//     var transCalcNumber = (a * Math.pow(10, aDecimalPart)) * (b * Math.pow(10, bDecimalPart));

//     var lastCalcNumber = Math.pow(10, aDecimalPart + bDecimalPart);

//     return this.orgFloor(transCalcNumber / lastCalcNumber, 100);
// }

Functions.decimalPlaceRound = function (value, digit) {
    if (digit == undefined) {
        digit = 2;
    }
    let result = Number(Functions.decimalDivision(Math.round(Functions.decimalMultiplication(value, 100)), 100));
    result = isNaN(result) ? 0 : result;
    return result.toFixed(digit);
}

Functions.toPercentage = function (value) {
    return (Functions.decimalDivision(value, 100));
}

Functions.toNumberToFixed = function (value, digit) {
    if (digit == undefined) {
        digit = 2;
    }
    let result = Number(value);
    return (isNaN(result) ? value : result.toFixed(digit));
}

Functions.toTermsCostOfMoneyPerDayFixed = function (value) {
    let result = Number(value);
    return (isNaN(result) ? value + '%' : result.toFixed(4) + '%');
}

// Functions.decimalDivision = function (a, b) {
//     if (a == undefined || b == undefined) {
//         return 0
//     }
//     if (!isFinite(a) || !isFinite(b)) {
//         return 0
//     }
//     if ((a.toString().indexOf('e') != -1) || (b.toString().indexOf('e') != -1)) {
//         return 0
//     }

//     var aArr = a.toString().split('.'), bArr = b.toString().split('.');
//     var aDecimalPart = (aArr.length === 2) ? aArr[1].length : 0;
//     var bDecimalPart = (bArr.length === 2) ? bArr[1].length : 0;
//     var longDecimalPart = (aDecimalPart > bDecimalPart) ? aDecimalPart : bDecimalPart;

//     return (a * Math.pow(10, longDecimalPart)) / (b * Math.pow(10, longDecimalPart));
// }

Functions.calculatePromofromthru = function (date1, date2) {
    if (!date1 || !date2) {
        return true;
    }
    if (moment(date1).isSame(date2)) {
        return true;
    } else if (moment(date1).isBefore(date2)) {
        return true;
    }
    return false;
}

Functions.changehtml = function (string) {
    if (typeof string !== 'string') {
        return string;
    }
    return string.replace(/&amp;/g, '&')
        .replace(/&#x27/g, "'")
        .replace(/&#x60;/g, "`")
        .replace(/&quot;/g, '"')
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">");
}

Functions.pafIdComparison = function (a, b) {
    let aIdArray = a.split("-");
    let bIdArray = b.split("-");
    // PafId Undefined Format
    if (aIdArray.length < 3) {
        return false;
    }
    if (bIdArray.length < 3) {
        return true;
    }
    // Comparison
    if (aIdArray[0] == bIdArray[0]) {
        if (aIdArray[2] == bIdArray[2]) {
            // Revision
            return aIdArray[1] <= bIdArray[1];
        } else {
            // Sequence
            return aIdArray[2] <= bIdArray[2];
        }
    } else {
        // Company Id
        return aIdArray[0] <= bIdArray[0];
    }
}

Functions.csvString = function (string) {
    if (typeof string !== 'string') {
        return string;
    }
    return '"' + string.replace(/"/g, '""') + '"'
}

Functions.changeNewLine = function (string) {
    let str = ''
    if (string && (string != '' || string != null)) {
        str = string.split("\n")
            .reduce((accum, x) => [...accum, x, <br />], [])
            .slice(0, -1)
    }
    return str;
}

Functions.checkFileName = function (fileName) {
    let error = false;
    fileName = fileName.toLowerCase();

    if (fileName.indexOf('"') != -1 || fileName.indexOf("'") != -1 || fileName.indexOf('<') != -1 ||
        fileName.indexOf('>') != -1 || fileName.indexOf('[') != -1 || fileName.indexOf(']') != -1 || fileName.indexOf('|') != -1
        || fileName.indexOf(';') != -1 || fileName.indexOf(':') != -1 || fileName.indexOf('#') != -1 || fileName.indexOf('?') != -1 || fileName.indexOf('@') != -1
        || fileName.indexOf('$') != -1 || fileName.indexOf('&') != -1 || fileName.indexOf('=') != -1 || fileName.indexOf('+') != -1
        || fileName.indexOf('*') != -1 || fileName.indexOf(',') != -1 || fileName.indexOf('/') != -1 || fileName.indexOf('%') != -1 || fileName.indexOf('・') != -1) {
        error = true;
    };

    return error;
}

const Buffer = require('buffer/').Buffer
/**
 * Get the byte length of a string
 * @param {string} s //string
 * @returns {number} //Byte length of input string
 */
Functions.byteLengthOf = function (s) {
    return Buffer.byteLength(s);
}

Functions.checkNumber = function (num) {
    if (isNaN(num)) {
        return false
    }
    return true;
}

Functions.checkDecimal = function (num, target) {
    if (num != null && num != '') {
        var array = num.toString().split('.')[1];
        if (array) {
            if (array.length <= target) {
                return true;
            }
            return false;
        }
        return true;
    }
    return true;
}

Functions.getExt = function (filename) {
    var pos = filename.lastIndexOf('.');
    if (pos === -1) return '';
    return filename.slice(pos + 1);
}

Functions.toBoolean = function (str) {
    return str.toLowerCase() === 'true';
}

/**
 * 任意の桁で切り捨てする関数
 * @param {number} value 切り捨てする数値
 * @param {number} base どの桁で切り捨てするか（10→10の位、0.1→小数第１位）
 * @return {number} 切り捨てした値
 */
Functions.orgFloor = function (value, base) {
    return Math.floor(value * base) / base;
}

// use bignumber.js
Functions.decimalAddition = function (a, b) {
    if (a === "" || a === null || a === undefined) {
        a = 0;
    }
    if (b === "" || b === null || b === undefined) {
        b = 0;
    }
    if (!isFinite(a) || !isFinite(b)) {
        return 0
    }
    if ((a.toString().indexOf('e') != -1) || (b.toString().indexOf('e') != -1)) {
        return 0
    }
    
    let aVal = new BigNumber(a);
    let bVal = new BigNumber(b);
    
    let rtnVal = aVal.plus(bVal);
    return rtnVal.toNumber().toFixed(2);
}

Functions.decimalSubtraction = function (a, b) {
    if (a === "" || a === null || a === undefined) {
        a = 0;
    }
    if (b === "" || b === null || b === undefined) {
        b = 0;
    }
    if (!isFinite(a) || !isFinite(b)) {
        return 0
    }
    if ((a.toString().indexOf('e') != -1) || (b.toString().indexOf('e') != -1)) {
        return 0
    }
    
    let aVal = new BigNumber(a);
    let bVal = new BigNumber(b);
    
    let rtnVal = aVal.minus(bVal);
    return rtnVal.toNumber().toFixed(2);
}

Functions.decimalMultiplication = function (a, b) {
    if (a === "" || a === null || a === undefined) {
        a = 0;
    }
    if (b === "" || b === null || b === undefined) {
        b = 0;
    }
    if (!isFinite(a) || !isFinite(b)) {
        return 0
    }
    if ((a.toString().indexOf('e') != -1) || (b.toString().indexOf('e') != -1)) {
        return 0
    }
    
    let aVal = new BigNumber(a);
    let bVal = new BigNumber(b);
    
    let rtnVal = aVal.times(bVal);
    return rtnVal.toNumber().toFixed(2);
}

Functions.decimalDivision = function (a, b) {
    if (a === "" || a === null || a === undefined) {
        a = 0;
    }
    if (b === "" || b === null || b === undefined) {
        b = 0;
    }
    if (!isFinite(a) || !isFinite(b)) {
        return 0
    }
    if ((a.toString().indexOf('e') != -1) || (b.toString().indexOf('e') != -1)) {
        return 0
    }

    let aVal = new BigNumber(a);
    let bVal = new BigNumber(b);
    
    let rtnVal = aVal.div(bVal);
    return rtnVal.toNumber().toFixed(2);
}

export default Functions;
