"use strict";
export default class {
    constructor(dataObject = null) {
        this.ShipToNumber = '';
        this.ShipToName = '';
        this.Address = '';
        this.City = '';
        this.State = '';
        this.ZipCode = '';
        this.Country = '';
        this.ShipVia = '';
        this.Amount = '';

        //Import data if set
        if (dataObject) {
            try {
                this.ShipToNumber = dataObject.ShipToNumber;
                this.ShipToName = dataObject.ShipToName;
                this.Address = dataObject.Address;
                this.City = dataObject.City;
                this.State = dataObject.State;
                this.ZipCode = dataObject.ZipCode;
                this.Country = dataObject.Country;
                this.ShipVia = dataObject.ShipVia;
                this.Amount = dataObject.Amount;
            }
            catch (e) {
                throw "Data import error @ ShipToInformation data.";
            }
        }
    }

    getDataObject() {
        return {
            ShipToNumber: this.ShipToNumber,
            ShipToName: this.ShipToName,
            Address: this.Address,
            City: this.City,
            State: this.State,
            ZipCode: this.ZipCode,
            Country: this.Country,
            ShipVia: this.ShipVia,
            Amount: this.Amount,
        }
    }
}