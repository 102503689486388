import * as Constant from '../Constants/Constant';

import RunParts from '../Models/JbasePartsOrder/RunParts';
import ShiptoInfo from '../Models/JbasePartsOrder/ShiptoInfo';
import ShipVia from '../Models/JbasePartsOrder/ShipVia';
import OrderFromJbaseOpenHeader from '../Models/OrderFromJbaseOpenHeader';
import OrderFromJbaseOpenDetail from '../Models/OrderFromJbaseOpenDetail';
import OrderFromJbaseCloseHeader from '../Models/OrderFromJbaseCloseHeader';
import OrderFromJbaseCloseDetail from '../Models/OrderFromJbaseCloseDetail';
import OrderLog from '../Models/OrderLog';
import FavoriteParts from '../Models/FavoriteParts';
import SessionUserInfo from '../Models/SessionUserInfo';

const initialState = {
    // Common
    tabStatus: (findGetParam("order") != null ? Constant.STAT_INVOICE : Constant.STAT_CONTACTUS),
    orderHeaderId: (findGetParam("order") != null ? findGetParam("order") : null),
    errorMessage: '',
    reloadFlag: false,
    systemRetireModalDisplayedFlag: false,

    // Login System
    loginData: {},
    reset: (findGetParam("reset") != null ? findGetParam("reset") : null),
    resetParamIsLoading: false,
    resetParamIsLoadingError: false,
    resetParamModal: false,
    resetPasswordPage: false,
    resetPassIsLoading: false,
    resetPassIsLoadingError: false,
    resetPassModal: false,
    customerNumList: [],
    selectedCustomerNum: '',
    loginIsLoading: false,
    loginIsLoadingError: false,
    loginErrorMessage: {
        login: '',
        passwordRegistration: '',
        resetPassword: '',
    },
    isOpenTermsConditionsModal: false,
    isOpenSystemRetireModal: false,

    // Order List
    sortBy: 'order-desc',
    currentPage: 1,
    itemsPerPage: 30,
    orderList: [],
    selectOrderData: [],
    orderListErrorMessage: {
        orderList: '',
        orderEmpty: '',
        invoice: '',
    },
    // InvoiceDateFrom/OrderDateFromの一番古い日付を保持する
    theOldestInvoiceOrOrderDateFrom: new Date(),

    // Order Entry
    warehouseOption: [{ text: "100", value: "100" }, { text: "200", value: "200" }],
    currentModalPage: 1,
    itemsInModalPerPage: 10,
    cart: [],
    category: [],
    orderQtyList: [],
    warehouse: "100",
    orderEntryErrorMessage: {
        orderEntry: '',
        searchModal: '',
        favoriteModal: '',
        orderConfirm: '',
    },
    orderEntryFormIsSaving: false,
    orderEntryFormIsSavingError: false,
    orderEntryCompleteModal: false,
    openRunPartsSearchResultModal: false,
    sessionDataLoading: false,
    sessionDataError: false,
    isCartUpdated: false,
    orderEntryAddPartLoadingError: false,

    // partsMaster: [],
    // partsMasterLoading: false,
    // partsMasterLoadingError: false,

    // Contact Us
    contactUsMailSending: false,
    contactUsMailSendingSuccess: false,
    contactUsMailSendingError: false,
    openContactUsSendModal: false,
    contactUsErrorMessage: '',

    // Jbase
    shipInfo: [],
    openOrderHeaders: [],
    closeOrderHeaders: [],
    orderLogs: [],
    runSearchData: [],
    partsSearching: false,
    partsSearchingError: false,
    acceptOrder: '',
    ordersLoading: false,
    ordersLoadingError: false,

    // Favorite Parts
    favoriteParts: [],
    favoritePartsUpdatingError: false,
    favoritePartsUpdating: false,
}

export default function reducer(state = initialState, action) {

    switch (action.type) {
        // Common
        case Constant.ACT_MENUCHANGE:
            return Object.assign({}, state, {
                tabStatus: action.tabStatus,
            });

        // Login system
        //#region
        case Constant.ACT_LOGINRESULT:
            let shipInfo = action.shipInfo;
            let shiptos = [];
            let categoryInfo = action.category;
            let categories = [];
            let customerNumList = [];
            let customerNumbers = action.customerNumList;

            customerNumbers.map(x => {
                customerNumList.push(x);
            })

            if (shipInfo) {
                shipInfo.map(x => {
                    let shiptoInfo = new ShiptoInfo(x);
                    x.shipVia.map(y => {
                        let shipVia = new ShipVia(y);
                        shiptoInfo.ShipVias.push(shipVia);
                    })
                    shiptos.push(shiptoInfo);
                });
            }

            if (categoryInfo) {
                categoryInfo.map(x => {
                    categories.push(x.category);
                })
            }

            return Object.assign({}, state, {
                loginData: action.loginData,
                customerNumList: customerNumList,
                shipInfo: shiptos,
                category: categories,
                selectedCustomerNum: action.loginData.CustomerNumber,
            });

        case Constant.ACT_SETCUSTOMERNUMBERLIST:
            let loginData = {};
            let loginDataList = action.loginDataList;

            loginData.UserId = loginDataList.UserId;
            loginData.Access = loginDataList.Access;
            loginData.Token = loginDataList.Token;

            return Object.assign({}, state, {
                loginData: loginData,
                customerNumList: action.customerNumList,
            });

        case Constant.ACT_LOGINRESULTBYCUSTOMERNUM:
            let shipInfoResult = action.shipInfo;
            let shiptoList = [];
            let categoryInfoResult = action.category;
            let categoryList = [];

            if (shipInfoResult) {
                shipInfoResult.map(x => {
                    let shiptoInfo = new ShiptoInfo(x);
                    x.shipVia.map(y => {
                        let shipVia = new ShipVia(y);
                        shiptoInfo.ShipVias.push(shipVia);
                    })
                    shiptoList.push(shiptoInfo);
                });
            }

            if (categoryInfoResult) {
                categoryInfoResult.map(x => {
                    categoryList.push(x.category);
                })
            }

            return Object.assign({}, state, {
                loginData: action.loginData,
                shipInfo: shiptoList,
                category: categoryList,
                selectedCustomerNum: action.loginData.CustomerNumber,
            });

        case Constant.ACT_SETCUSTOMERNUMBER:
            return Object.assign({}, state, {
                selectedCustomerNum: action.customerNumber,
                loginData: action.loginData,
            });

        case Constant.ACT_LOGINISLOADING:
            return Object.assign({}, state, {
                loginIsLoading: action.loginIsLoading,
            });

        case Constant.ACT_LOGINISLOADING_ERROR:
            var loginErrorMessage = {
                login: action.message,
                passwordRegistration: '',
                resetPassword: '',
            };
            var orderListErrorMessage = {
                orderList: state.orderListErrorMessage.orderList,
                orderEmpty: state.orderListErrorMessage.orderEmpty,
                invoice: '',
            };
            if (action.message != '') {
                orderListErrorMessage.orderList = action.message;
                orderListErrorMessage.orderEmpty = action.message;
            }
            var orderEntryErrorMessage = {
                orderEntry: action.message,
                searchModal: '',
                favoriteModal: '',
                orderConfirm: '',
            };
            return Object.assign({}, state, {
                loginIsLoadingError: action.loginIsLoadingError,
                loginErrorMessage: loginErrorMessage,
                orderListErrorMessage: orderListErrorMessage,
                orderEntryErrorMessage: orderEntryErrorMessage,
                contactUsErrorMessage: action.message,
            });

        case Constant.ACT_ISTERMSCONDITIONSMODAL:
            return Object.assign({}, state, {
                isOpenTermsConditionsModal: action.bool,
            })

        case Constant.ACT_SETRELOADFLAG:
            return Object.assign({}, state, {
                errorMessage: action.message,
                reloadFlag: action.bool,
            });
        //#endregion

        // Reset password
        //#region
        case Constant.ACT_RESETPASSWORDPAGE:
            return Object.assign({}, state, {
                resetPasswordPage: action.resetPasswordPage,
            });

        case Constant.ACT_RESETPARAMISLOADING:
            return Object.assign({}, state, {
                resetParamIsLoading: action.resetParamIsLoading,
            });

        case Constant.ACT_RESETPARAMISLOADING_ERROR:
            var loginErrorMessage = {
                login: '',
                passwordRegistration: '',
                resetPassword: action.message,
            }
            return Object.assign({}, state, {
                resetParamIsLoadingError: action.resetParamIsLoadingError,
                loginErrorMessage: loginErrorMessage,
            });

        case Constant.ACT_OPENRESETMODAL:
            return Object.assign({}, state, {
                resetParamModal: action.resetParamModal,
            });

        case Constant.ACT_RESETPASSISLOADING:
            return Object.assign({}, state, {
                resetPassIsLoading: action.resetPassIsLoading,
            });

        case Constant.ACT_RESETPASSISLOADING_ERROR:
            var loginErrorMessage = {
                login: '',
                passwordRegistration: action.message,
                resetPassword: '',
            };
            return Object.assign({}, state, {
                resetPassIsLoadingError: action.resetPassIsLoadingError,
                loginErrorMessage: loginErrorMessage,
            });

        case Constant.ACT_OPENRESETPASSMODAL:
            return Object.assign({}, state, {
                resetPassModal: action.resetPassModal,
            });
        //#endregion

        // Order List
        //#region
        case Constant.ACT_CHANGESORTBY:
            return Object.assign({}, state, {
                sortBy: action.sortBy,
            });

        // Change items per page
        case Constant.ACT_CHANGEITEMSPERPAGE:
            if (action.logFlag) {
                if (state.orderLogs.length < action.itemsPerPage * (state.currentPage - 1)) {
                    return Object.assign({}, state, {
                        currentPage: 1,
                        itemsPerPage: action.itemsPerPage,
                    });
                }
            } else {
                let orderList = state.openOrderHeaders.concat(state.closeOrderHeaders);
                if (orderList.length < action.itemsPerPage * (state.currentPage - 1)) {
                    return Object.assign({}, state, {
                        currentPage: 1,
                        itemsPerPage: action.itemsPerPage,
                    });
                }
            }
            return Object.assign({}, state, {
                itemsPerPage: action.itemsPerPage,
            });

        // Select page
        case Constant.ACT_SELECTPAGE:
            if (action.logFlag) {
                if (state.orderLogs.length < state.itemsPerPage * (action.currentPage - 1)) {
                    return Object.assign({}, state, {
                        currentPage: 1,
                    });
                }
            } else {
                let orderList = state.openOrderHeaders.concat(state.closeOrderHeaders);
                if (orderList.length < state.itemsPerPage * (action.currentPage - 1)) {
                    return Object.assign({}, state, {
                        currentPage: 1,
                    });
                }
            }
            return Object.assign({}, state, {
                currentPage: action.currentPage,
            });

        case Constant.ACT_MOVE_INVOICE:
            return Object.assign({}, state, {
                selectOrderData: action.selectOrderData,
                tabStatus: Constant.STAT_INVOICE,
            });

        // Back to Order list
        case Constant.ACT_BACKTOLIST:
            return Object.assign({}, state, {
                tabStatus: Constant.STAT_ORDERLIST,
            });


        case Constant.ACT_THEOLDESTDATEFROMCHANGE:
            return Object.assign({}, state, {
                theOldestInvoiceOrOrderDateFrom: action.date,
            });
        //#endregion

        // Order Entry
        //#region
        case Constant.ACT_ORDERENTRY_SAVING_SUCCESS:
            return Object.assign({}, state, {
                acceptOrder: action.acceptOrder,
                orderEntryCompleteModal: action.orderEntryCompleteModal,
                cart: [],
                warehouse: "100",
            });

        case Constant.ACT_ORDERENTRY_SAVING:
            return Object.assign({}, state, {
                orderEntryFormIsSaving: action.orderEntryFormIsSaving,
            });

        case Constant.ACT_ORDERENTRY_SAVING_ERROR:
            var orderEntryErrorMessage = {
                orderEntry: '',
                searchModal: '',
                favoriteModal: '',
                orderConfirm: action.message,
            };
            return Object.assign({}, state, {
                orderEntryFormIsSavingError: action.orderEntryFormIsSavingError,
                orderEntryErrorMessage: orderEntryErrorMessage,
            });

        case Constant.ACT_ORDERENTRY_CLOSE_COMPLETEMODAL:
            return Object.assign({}, state, {
                orderEntryCompleteModal: action.bool,
            });

        case Constant.ACT_ORDERENTRY_CART:
            return Object.assign({}, state, {
                cart: action.cart,
                orderQtyList: action.orderQtyList,
                warehouse: action.warehouse,
                isCartUpdated: true,
            });

        case Constant.ACT_ORDERENTRY_CART_ERROR:
            var orderListErrorMessage = {
                orderList: state.orderListErrorMessage.orderList,
                orderEmpty: state.orderListErrorMessage.orderEmpty,
                invoice: '',
            };
            if (action.message != '') {
                orderListErrorMessage.orderList = action.message;
                orderListErrorMessage.orderEmpty = action.message;
            }
            var orderEntryErrorMessage = {
                orderEntry: action.message,
                searchModal: action.message,
                favoriteModal: action.message,
                orderConfirm: '',
            };
            return Object.assign({}, state, {
                sessionDataError: action.sessionDataError,
                orderListErrorMessage: orderListErrorMessage,
                orderEntryErrorMessage: orderEntryErrorMessage,
                contactUsErrorMessage: action.message,
            });

        case Constant.ACT_ORDERENTRY_SELECT_MODAL_PAGE:
            if (state.runSearchData.length < state.itemsInModalPerPage * (action.currentModalPage - 1)) {
                return Object.assign({}, state, {
                    currentModalPage: 1,
                });
            }
            return Object.assign({}, state, {
                currentModalPage: action.currentModalPage,
            });

        case Constant.ACT_SESSIONDATALOADING:
            return Object.assign({}, state, {
                sessionDataLoading: action.sessionDataLoading,
            });

        case Constant.ACT_ORDERENTRY_ADDPART_ERROR:
            var orderEntryErrorMessage = {
                orderEntry: action.message,
                searchModal: action.message,
                favoriteModal: action.message,
                orderConfirm: '',
            };
            return Object.assign({}, state, {
                orderEntryAddPartLoadingError: action.orderEntryAddPartLoadingError,
                orderEntryErrorMessage: orderEntryErrorMessage,
            });

        // // get parts master data
        // case Constant.ACT_ORDERENTRY_PARTSMASTER:
        //     return Object.assign({}, state, {
        //         partsMaster: action.partsMaster,
        //     });

        // case Constant.ACT_ORDERENTRY_PARTSMASTER_LOADING:
        //     return Object.assign({}, state, {
        //         partsMasterLoading: action.partsMasterLoading,
        //     });

        // case Constant.ACT_ORDERENTRY_PARTSMASTER_LOADING_ERROR:
        //     return Object.assign({}, state, {
        //         var orderEntryErrorMessage = {
        //             orderEntry: action.message,
        //             searchModal: '',
        //             favoriteModal: '',
        //             orderConfirm: '',
        //         };
        //         partsMasterLoadingError: action.partsMasterLoadingError,
        //         orderEntryErrorMessage: orderEntryErrorMessage,
        //     });
        //#endregion

        // Contact Us
        //#region
        case Constant.ACT_CONTACTUS_MAILSENDING:
            return Object.assign({}, state, {
                contactUsMailSending: action.contactUsMailSending,
            });

        case Constant.ACT_CONTACTUS_MAILSENDING_SUCCESS:
            return Object.assign({}, state, {
                contactUsMailSendingSuccess: action.contactUsMailSendingSuccess,
                openContactUsSendModal: true,
            });

        case Constant.ACT_CONTACTUS_MAILSENDING_ERROR:
            return Object.assign({}, state, {
                contactUsMailSendingError: action.contactUsMailSendingError,
                contactUsErrorMessage: action.message,
            });

        case Constant.ACT_CONTACTUS_CLOSE_SENDINGMODAL:
            return Object.assign({}, state, {
                openContactUsSendModal: action.bool,
            });
        //#endregion

        // Connect WCF Service
        // Open/Close orders
        //#region
        case Constant.ACT_JBASE_PARTSORDERSBYCUSTOMER:
            let openOrderHeaders = [];
            let closeOrderHeaders = [];
            let orderLogs = [];
            let openOrderHeadersData = action.orders.rtnOrderOpenHeaders;
            let closedOrderHeadersData = action.orders.rtnOrderCloseHeaders;
            let orderLogsData = action.orders.rtnOrderLogs;

            if (openOrderHeadersData.length > 0) {
                for (let item of openOrderHeadersData) {
                    let orderData = new OrderFromJbaseOpenHeader(item);
                    orderData.Details = action.orders.rtnOrderOpenDetails.filter(x => x.openHeaderId == orderData.Id).map(x => new OrderFromJbaseOpenDetail(x));
                    // If order status is open, set ShipCharge from OrderLog table
                    let foundLog = orderLogsData.find(x => x.pONumber == orderData.PONumber);
                    if (orderData.ShipCharge == 0 && foundLog) {
                        orderData.ShipCharge = foundLog.shipCharge;
                    }
                    openOrderHeaders.push(orderData);
                }
            }

            if (closedOrderHeadersData.length > 0) {
                for (let item of closedOrderHeadersData) {
                    let orderData = new OrderFromJbaseCloseHeader(item);
                    orderData.Details = action.orders.rtnOrderCloseDetails.filter(x => x.closeHeaderId == orderData.Id).map(x => new OrderFromJbaseCloseDetail(x));
                    closeOrderHeaders.push(orderData);
                }
            }

            if (orderLogsData.length > 0) {
                for (let log of orderLogsData) {
                    let orderLog = new OrderLog(log);
                    orderLogs.push(orderLog);
                }
            }

            return Object.assign({}, state, {
                openOrderHeaders: openOrderHeaders,
                closeOrderHeaders: closeOrderHeaders,
                orderLogs: orderLogs,
            });

        case Constant.ACT_JBASE_PARTSClOSEORDERS:
            let closeOrders = [];
            let closedOrdersData = action.orders.rtnOrderCloseHeaders;

            if (closedOrdersData.length > 0) {
                for (let item of closedOrdersData) {
                    let orderData = new OrderFromJbaseCloseHeader(item);
                    orderData.Details = action.orders.rtnOrderCloseDetails.filter(x => x.closeHeaderId == orderData.Id).map(x => new OrderFromJbaseCloseDetail(x));
                    closeOrders.push(orderData);
                }
            }

            return Object.assign({}, state, {
                closeOrderHeaders: closeOrders,
            });

        case Constant.ACT_JBASE_DELETEPARTSORDERS:
            return Object.assign({}, state, {
                openOrderHeaders: action.openOrderHeaders,
                closeOrderHeaders: action.closeOrderHeaders,
                orderLogs: action.orderLogs,
            });

        case Constant.ACT_JBASE_PARTSORDERSBYCUSTOMER_LOADING:
            return Object.assign({}, state, {
                ordersLoading: action.ordersLoading,
            });

        case Constant.ACT_JBASE_PARTSORDERSBYCUSTOMER_LOADING_ERROR:
            var orderListErrorMessage = {
                orderList: action.message,
                orderEmpty: action.message,
                invoice: '',
            };
            return Object.assign({}, state, {
                ordersLoadingError: action.ordersLoadingError,
                orderListErrorMessage: orderListErrorMessage,
            });
        //#endregion

        // RunPartsSearch
        //#region
        case Constant.ACT_JBASE_RUNPARTSSEARCH:     // Call at Order Entry
            let runParts = [];
            let runPartsData = action.runParts;

            if (runPartsData.length > 0) {
                for (let item of runPartsData) {
                    let data = new RunParts(item);
                    runParts.push(data);
                }
            }
            return Object.assign({}, state, {
                runSearchData: runParts,
                openRunPartsSearchResultModal: action.openRunPartsSearchResultModal,
            });

        case Constant.ACT_JBASE_PARTSSEARCHING:
            return Object.assign({}, state, {
                partsSearching: action.partsSearching,
            });

        case Constant.ACT_JBASE_PARTSSEARCHING_ERROR:
            var orderEntryErrorMessage = {
                orderEntry: action.message,
                searchModal: '',
                favoriteModal: '',
                orderConfirm: '',
            };
            return Object.assign({}, state, {
                partsSearchingError: action.partsSearchingError,
                orderEntryErrorMessage: orderEntryErrorMessage,
            });

        case Constant.ACT_JBASE_RUNPARTSSEARCH_CLOSE_MODAL:
            return Object.assign({}, state, {
                openRunPartsSearchResultModal: action.openRunPartsSearchResultModal,
            });
        //#endregion

        // Favorite Parts
        //#region
        case Constant.ACT_FAVORITEPARTS:
            let favoriteParts = action.favoriteParts.map(x => new FavoriteParts(x));

            return Object.assign({}, state, {
                favoriteParts: favoriteParts
            });

        case Constant.ACT_FAVORITEPARTS_LOADING:
            return Object.assign({}, state, {
                favoritePartsUpdating: action.favoritePartsUpdating
            });

        case Constant.ACT_FAVORITEPARTS_LOADING_ERROR:
            var orderListErrorMessage = {
                orderList: state.orderListErrorMessage.orderList,
                orderEmpty: state.orderListErrorMessage.orderEmpty,
                invoice: action.message,
            };
            var orderEntryErrorMessage = {
                orderEntry: action.message,
                searchModal: action.message,
                favoriteModal: action.message,
                orderConfirm: '',
            };
            return Object.assign({}, state, {
                favoritePartsUpdatingError: action.favoritePartsUpdatingError,
                orderListErrorMessage: orderListErrorMessage,
                orderEntryErrorMessage: orderEntryErrorMessage,
            });
        //#endregion

        case Constant.ACT_SETISOPENSYSTEMRETIREMODAL:
            console.log({
                systemRetireModalDisplayedFlag: state.systemRetireModalDisplayedFlag,
                ...action
            })
            // 一度表示されたら再度表示する必要はない
            let isOpenSystemRetireModal = action.isLogin && state.systemRetireModalDisplayedFlag ? false : action.flag;
            return Object.assign({}, state, {
                isOpenSystemRetireModal,
                systemRetireModalDisplayedFlag: true,
            });

        default:
            return state;
    }
}

function findGetParam(paramName) {
    var result = null,
        tmp = [];
    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === paramName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}


