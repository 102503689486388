import React from 'react';
import { connect } from 'react-redux';

import { Modal, Button, Icon, Header } from 'semantic-ui-react';

import { mapStateToProps, mapDispatchToProps } from '../../Root';

export class TermsAndConditionsModal extends React.Component {
    constructor(props) {
        super(props);

        this.isTermsConditionsModalClose = this.isTermsConditionsModalClose.bind(this);
    }

    isTermsConditionsModalClose() {
        this.props.action.isTermsConditionsModal(false);
        this.props.action.setIsOpenSystemRetireModal(true);
    }

    render() {
        let styleModal = { marginTop: 0, margin: 'auto', };

        return (
            <Modal open={this.props.Reducer.isOpenTermsConditionsModal} style={styleModal}>
                <Modal.Header>JVCKENWOOD USA CORPORATION<br />Service Parts Purchase and Firmware and Publication Download</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Header as='h3'>Terms and Conditions of Sale and/or License</Header>
                        <p>JVCKENWOOD USA Corporation (“Seller” or “JKUSA”), hereby offers to sell  the parts and/or license software and/or publications to account holder’s (“Account Holder’s”) the service parts/products, software and services (“Products”) described at the prices indicated thereon as applicable, subject to the terms and, conditions (“Terms”) set forth below.  Seller’s quotation is expressly subject to these terms and conditions.   Seller hereby refuses inclusion of any terms proposed or provided by Account Holder with its purchase order and Seller’s delivery of the Products shall not constitute an assent to any such terms.</p>
                        <br />

                        <ol>
                            <li>Account Holder agrees to purchase the Products listed in the portal at the price specified thereon.  Account Holder’s order for Seller’s standard Products can be cancelled or changed prior to shipment.  No cancellations will be accepted after shipment for any reason.</li>
                            <br />

                            <li>Partial shipment and partial performance are authorized by Account Holder.  Seller will issue invoices to Account Holder for all Products as they are shipped or performed.  Payment shall be made net 30 days from date of invoice.  The purchase price does not include sales, use, excise or similar taxes, custom duties, or transfer fees, all of which shall be Account Holder’s responsibility.   Such taxes and fees, if paid by Seller, shall be charged to Account Holder.  Account Holder agrees to pay to Seller interest at the rate of one and one half percent (1½%) per month on all amounts past due until payment is made.  Failure to make payments when due shall give Seller the right to refuse to fill any unshipped orders, to stop any shipment in transit, and to exercise any other rights it may have as a Seller.  Account Holder agrees to pay Seller all reasonable expenses, including but not limited to attorney’s fees, of collection of accounts past due.  Letters of Credit or wire payments are required in advance of shipment.  If, in Seller’s judgment, the financial condition of Account Holder at any time prior to receipt of Products by Account Holder, does not justify continuance of the work or shipment of the Products, Seller may, at its option, require full or partial payment in advance, or may cancel an order or any part thereof.</li>
                            <br />

                            <li>All shipments shall be made FOB Origin at Seller’s shipment point unless otherwise designated, and delivery for shipment to a carrier or as otherwise designated at the FOB point shall constitute delivery thereof by Seller to Account Holder.  All Products are shipped at Account Holder’s risk, and Seller shall incur no liability for Products damaged, broken or lost in transit.  If shipping instructions are not provided by Account Holder to Seller, Seller may pack and ship the products in such a manner as Seller shall determine without incurring any obligation or liability to Account Holder on account of the manner in which Seller causes same to be packed or shipped.</li>
                            <br />

                            <li>Products sold hereunder carry Seller's standard product or service warranty in effect at the time of the sale.  ACCOUNT HOLDER AGREES TO INDEMNIFY AND HOLD SELLER HARMLESS: (I) FROM ANY BREACH BY ACCOUNT HOLDER OF THESE TERMS; AND (II) FROM ANY AND ALL CLAIMS, LIABILITIES, DAMAGES, INJURIES AND LOSSES ARISING FROM ITS RESPECTIVE ACTS OR OMISSIONS REGARDING THE REPAIR AND SERVICE ARISING FROM AND/OR RELATED TO THE PRODUCTS AND/OR RELATED TO ANY STANDARDS OR REQUIREMENTS REGARDING SUCH REPAIR AND SERVICE ACTIVITIES. SELLER MAKES NO OTHER WARRANTY, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.    THE TOTAL LIABILITY OF SELLER ON ANY AND ALL CLAIMS, WHETHER BASED ON CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE OR PATENT INFRINGEMENT) OR OTHERWISE, ARISING OUT OF, CONNECTED WITH, OR RESULTING FROM THE PERFORMANCE OR NON-PERFORMANCE OF ANY AGREEMENT RESULTING HEREFROM OR FROM THE MANUFACTURE, SALE, DELIVERY, RESALE, REPAIR, REPLACEMENT OR USE OF ANY PRODUCTS OR FURNISHING OF ANY SERVICE SHALL NOT EXCEED THE PRICE ALLOCABLE TO THE PRODUCTS OR SERVICE WHICH GIVES RISE TO THE CLAIM.  EXCEPT AS TO TITLE, ANY SUCH LIABILITY SHALL TERMINATE UPON THE EXPIRATION OF THE APPLICABLE WARRANTY PERIOD THEREOF IF ANY.  IN NO EVENT, WHETHER AS A RESULT OF BREACH OF CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE OR PATENT INFRINGEMENT) OR OTHERWISE, SHALL SELLER BE LIABLE FOR PERSONAL INJURY OR LOSS, PROPERTY DAMAGE, OR EXPENSE ARISING FROM THE USE OF SELLER’S PRODUCTS OR FOR ANY SPECIAL, CONSEQUENTIAL, INCIDENTAL, INDIRECT OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED, TO LOSS OF PROFIT OR REVENUES, LOSS OF THE USE OF THE PRODUCTS OR ANY ASSOCIATED PRODUCTS, COST OF CAPITAL, COST OF SUBSTITUTE GOODS, FACILITIES, SERVICES OR REPLACEMENT POWER, DOWNTIME COSTS OR CLAIMS OF THE ACCOUNT HOLDER’S CUSTOMERS FOR SUCH DAMAGES.</li>
                            <br />

                            <li>Seller reserves the right at any time to discontinue any Product and to make changes or improvements in specifications or design in such Products without incurring any obligations to Account Holder with respect to the past or future sale of such Products.</li>
                            <br />

                            <li>Prices and delivery dates apply only if this offer is accepted within thirty (30) days from the date the offer (the “validity period”) is originated.  This offer expires upon the expiration of this validity period or such extension of the validity period as Seller may grant in writing.  Seller reserves the right to adjust sell prices on existing orders when warranted due to increase in gold costs, connector prices, or any other specially purchased materials necessary to complete existing orders.</li>
                            <br />

                            <li>Account Holder may inspect the products and/or confirm the quantity of the Product upon delivery.  If special or source inspection is required, man hours necessary for Seller to assist in the performance of it are chargeable at the Seller’s inspection rate. Claims for defective or incorrect parts, or shortages, must be reported to Seller in writing within two (2) weeks after Account Holder’s receipt of the Products.  Failure to make such a claim within the time specified shall relieve Seller of all responsibility therefore.  NO RETURNS ARE ALLOWED WITHOUT PRIOR WRITTEN AUTHORIZATION OF SELLER.  In the absence of such prior authorization, Seller assumes no responsibility for returned Products nor does it agree to grant a credit therefore. Products are to be returned in accordance with Seller’s then current return policy.  Warranty returns must be made on the terms stated in the Seller’s standard product warranty in effect at the time of sale.</li>
                            <br />

                            <li>Account Holder is solely responsible for obtaining and complying with any necessary permits and licenses for any International, Federal, State or local government authority, related to the purchase, installation, construction or operation of any Products purchased hereunder.</li>
                            <br />

                            <li>Account Holder agrees that it may have access to certain confidential information of the Seller including, but not limited to, sales, marketing technical and support information, processes, compilations of information, records, and specifications that are owned by Seller (“Seller Confidential Information”).  Account Holder acknowledges and agrees that Seller Confidential Information constitutes valuable, special and unique property of Seller, and further acknowledges that protection of such Seller Confidential Information and trade secrets against unauthorized disclosure and use is of critical importance to Seller.  Account Holder agrees that it will not disclose Seller Confidential Information to any third party, or make any use thereof except as provided under these Terms.</li>
                            <br />

                            <li>Account Holder agrees that it will comply with all U.S. and international export laws and that it will not directly or indirectly export, re-export, resell, ship, or divert any product or technical data or software furnished hereunder to any country for which the U.S. or applicable country, at the time of export, requires an export license or other governmental approval without first obtaining such license or approval.</li>
                            <br />

                            <li>Seller shall not be liable for delays in delivery or performance or for failure to manufacture, deliver, or perform due to (i) acts of God, acts of civil or military authority, governmental priorities, supplier shortages, strikes or other labor disturbances, floods, epidemics, war, riots, or delays in transportation; or (ii) causes beyond the reasonable control of Seller or its suppliers, including inability to obtain necessary material components, services or facilities.  In the event of any such delay, the date of delivery or performance shall be extended for a period equal to the time lost by reason of such delay.</li>
                            <br />

                            <li>These Terms shall be construed in accordance with the State of California, USA without regard to its choice of law provisions.</li>
                            <br />

                            <li>The following Section 13 applies to the download of firmware and/or publications provided by Seller.  In the event of conflicting terms regarding this subject matter between the following Section 13 and Sections 1-12 above, the following Terms shall govern.  Subject to the conditions below, JVCKENWOOD USA Corporation grants to Account Holder a right to use the firmware software and publication materials (“Downloaded Content”) for the product stated therein.  By downloading the Downloaded Content, Account Holder  agrees to be bound by the conditions set forth below.</li>
                            <ol type="A">
                                <li>Copyright Protection, Intellectual Property Ownership:  All copyrights and other intellectual property rights in the Downloaded Content are owned by JKUSA and/or its parent company, JVCKENWOOD Corporation (“JKC”), and or its respective authors or licensors and remains vested in the same.  The Downloaded Content is protected under copyright law of Japan and other countries, and related conventions.</li>
                                <li>Grant of License:  Subject to the conditions of the applicable Terms, JKUSA grants Account Holder a non-exclusive and non-transferable right to use the Downloaded Content.  Account Holder may download the Downloaded Content to Account Holder’s specific PC and install the Downloaded Content connected to the specific PC.  Account Holder may make copies of the Downloaded Content for specific usage intended, back-up and storage purposes only.  Each such copy must contain copyright and/or other proprietary rights notices in the same manner as originally appeared.  If copies are made by on-line or other media, Account Holder must display notices as required to appropriately protect copyright and other proprietary rights of JKUSA and JKC.</li>
                                <li>Restriction:  i) Account Holder may not modify, reverse engineer, decompile or disassemble the Downloaded Content in any manner; ii) Account Holder has no right to grant a license to use the Downloaded Content and may not distribute, lease or rent the Downloaded  Content to any other person and/or any third party for any purpose; iii) Account Holder shall comply with the laws and regulations of the applicable countries of the Downloaded Content.</li>
                                <li>LIMITED WARRANTY: THE DOWNLOADED CONTENT IS PROVIDED “AS-IS” WITHOUT WARRANTY OF ANY KIND.  NEITHER JKUSA, JKC NOR THIRD PARTY AUTHORS OR LICENSORS MAKE ANY GUARANTEE OF ANY KIND, EXPRESS OR IMPLIED, REGARDING THE DOWNLOADED CONTENT’S MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.  SHOULD THERE BE ANY PROBLEM ARISING FROM OR CAUSED BY THE DOWNLOADED CONTENT, ACCOUNT HOLDER SHOULD SETTLE ALL SUCH PROBLEMS AT ITS OWN COSTS AND RESPONSIBILITIES.</li>
                                <li>LIMITATION OF LIABLITY:  NEITHER JKUSA, JKC NOR LICENSORS HAVE ANY LIABILITY WITH RESPECT TO ITS OBLIGATIONS UNDER THESE TERMS OR OTHERWISE FOR CONSEQUENTIAL, EXEMPLARY, INCIDENT EVEN IF IT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  ACCOUNT HOLDER WILL INDEMNIFY AND HOLD JKUSA, JKC AND THIRD PARTY AUTHOR OR LICENSOR HARMLESS FROM ANY LOSS, LIABILITY OR COSTS ARISING OUT OF OR IN ANY WAY CONNECTED TO CLAIMS FROM ANY OTHER PERSONS RELATING TO THE USE OF THE SOFTWARE.</li>
                                <li>Term:  These Terms will become effective on the date when Account Holder downloads and/or installs the Downloaded Content onto the specific PC and shall continue to be in effect until the termination as set forth in Section 13.G. below.</li>
                                <li>End of Term:  Should Account Holder breach any provision of these Terms, JKUSA may terminate the Terms and respective rights without giving Account Holder notice.  In this event, JKUSA may claim against Account Holder any damages caused by Account Holder’s breach.  Should these Terms be terminated, Account Holder must discontinue the use of the Downloaded Content and shall not be allowed to possess the Downloaded Content.</li>
                            </ol>
                            <br />

                            <li>These Terms constitute the entire understanding between the Account Holder and the Seller concerning the subject hereof, any representation, promise, understanding, proposal, agreement, warranty, course of dealing or trade usage not expressly contained or referenced herein shall not be binding on Seller.  No modification, amendment, rescission, waiver, or other change shall be binding on Seller unless agreed in writing by Seller.  SELLER DOES NOT ASSUME ANY OBLIGATIONS OR LIABILITES IN CONNECTION WITH THE SALE OF ITS PRODUCTS OTHER THAN THOSE EXPRESSLY STATED IN THESE TERMS, AND DOES NOT AUTHORIZE ANY PERSON (INCLUDING SELLER’S DIRECT SALES FORCE AND SALES AGENTS) TO ASSUME FOR SELLER ANY OTHER OBLIGATIONS OR LIABILITIES.</li>
                            <br />
                        </ol>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary onClick={this.isTermsConditionsModalClose}>
                        Confirmed <Icon name='right chevron' />
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TermsAndConditionsModal);
