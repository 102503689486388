import React from 'react';
import { connect } from 'react-redux';
import AppConfig from '../../AppConfig';

import { Form, Header, Icon, Segment, Button, Modal, Message, Dimmer, Loader, Dropdown } from 'semantic-ui-react';

import { mapStateToProps, mapDispatchToProps } from '../../Root.js';
import 'react-datepicker/dist/react-datepicker.css';
import * as Constant from '../../Constants/Constant';

export class ContactUs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            openConfirmModal: false,
            openSendModal: false,
            inputContact: { "Subject": '', "Message": '' },
            errors: {},
        };
        this.onConfirmClick = this.onConfirmClick.bind(this);
        this.onCloseConfirmModal = this.onCloseConfirmModal.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onCustomerNumChange = this.onCustomerNumChange.bind(this);
        this.onSendClick = this.onSendClick.bind(this);
        this.onCloseSendModal = this.onCloseSendModal.bind(this);
    }

    componentDidMount() {
        this.props.action.loginSetLoadingError(false);
        this.props.action.sessionDataError(false);
        this.props.action.contactUsMailSendingError(false);
    }

    onConfirmClick() {
        this.props.action.loginSetLoadingError(false);
        this.props.action.sessionDataError(false);
        this.props.action.contactUsMailSendingError(false);
        this.setState({ errors: {}, });
        let inputContact = this.state.inputContact;
        let errors = {};

        // Check null and blank, remove leading and trailing whitespace
        if (inputContact.Subject != null && inputContact.Subject != '') {
            inputContact.Subject = inputContact.Subject.trim();
        } else {
            errors.subject = "Please enter Subject.";
        }
        if (inputContact.Message != null && inputContact.Message != '') {
            inputContact.Message = inputContact.Message.trim();
        } else {
            errors.message = "Please enter Message.";
        }

        // If there are no errors, proceed to the next step
        if (Object.keys(errors).length > 0) {
            this.setState({ errors: errors, });
        } else {
            this.setState({ openConfirmModal: true, inputContact: inputContact, });
        }
    }

    onCloseConfirmModal() {
        this.setState({ openConfirmModal: false, });
    }

    onTextChange(evn, data) {
        let inputContact = Object.assign(Object.create(this.state.inputContact), this.state.inputContact);
        const { name, value } = data;
        inputContact[name] = value;
        this.setState({ inputContact });
    }

    onCustomerNumChange(evn, data) {
        let oldSelectedNum = this.props.Reducer.selectedCustomerNum;
        let selectedCustomerNum = data.value;

        if (oldSelectedNum != selectedCustomerNum) {
            if (oldSelectedNum != '') {
                var result = window.confirm("If you change Customer#, the current input will be discarded.");
                if (result) {
                    this.setState({ inputContact: { "Subject": '', "Message": '' }, errors: {} });
                    this.props.action.loginSetLoadingError(false);
                    this.props.action.sessionDataError(false);
                    this.props.action.contactUsMailSendingError(false);
                    this.props.action.setCustomerNumber(selectedCustomerNum, this.props.Reducer.loginData);
                    this.props.action.deleteSessionCart(AppConfig.ApiUrlPartsOrder, this.props.Reducer.loginData);
                    this.props.action.updateOrderData([], [], "100");
                    this.props.action.deletePartsOrders();
                    if (selectedCustomerNum != '') {
                        this.props.action.loginConnect(AppConfig.ApiUrlPartsLogin, selectedCustomerNum, true, this.props.Reducer.loginData);
                    }
                }
            } else {
                this.props.action.loginSetLoadingError(false);
                this.props.action.sessionDataError(false);
                this.props.action.contactUsMailSendingError(false);
                this.props.action.setCustomerNumber(selectedCustomerNum, this.props.Reducer.loginData);
                if (selectedCustomerNum != '') {
                    this.props.action.loginConnect(AppConfig.ApiUrlPartsLogin, selectedCustomerNum, true, this.props.Reducer.loginData);
                }
            }
        }
    }

    onSendClick() {
        this.setState({ openConfirmModal: false, });
        this.props.action.contactUs(AppConfig.ApiUrlContactUs, this.state.inputContact, this.props.Reducer.loginData);
    }

    onCloseSendModal(evn, data) {
        this.props.action.contactUsCloseSendingModal();
        const { name } = data;
        this.props.action.menuChange(name);
    }

    render() {
        let styleModal = { marginTop: 0, margin: 'auto', };
        let styleSegment = { marginTop: -15 };

        let selectCustomerNumFlag = this.props.Reducer.selectedCustomerNum ? true : false;

        let customerOption = [{ value: '', text: '' }];
        this.props.Reducer.customerNumList.map(x => {
            customerOption.push({ value: x, text: x });
        });

        let sendingMessage = this.props.Reducer.sending ? <div><Icon loading size='large' name='spinner' /> Sending...</div> : '';

        let errorMessage = this.props.Reducer.loginIsLoadingError || this.props.Reducer.sessionDataError || this.props.Reducer.contactUsMailSendingError ?
            <Message negative size='big'
                icon='warning circle'
                header='Send Error occured.'
                content={this.props.Reducer.contactUsErrorMessage}
            />
            : Object.keys(this.state.errors).length > 0 ?
                <Message negative size='big'
                    icon='warning circle'
                    header='Error occured. Please fix below.'
                    list={Object.keys(this.state.errors).map((k, idx) => (<p key={idx}>{this.state.errors[k]}</p>))}
                />
                : "";

        return (
            <div>
                {this.props.Reducer.customerNumList.length != 1 ?
                    <h3>
                        <Dropdown
                            search selection placeholder='Select Customer#'
                            options={customerOption}
                            value={this.props.Reducer.selectedCustomerNum}
                            onChange={this.onCustomerNumChange}
                        />
                    </h3>
                    : ""}

                <Header color='grey' as='h1' dividing>
                    Contact Us
                </Header>
                <Dimmer active={this.props.Reducer.contactUsMailSending}>
                    <Loader inline='centered' size='big'>Sending mail...</Loader>
                </Dimmer>
                <Dimmer active={this.props.Reducer.loginIsLoading || this.props.Reducer.ordersLoading}>
                    <Loader inline='centered' size='big'>Loading ...</Loader>
                </Dimmer>

                {sendingMessage}{errorMessage}

                <Dimmer.Dimmable fluid dimmed style={styleSegment}>
                    <Dimmer active={this.props.Reducer.selectedCustomerNum == ''} >
                        <Header as='h2' inverted>
                            Please Select Customer# in Advance.
                        </Header>
                    </Dimmer>

                    <Segment basic>
                        <Header as='h2'>Send Us a Message</Header>
                        <Form size='big'>
                            <Form.Input fluid label='Subject' placeholder='Subject' name='Subject' value={this.state.inputContact.Subject} error={!!this.state.errors.subject} onChange={this.onTextChange} disabled={!selectCustomerNumFlag} />
                            <Form.TextArea autoHeight label='Message' placeholder='Message' name='Message' value={this.state.inputContact.Message} error={!!this.state.errors.message} onChange={this.onTextChange} disabled={!selectCustomerNumFlag} />
                            <Button primary size='huge' onClick={this.onConfirmClick} disabled={!selectCustomerNumFlag}>Confirm</Button>
                        </Form>
                    </Segment>
                </Dimmer.Dimmable>

                <Modal size='small' open={this.state.openConfirmModal} close={this.onCloseModal} style={styleModal}>
                    <Modal.Header>
                        <Header as='h2'>Please confirm</Header>
                    </Modal.Header>
                    <Modal.Content>
                        <Message warning size='big'
                            icon='warning circle'
                            header='You message not submitted.'
                            content='Press Send button below to submit the message.'
                        />
                        <Header>Subject</Header>
                        <p>{this.state.inputContact.Subject}</p>
                        <Header>Message</Header>
                        <p>{this.state.inputContact.Message ? (this.state.inputContact.Message + "").split("\n").map((m, idx) => <span key={idx}>{m}<br /></span>) : ""}</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button primary size='huge' icon='paper plane' labelPosition='right' content='Send' onClick={this.onSendClick} />
                        <Button size='huge' onClick={this.onCloseConfirmModal}>
                            Cancel
                        </Button>
                    </Modal.Actions>
                </Modal>

                <Modal basic size='small' open={this.props.Reducer.openContactUsSendModal} style={styleModal}>
                    <Header icon>
                        <Icon name='paper plane' />
                        <h2>Complete!</h2>
                    </Header>
                    <Modal.Content>We received your message.</Modal.Content>
                    <Modal.Actions>
                        <Button size='huge' basic inverted onClick={this.onCloseSendModal} name={Constant.STAT_ORDERLIST}>
                            <Icon name='home' />Back To Home
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactUs);
