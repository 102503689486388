"use strict";
export default class {
    constructor(dataObject = null) {
        this.Id = null;
        this.CloseHeaderId = null;
        this.ProductNumber = '';
        this.OrderNumber = '';
        this.ShipTrackingNumber = '';

        //Import data if set
        if (dataObject) {
            try {
                this.Id = dataObject.id;
                this.CloseHeaderId = dataObject.closeHeaderId;
                this.ProductNumber = dataObject.productNumber;
                this.OrderNumber = dataObject.orderNumber;
                this.ShipTrackingNumber = dataObject.shipTrackingNumber;
            }
            catch (e) {
                throw "Data import error @ OrderFromJbaseCloseDetail data.";
            }
        }
    }

    getDataObject() {
        return {
            Id: this.Id,
            CloseHeaderId: this.CloseHeaderId,
            ProductNumber: this.ProductNumber,
            OrderNumber: this.OrderNumber,
            ShipTrackingNumber: this.ShipTrackingNumber,
        }
    }
}