import * as Constant from '../Constants/Constant';
import Functions from '../Functions';
import SessionUserInfo from '../Models/SessionUserInfo';
import Parts from '../Models/Parts';
import AppConfig from '../AppConfig';

// Common
export function menuChange(tabStatus) {
    return {
        type: Constant.ACT_MENUCHANGE,
        tabStatus: tabStatus
    };
}

// Login System
//#region
export function loginAuth(url, loginInfo) {
    return (dispatch, getState) => {
        dispatch(loginSetLoadingError(false));
        dispatch(loginSetLoading(true));

        let generatedUrl = url + '/login';

        fetch(generatedUrl, {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(loginInfo)
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                dispatch(loginSetLoading(false));
                return response;
            })
            .then((response) => response.json())
            .then((loginResponseData) => {
                let loginData = new SessionUserInfo(loginResponseData.userInfo);

                if (loginData.Access) {
                    if (loginResponseData.customerNumList.length <= 1) {
                        dispatch(loginConnect(url, loginData.CustomerNumber, false, loginData));
                    } else {
                        dispatch(setCustmerNumberList(loginData, loginResponseData.customerNumList));
                    }
                } else {
                    dispatch(loginSetLoadingError(true, loginData.Message));
                }
            })
            .catch((e) => {
                dispatch(loginSetLoadingError(true, e.message));
                dispatch(loginSetLoading(false));
            });
    }
}

export function loginConnect(url, customerNumber, selectCustomerFlag, loginData) {
    return (dispatch, getState) => {
        dispatch(loginSetLoadingError(false));
        dispatch(loginSetLoading(true));

        let generatedUrl = url + '/connect';

        fetch(generatedUrl, {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(loginData.Token)
            },
            body: JSON.stringify(customerNumber)
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                dispatch(loginSetLoading(false));
                return response;
            })
            .then((response) => response.json())
            .then((loginResponseData) => {
                let loginData = new SessionUserInfo(loginResponseData.userInfo);

                if (loginData.Access) {
                    if (loginResponseData.isExistLogin) {
                        dispatch(setIsOpenSystemRetireModal(true, true));
                    } else {
                        dispatch(isTermsConditionsModal(true));
                    }
                    if (selectCustomerFlag) {
                        dispatch(loginResultByCustomerNum(loginData, loginResponseData.shipInfo, loginResponseData.category));
                        dispatch(JbasePartsOrdersByCustomer(AppConfig.ApiUrlPartsOrder, loginData));
                    } else {
                        dispatch(loginResult(loginData, loginResponseData.shipInfo, loginResponseData.category, loginResponseData.customerNumList));
                    }
                    dispatch(setFavoriteParts(loginResponseData.favoriteParts));
                    dispatch(onFromDateChange(new Date()));
                } else {
                    dispatch(setCustomerNumber('', loginData));
                    dispatch(loginSetLoadingError(true, loginData.Message));
                }
            })
            .catch((e) => {
                dispatch(setCustomerNumber('', loginData));
                if (e.message == 'Failed to get user information. Please reload the screen.') {
                    dispatch(setReloadFlag(true, e.message));
                } else {
                    dispatch(loginSetLoadingError(true, e.message));
                }
                dispatch(loginSetLoading(false));
            });
    }
}

export function loginResult(loginData, shipInfo, category, customerNumList) {
    return {
        type: Constant.ACT_LOGINRESULT,
        loginData: loginData,
        shipInfo: shipInfo,
        category: category,
        customerNumList: customerNumList,
    };
}

export function setCustmerNumberList(loginDataList, customerNumList) {
    return {
        type: Constant.ACT_SETCUSTOMERNUMBERLIST,
        loginDataList: loginDataList,
        customerNumList: customerNumList,
    };
}

export function loginResultByCustomerNum(loginData, shipInfo, category) {
    return {
        type: Constant.ACT_LOGINRESULTBYCUSTOMERNUM,
        loginData: loginData,
        shipInfo: shipInfo,
        category: category,
    };
}

export function setCustomerNumber(customerNumber, loginData) {
    if (customerNumber == '') {
        loginData.IsBulkOrder = false;
        loginData.IsFileDownload = false;
    }

    return {
        type: Constant.ACT_SETCUSTOMERNUMBER,
        customerNumber: customerNumber,
        loginData: loginData,
    };
}

export function loginSetLoading(bool) {
    return {
        type: Constant.ACT_LOGINISLOADING,
        loginIsLoading: bool
    };
}

export function loginSetLoadingError(bool, message) {
    if (message == undefined) {
        message = "";
    }
    return {
        type: Constant.ACT_LOGINISLOADING_ERROR,
        loginIsLoadingError: bool,
        message: message,
    };
}

export function isTermsConditionsModal(bool) {
    return {
        type: Constant.ACT_ISTERMSCONDITIONSMODAL,
        bool: bool
    }
}

export function setReloadFlag(bool, message = "") {
    return {
        type: Constant.ACT_SETRELOADFLAG,
        message,
        bool,
    }
}

//#endregion

// Reset password
//#region
export function resetPasswordPage(bool) {
    return {
        type: Constant.ACT_RESETPASSWORDPAGE,
        resetPasswordPage: bool
    };
}

export function getResetParam(url, userInfo) {
    return (dispatch, getState) => {
        dispatch(resetParamLoadingError(false));
        dispatch(resetParamLoading(true));

        let generatedUrl = url + '/resetParam';

        fetch(generatedUrl, {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userInfo)
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                dispatch(resetParamLoading(false));
                dispatch(openResetParamModel(true));
                return response;
            })
            .catch((e) => {
                dispatch(resetParamLoadingError(true, e.message));
                dispatch(resetParamLoading(false));
            });
    }
}

export function resetParamLoading(bool) {
    return {
        type: Constant.ACT_RESETPARAMISLOADING,
        resetParamIsLoading: bool
    };
}

export function resetParamLoadingError(bool, message) {
    if (message == undefined) {
        message = "";
    }
    return {
        type: Constant.ACT_RESETPARAMISLOADING_ERROR,
        resetParamIsLoadingError: bool,
        message: message,
    };
}

export function openResetParamModel(bool) {
    return {
        type: Constant.ACT_OPENRESETMODAL,
        resetParamModal: bool
    };
}

export function resetPassword(url, newPassword, resetParam) {
    return (dispatch, getState) => {
        dispatch(resetPassLoadingError(false));
        dispatch(resetPassLoading(true));

        let generatedUrl = url + '/reset';
        let patchData = { newPassword, resetParam };

        fetch(generatedUrl, {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(patchData)
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                dispatch(resetPassLoading(false));
                dispatch(openResetPassModel(true));
                return response;
            })
            .catch((e) => {
                dispatch(resetPassLoadingError(true, e.message));
                dispatch(resetPassLoading(false));
            });
    }
}

export function resetPassLoading(bool) {
    return {
        type: Constant.ACT_RESETPASSISLOADING,
        resetPassIsLoading: bool
    };
}

export function resetPassLoadingError(bool, message) {
    if (message == undefined) {
        message = "";
    }
    return {
        type: Constant.ACT_RESETPASSISLOADING_ERROR,
        resetPassIsLoadingError: bool,
        message: message,
    };
}

export function openResetPassModel(bool) {
    return {
        type: Constant.ACT_OPENRESETPASSMODAL,
        resetPassModal: bool
    };
}
//#endregion

// Order List
//#region
export function transitionInvoice(orderData) {
    return {
        type: Constant.ACT_MOVE_INVOICE,
        selectOrderData: orderData,
    }
}

export function backToOrderList() {
    return {
        type: Constant.ACT_BACKTOLIST,
    };
}

export function changeSortBy(sortBy) {
    return {
        type: Constant.ACT_CHANGESORTBY,
        sortBy: sortBy,
    };
}

export function changeItemsPerPage(n, logFlag) {
    return {
        type: Constant.ACT_CHANGEITEMSPERPAGE,
        itemsPerPage: Number(n),
        logFlag: logFlag
    };
}

export function selectPage(n, logFlag) {
    return {
        type: Constant.ACT_SELECTPAGE,
        currentPage: Number(n),
        logFlag: logFlag
    };
}

export function onFromDateChange(date) {
    return {
        type: Constant.ACT_THEOLDESTDATEFROMCHANGE,
        date,
    };
}
//#endregion

// Order Entry
//#region
export function orderEntryFormSave(url, warehouse, companyId, orderEntryForm, backOrderQuantityList, loginData) {
    return (dispatch, getState) => {
        dispatch(orderEntryFormSetSavingError(false));
        dispatch(orderEntryFormSetSaving(true));

        let patchData = createSaveData(warehouse, companyId, orderEntryForm, backOrderQuantityList, loginData);
        let generatedUrl = url + '/orderEntry';

        fetch(generatedUrl, {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(loginData.Token)
            },
            body: JSON.stringify(patchData)
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                dispatch(orderEntryFormSetSaving(false));
                return response;
            })
            .then((response) => response.json())
            .then((acceptOrder) => {
                let success = acceptOrder.orderResult.successField;
                if (success) {
                    let orderNumber = acceptOrder.orderResult.orderNumberField;
                    dispatch(orderEntryFormSavingSuccess(orderNumber, true));
                } else {
                    let errorMessage = acceptOrder.orderResult.ErrorMessage;
                    dispatch(orderEntryFormSetSavingError(true, errorMessage));
                }
            })
            .catch((e) => {
                dispatch(orderEntryFormSetSavingError(true, e.message));
                dispatch(orderEntryFormSetSaving(false));
            });
    }
}

export function orderEntryFormSavingSuccess(orderNumber, bool) {
    return {
        type: Constant.ACT_ORDERENTRY_SAVING_SUCCESS,
        acceptOrder: orderNumber,
        orderEntryCompleteModal: bool,
    };
}

export function orderEntryFormSetSaving(bool) {
    return {
        type: Constant.ACT_ORDERENTRY_SAVING,
        orderEntryFormIsSaving: bool,
    };
}

export function orderEntryFormSetSavingError(bool, message) {
    if (message == undefined) {
        message = "";
    }
    return {
        type: Constant.ACT_ORDERENTRY_SAVING_ERROR,
        orderEntryFormIsSavingError: bool,
        message: message,
    };
}

export function closeOrderEntryCompleteModal() {
    return {
        type: Constant.ACT_ORDERENTRY_CLOSE_COMPLETEMODAL,
        bool: false,
    };
}

function createSaveData(warehouse, companyId, orderEntryForm, backOrderQuantityList, loginData) {
    let saveData = {
        purchaseOrderNumber: orderEntryForm.PurchaseOrderNumber,
        shipVia: orderEntryForm.ShipVia,
        shipTo: orderEntryForm.ShipTo,
        customerNumber: loginData.CustomerNumber,
        wareHouse: warehouse,
        userId: loginData.UserId, // email
        companyId: companyId,
    };

    if (orderEntryForm.Parts && backOrderQuantityList) {
        saveData.Parts = orderEntryForm.Parts.map(x => {
            let isPartsSelected = backOrderQuantityList.find(backOrderProduct => backOrderProduct.ProductNumber == x.ProductNumber);

            if (isPartsSelected) {
                let rtn = {
                    productNumber: x.ProductNumber,
                    orderQuantity: Number(x.OrderQuantity) + Number(isPartsSelected.BoQty),
                    description: x.Description,
                }
                return rtn;
            } else {
                let rtn = {
                    productNumber: x.ProductNumber,
                    orderQuantity: Number(x.OrderQuantity),
                    description: x.Description,
                }
                return rtn;
            }
        })

        backOrderQuantityList.map(x => {
            let backOrderParts = saveData.Parts.find(parts => parts.productNumber == x.ProductNumber);
            if (!backOrderParts) {
                let rtn = {
                    productNumber: x.ProductNumber,
                    orderQuantity: Number(x.BoQty),
                    description: x.Description,
                }
                saveData.Parts = saveData.Parts.concat(rtn);
            }
        })
    } else if (orderEntryForm.Parts) {
        saveData.Parts = orderEntryForm.Parts.map(x => {
            let rtn = {
                productNumber: x.ProductNumber,
                orderQuantity: Number(x.OrderQuantity),
                description: x.Description,
            }
            return rtn;
        })
    } else if (backOrderQuantityList) {
        saveData.Parts = backOrderQuantityList.map(x => {
            let rtn = {
                productNumber: x.ProductNumber,
                orderQuantity: Number(x.BoQty),
                description: x.Description,
            }
            return rtn;
        })
    }

    return saveData;
}

// keep order parts data in session
export function keepOrderPartsInSession(url, orderParts, loginData, warehouse) {
    return (dispatch, getState) => {
        dispatch(sessionDataError(false));

        let generatedUrl = url + '/keepOrderDataInSession';
        let patchData = { "orderParts": orderParts, "wareHouse": warehouse };

        fetch(generatedUrl, {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(loginData.Token)
            },
            body: JSON.stringify(patchData)
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
            })
            .catch((e) => {
                dispatch(sessionDataError(true, e.message));
            });
    }
}

// Get order parts data in session
export function callOrderDataInSession(url, loginData) {
    return (dispatch, getState) => {
        dispatch(sessionDataError(false));
        dispatch(sessionDataLoading(true));

        let generatedUrl = url + '/callOrderDataInSession';

        fetch(generatedUrl, {
            mode: 'cors', credentials: 'include',
            headers: { 'csrftoken': JSON.stringify(loginData.Token) },
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                dispatch(sessionDataLoading(false));
                return response;
            })
            .then((response) => response.json())
            .then((runParts) => {
                let isPartsPicked = runParts.isPartsPicked;
                let resultProducts = runParts.result;
                let mainPartsList = runParts.productNumberList;
                let orderQtyList = runParts.orderQtyList;
                let warehouse = runParts.wareHouse;

                let orderParts = [];
                let formatOrderQtyList = [];

                if (!isPartsPicked) {
                    dispatch(updateOrderData([], [], "100"));
                } else {
                    resultProducts.map(product => {
                        mainPartsList.map(productNumber => {
                            let partsObj = product[productNumber];
                            if (!partsObj) {
                                return;
                            }

                            let orderItem = createOrderParts(partsObj, productNumber);
                            orderParts.push(orderItem);
                        });
                    });

                    formatOrderQtyList = Object.entries(orderQtyList).map(([key, value]) => ({ 'ProductNumber': key, 'Qty': value }));
                    dispatch(updateOrderData(orderParts, formatOrderQtyList, warehouse));
                }
            })
            .catch((e) => {
                if (e.message == 'Failed to get user information. Please reload the screen.') {
                    dispatch(setReloadFlag(true, e.message));
                } else {
                    dispatch(sessionDataError(true, e.message));
                }
                dispatch(sessionDataLoading(false));
            });
    }
}

function createOrderParts(partsObj, productNumber) {
    let parts = partsObj.find(x => x.ProductNumber == productNumber);

    let orderItem = new Parts();
    orderItem.ProductNumber = parts.ProductNumber;
    orderItem.Description = parts.Description;
    orderItem.List = parts.ListPrice;
    orderItem.Price = parts.Price;
    orderItem.Obsolete = parts.Obsolete;
    orderItem.Available = parts.Available;

    let subPartsList = partsObj.filter(x => x.ProductNumber != productNumber);
    let subParts = subPartsList.map(x => {
        let subPart = new Parts();
        subPart.ProductNumber = x.ProductNumber;
        subPart.Description = x.Description;
        subPart.List = x.ListPrice;
        subPart.Price = x.Price;
        subPart.Obsolete = x.Obsolete;
        subPart.Available = x.Available;

        return subPart;
    });

    orderItem.SubParts = subParts;
    return orderItem;
}

export function updateOrderData(orderPartsData, orderQtyList, warehouse) {
    return {
        type: Constant.ACT_ORDERENTRY_CART,
        cart: orderPartsData,
        orderQtyList: orderQtyList,
        warehouse: warehouse,
    }
}

export function sessionDataError(bool, message) {
    if (message == undefined) {
        message = "";
    }
    return {
        type: Constant.ACT_ORDERENTRY_CART_ERROR,
        sessionDataError: bool,
        message: message,
    };
}


export function selectModalPage(n) {
    return {
        type: Constant.ACT_ORDERENTRY_SELECT_MODAL_PAGE,
        currentModalPage: Number(n),
    };
}

export function sessionDataLoading(bool) {
    return {
        type: Constant.ACT_SESSIONDATALOADING,
        sessionDataLoading: bool,
    }
}

export function deleteSessionCart(url, loginData) {
    return (dispatch, getState) => {
        dispatch(sessionDataError(false));

        let generatedUrl = url + '/deleteSessionCart';

        fetch(generatedUrl, {
            mode: 'cors', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(loginData.Token)
            },
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
            })
            .catch((e) => {
                dispatch(sessionDataError(true, e.message));
            });
    }
}

export function orderEntryAddPartLoadingError(bool, message) {
    if (message == undefined) {
        message = "";
    }
    return {
        type: Constant.ACT_ORDERENTRY_ADDPART_ERROR,
        orderEntryAddPartLoadingError: bool,
        message: message,
    };
}

// export function getPartsMaster(url, loginData) {
//     return (dispatch, getState) => {
//         dispatch(partsMasterLoading(true));

//         let generatedUrl = url + '/getPartsMaster';

//         fetch(generatedUrl, {
//             mode: 'cors', credentials: 'include',
//             headers: { 'csrftoken': JSON.stringify(loginData.Token) },
//         })
//             .then((response) => {
//                 if (!response.ok) {
//                     return response.json()
//                         .then(function (err) {
//                             throw new Error(err.Message);
//                         });
//                 }
//                 dispatch(partsMasterLoading(false));
//                 return response;
//             })
//             .then((response) => response.json())
//             .then((result) => dispatch(getPartsMasterSuccess(result.partsMaster)))
//             .catch((e) => {
//                 let errorMessage = "Failed to get PartsMaster data, please reload the screen.";
//                 dispatch(partsMasterLoadingError(true, errorMessage));
//                 dispatch(partsMasterLoading(false));
//             });
//     }
// }

// export function getPartsMasterSuccess(partsMaster) {
//     return {
//         type: Constant.ACT_ORDERENTRY_PARTSMASTER,
//         partsMaster: partsMaster,
//     }
// }

// export function partsMasterLoading(bool) {
//     return {
//         type: Constant.ACT_ORDERENTRY_PARTSMASTER_LOADING,
//         partsMasterLoading: bool,
//     }
// }

// export function partsMasterLoadingError(bool, message) {
//     if (message == undefined) {
//         message = "";
//     }
//     return {
//         type: Constant.ACT_ORDERENTRY_PARTSMASTER_LOADING_ERROR,
//         partsMasterLoadingError: bool,
//         message: message,
//     };
// }
//#endregion

// Contact Us
//#region
export function contactUs(url, inputContact, loginData) {
    return (dispatch, getState) => {
        dispatch(contactUsMailSendingError(false));
        dispatch(contactUsMailSending(true));

        fetch(url, {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(loginData.Token)
            },
            body: JSON.stringify(inputContact)
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                dispatch(contactUsMailSending(false));
                dispatch(contactUsMailSendingSuccess(true));
                return response;
            })
            .catch((e) => {
                dispatch(contactUsMailSendingError(true, e.message));
                dispatch(contactUsMailSending(false));
            })
    };
}

export function contactUsMailSendingSuccess(bool) {
    return {
        type: Constant.ACT_CONTACTUS_MAILSENDING_SUCCESS,
        contactUsMailSendingSuccess: bool,
    };
}

export function contactUsMailSending(bool) {
    return {
        type: Constant.ACT_CONTACTUS_MAILSENDING,
        contactUsMailSending: bool
    };
}

export function contactUsMailSendingError(bool, message) {
    if (message == undefined) {
        message = "";
    }
    return {
        type: Constant.ACT_CONTACTUS_MAILSENDING_ERROR,
        contactUsMailSendingError: bool,
        message: message,
    };
}

export function contactUsCloseSendingModal() {
    return {
        type: Constant.ACT_CONTACTUS_CLOSE_SENDINGMODAL,
        bool: false,
    };
}
//#endregion

// Call WCFService
// open/close order
//#region
export function JbasePartsOrdersByCustomer(url, loginData) {
    return (dispatch) => {
        dispatch(ordersFetchDataLoadingError(false));
        dispatch(ordersFetchDataLoading(true));

        let generatedUrl = url + "/ordersByCustomer";

        fetch(generatedUrl, {
            mode: 'cors', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(loginData.Token)
            },
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                dispatch(ordersFetchDataLoading(false));
                return response;
            })
            .then((response) => response.json())
            .then((orders) => {
                let success = orders.success;
                if (success) {
                    dispatch(ordersFetchDataSuccess(orders));
                } else {
                    let errorMessage = "Failed to get Order data from jBASE, please contact the IT development team.";
                    dispatch(ordersFetchDataLoadingError(true, errorMessage));
                }
            })
            .catch((e) => {
                dispatch(ordersFetchDataLoadingError(true, e.message));
                dispatch(ordersFetchDataLoading(false));
            });
    }
}

export function ordersFetchDataSuccess(orders) {
    return {
        type: Constant.ACT_JBASE_PARTSORDERSBYCUSTOMER,
        orders: orders,
    }
}

export function closeOrdersFetchDataSuccess(orders) {
    return {
        type: Constant.ACT_JBASE_PARTSClOSEORDERS,
        orders: orders,
    }
}

export function deletePartsOrders() {
    return {
        type: Constant.ACT_JBASE_DELETEPARTSORDERS,
        openOrderHeaders: [],
        closeOrderHeaders: [],
        orderLogs: [],
    }
}

export function ordersFetchDataLoading(bool) {
    return {
        type: Constant.ACT_JBASE_PARTSORDERSBYCUSTOMER_LOADING,
        ordersLoading: bool
    };
}

export function ordersFetchDataLoadingError(bool, message) {
    if (message == undefined) {
        message = "";
    }
    return {
        type: Constant.ACT_JBASE_PARTSORDERSBYCUSTOMER_LOADING_ERROR,
        ordersLoadingError: bool,
        message: message,
    };
}
//#endregion

// runPartsSearch
//#region
export function JbaseRunPartsSearch(url, loginData, companyId, productString, warehouse) {
    return (dispatch, getState) => {
        dispatch(partsSetSearchingError(false));
        dispatch(partsSetSearching(true));

        let patchData = {};
        patchData.CompanyId = companyId;
        patchData.ProductString = productString;
        patchData.CustomerNumber = loginData.CustomerNumber;
        patchData.WareHouse = warehouse;

        let generatedUrl = url + '/entry';

        fetch(generatedUrl, {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(loginData.Token)
            },
            body: JSON.stringify(patchData)
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                dispatch(partsSetSearching(false));
                return response;
            })
            .then((response) => response.json())
            .then((runParts) => {
                let success = runParts.result.Success;
                if (success) {
                    if (runParts.result.Products != null) {
                        dispatch(runPartsSearchFetchDataSuccess(runParts.result.Products.Product));
                    } else {
                        let message = 'Could not find the part. Please change the keywords and search again.'
                        dispatch(partsSetSearchingError(true, message));
                    }
                } else {
                    let errorMessage = 'Failed to search for parts. Please reload the screen.';
                    dispatch(partsSetSearchingError(true, errorMessage));
                }
            })
            .catch((e) => {
                dispatch(partsSetSearchingError(true, e.message));
                dispatch(partsSetSearching(false));
            });
    }
}

export function runPartsSearchFetchDataSuccess(runParts) {
    return {
        type: Constant.ACT_JBASE_RUNPARTSSEARCH,
        runParts: runParts,
        openRunPartsSearchResultModal: true,
    }
}

export function partsSetSearching(bool) {
    return {
        type: Constant.ACT_JBASE_PARTSSEARCHING,
        partsSearching: bool
    };
}

export function partsSetSearchingError(bool, message) {
    if (message == undefined) {
        message = "";
    }
    return {
        type: Constant.ACT_JBASE_PARTSSEARCHING_ERROR,
        partsSearchingError: bool,
        message: message,
    };
}

export function closeRunPartsSearchResultModal() {
    return {
        type: Constant.ACT_JBASE_RUNPARTSSEARCH_CLOSE_MODAL,
        openRunPartsSearchResultModal: false,
    }
}
//#endregion

// Favorite Parts
//#region
export function updateFavoriteParts(url, loginData, favoritePart, isAdd) {
    return (dispatch, getState) => {
        dispatch(favoritePartsUpdatingError(false));
        dispatch(favoritePartsUpdating(true));

        let generatedUrl = url + '/post';
        let patchData = createFavoritePartData(loginData.CustomerNumber, favoritePart, isAdd);

        fetch(generatedUrl, {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(loginData.Token)
            },
            body: JSON.stringify(patchData)
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                dispatch(favoritePartsUpdating(false));
                return response;
            })
            .then((response) => response.json())
            .then((response) => {
                if (response) {
                    dispatch(setFavoriteParts(response.favoriteParts));
                } else {
                    dispatch(favoritePartsUpdatingError(true, response.Message));
                }
            })
            .catch((e) => {
                dispatch(favoritePartsUpdatingError(true, e.message));
                dispatch(favoritePartsUpdating(false));
            });
    }
}

function createFavoritePartData(customerNumber, favoritePart, isAdd) {
    let patchData = ({
        customerNumber: customerNumber,
        productNumber: favoritePart.ProductNumber,
        description: favoritePart.Description,
        isAdd: isAdd,
    });

    return patchData;
}

export function favoritePartsUpdatingError(bool, message) {
    if (message == undefined) {
        message = "";
    }
    return {
        type: Constant.ACT_FAVORITEPARTS_LOADING_ERROR,
        favoritePartsUpdatingError: bool,
        message: message,
    };
}

export function favoritePartsUpdating(bool) {
    return {
        type: Constant.ACT_FAVORITEPARTS_LOADING,
        favoritePartsUpdating: bool,
    }
}

export function setFavoriteParts(favoriteParts) {
    return {
        type: Constant.ACT_FAVORITEPARTS,
        favoriteParts: favoriteParts,
    }
}

export function setIsOpenSystemRetireModal(flag, isLogin = false) {
    return {
        type: Constant.ACT_SETISOPENSYSTEMRETIREMODAL,
        flag,
        isLogin,
    }
}
//#endregion
