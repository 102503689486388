import React from 'react';
import { connect } from 'react-redux';

import { Input, Container, Header, Table, Label, Icon, Button, Segment, Image, Form, Grid, Message, Modal } from 'semantic-ui-react';

import { mapStateToProps, mapDispatchToProps } from '../../Root.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import AppConfig from '../../AppConfig';
import * as Constant from '../../Constants/Constant';
import Functions from '../../Functions';


export class ResetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userInfo: { userId: "", customerId: "" },
            errors: {},
        };
        this.onBackClick = this.onBackClick.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
    }

    componentDidMount() {
        this.props.action.resetParamLoadingError(false);
    }

    onBackClick() {
        this.setState({ userInfo: { userId: "", customerId: "" }, errors: {} });
        this.props.action.resetPasswordPage(false);
    }

    onSubmitClick() {
        this.setState({ errors: {}, });
        let userInfo = Object.assign(Object.create(this.state.userInfo), this.state.userInfo);
        let errors = {};
        if (userInfo.userId == "") {
            errors.userId = "Please enter your User ID.";
        }
        if (userInfo.customerId == "") {
            errors.customerId = "Please enter your Customer ID.";
        }
        if (Object.keys(errors).length > 0) {
            this.setState({ errors: errors, });
        } else {
            this.props.action.getResetParam(AppConfig.ApiUrlPartsLogin, userInfo);
        }
    }

    onCloseModal() {
        this.props.action.openResetParamModel(false);
        this.onBackClick()
    }

    onTextChange(evn, data) {
        let userInfo = Object.assign(Object.create(this.state.userInfo), this.state.userInfo);
        const { name, value } = data;
        userInfo[name] = value;

        this.setState({ userInfo });
    }

    render() {
        let styleButton = { marginTop: 20 };
        let styleResrt = { color: "steelblue" };
        let styleRequired = { color: "red", marginLeft: 10, fontWeight: "normal" };
        let styleModal = { marginTop: 0, margin: 'auto', };
        let styleButtonLink = { background: 'none', color: 'blue' };

        let loadingFlag = this.props.Reducer.resetParamIsLoading;

        let errorMessage = this.props.Reducer.resetParamIsLoadingError ?
            <Message negative size='big'
                icon='warning circle'
                header='Login Error occured.'
                content={this.props.Reducer.loginErrorMessage.resetPassword}
            />
            : Object.keys(this.state.errors).length > 0 ?
                <Message negative size='big'
                    icon='warning circle'
                    header='Error occured. Please fix below.'
                    content={Object.keys(this.state.errors).map(k => (<p>{this.state.errors[k]}</p>))}
                />
                : "";

        let resetForm =
            <Segment textAlign='left'>
                {errorMessage}
                <Form size='big'>
                    <Form.Field>
                        <label>User ID <span style={styleRequired} >* required</span></label>
                        <Form.Input fluid icon='user' iconPosition='left' name='userId' placeholder='User ID' value={this.state.userInfo.userId} onChange={this.onTextChange} error={this.state.errors.userId} />
                    </Form.Field>
                    <Form.Field>
                        <label>Customer ID <span style={styleRequired} >* required</span></label>
                        <Form.Input fluid icon='building' iconPosition='left' name='customerId' placeholder='Customer ID' value={Functions.checkNumber(this.state.userInfo.customerId) ? this.state.userInfo.customerId : ''} onChange={this.onTextChange} error={this.state.errors.customerId} />
                    </Form.Field>


                    <Button color='blue' fluid size='huge' style={styleButton} onClick={this.onSubmitClick} disabled={loadingFlag}>
                        Submit
                    </Button>
                    <a className='cursor-pointer' style={styleButtonLink} onClick={this.onBackClick}>Back to login</a>
                </Form>
            </Segment>;

        return (
            <div>
                <Grid textAlign='center'>
                    <Grid.Column style={{ maxWidth: 650 }}>
                        {resetForm}
                    </Grid.Column>
                </Grid>

                <Modal basic size='small' open={this.props.Reducer.resetParamModal} style={styleModal}>
                    <Header icon>
                        <Icon name='mail' />
                        <h2>Send!</h2>
                    </Header>
                    <Modal.Content>Please check password reset mail.</Modal.Content>
                    <Modal.Actions>
                        <Button size='huge' basic inverted onClick={this.onCloseModal}>
                            <Icon name='check' />OK
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPassword);
