"use strict";
import Functions from '../Functions';

export default class {
    constructor(dataObject = null) {
        this.OrderNumber = '';
        this.CustomerNumber = '';
        this.UserId = '';
        this.ShipTo = '';
        this.PurchaseOrderNumber = '';
        this.TempChartId = '';
        this.Warehouse = '';
        this.ShipVia = '';

        // for view data
        this.OrderDate = '';
        this.CustomerName = '';

        this.Parts = [];

        //Import data if set
        if (dataObject) {
            try {
                this.OrderNumber = dataObject.OrderNumber;
                this.CustomerNumber = dataObject.CustomerNumber;
                this.UserId = dataObject.UserId;
                this.ShipTo = dataObject.ShipTo;
                this.PurchaseOrderNumber = dataObject.PurchaseOrderNumber;
                this.TempChartId = dataObject.TempChartId;
                this.Warehouse = dataObject.Warehouse;
                this.ShipVia = dataObject.ShipVia;

                this.OrderDate = dataObject.orderDate;
                this.CustomerName = dataObject.customerName;
            }
            catch (e) {
                throw "Data import error @ OrderEntryForm data.";
            }
        }
    }

    getDataObject() {
        return {
            OrderNUmber: this.OrderNumber,
            CustomerNumber: this.CustomerNumber,
            UserId: this.UserId,
            ShipTo: this.ShipTo,
            PurchaseOrderNumber: this.PurchaseOrderNumber,
            TempChartId: this.TempChartId,
            Warehouse: this.Warehouse,
            ShipVia: this.ShipVia,

            OrderDate: this.OrderDate,
            CustomerName: this.CustomerName,

            Parts: this.Parts,
        }
    }

    // Validation
    confirmValidate() {
        let errors = {};

        if (this.PurchaseOrderNumber == null || this.PurchaseOrderNumber == "") {
            errors.PurchaseOrderNumber = "P.O.# cannot be empty. Please enter P.O.#.";
        }

        if (this.ShipTo == null || this.ShipTo == "") {
            errors.ShipTo = "Ship To cannot be empty. Please enter Ship To.";
        }

        if (this.ShipVia == null || this.ShipVia == "") {
            errors.ShipVia = "Ship Via cannot be empty. Please enter Ship Via.";
        }

        // Check Byte
        if (Functions.byteLengthOf(this.PurchaseOrderNumber) > 255) {
            errors.PurchaseOrderNumber = "Please enter P.O.# within 255 characters.";
        }

        return errors;
    }
}