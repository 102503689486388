"use strict";
export default class {
    constructor(dataObject = null) {
        this.GuId = '';
        this.Access = false;
        this.CustomerNumber = '';
        this.CustomerName = '';
        this.Company = '';
        this.UserId = '';
        this.LoginDate = '';
        this.Message = '';
        this.Token = '';
        this.IsBulkOrder = false;
        this.SelectableWarehouse = false;
        this.ExpirationDate = '';
        this.IsFileDownload = false;

        //Import data if set
        if (dataObject) {
            try {
                this.GuId = dataObject.guId;
                this.Access = dataObject.access;
                this.CustomerNumber = dataObject.customerNumber;
                this.CustomerName = dataObject.customerName;
                this.Company = dataObject.company;
                this.UserId = dataObject.userId;
                this.LoginDate = dataObject.loginDate;
                this.Message = dataObject.message;
                this.Token = dataObject.token;
                this.IsBulkOrder = dataObject.isBulkOrder;
                this.SelectableWarehouse = dataObject.selectableWarehouse;
                this.ExpirationDate = dataObject.expirationDate;
                this.IsFileDownload = dataObject.isFileDownload;
            }
            catch (e) {
                throw "Data import error @ SessionUserInfo data.";
            }
        }
    }

    getDataObject() {
        return {
            GuId: this.GuId,
            Access: this.Access,
            CustomerNumber: this.CustomerNumber,
            CustomerName: this.CustomerName,
            Company: this.Company,
            UserId: this.UserId,
            LoginDate: this.LoginDate,
            Message: this.Message,
            Token: this.Token,
            IsBulkOrder: this.IsBulkOrder,
            SelectableWarehouse: this.SelectableWarehouse,
            ExpirationDate: this.ExpirationDate,
            IsFileDownload: this.IsFileDownload,
        }
    }
}