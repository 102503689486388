"use strict";
export default class {
    constructor(dataObject = null) {
        this.ProductNumber = '';
        this.Description = '';
        this.Available = '';
        this.Price = '';
        this.Obsolete = '';
        this.Status = '';
        this.Min = '';
        this.Max = '';
        this.Class = '';
        this.Category = '';
        this.SubAvailable = '';
        this.ListPrice = '';

        //Import data if set
        if (dataObject) {
            try {
                this.ProductNumber = dataObject.ProductNumber;
                this.Description = dataObject.Description;
                this.Available = dataObject.Available;
                this.Price = dataObject.Price;
                this.Obsolete = dataObject.Obsolete;
                this.Status = dataObject.Status;
                this.Min = dataObject.Min;
                this.Max = dataObject.Max;
                this.Class = dataObject.Class;
                this.Category = dataObject.Category;
                this.SubAvailable = dataObject.SubAvailable;
                this.ListPrice = dataObject.ListPrice;
            }
            catch (e) {
                throw "Data import error @ RunParts data.";
            }
        }
    }

    getDataObject() {
        return {
            ProductNumber: this.ProductNumber,
            Description: this.Description,
            Available: this.Available,
            Price: this.Price,
            Obsolete: this.Obsolete,
            Status: this.Status,
            Min: this.Min,
            Max: this.Max,
            Class: this.Class,
            Category: this.Category,
            SubAvailable: this.SubAvailable,
            ListPrice: this.ListPrice,
        }
    }
}