"use strict";

export default class {
    constructor(dataObject = null) {
        this.ProductNumber = '';
        this.OrderQuantity = '';
        this.QuantitySelected = '';
        this.QuantityBackOrderd = '';
        this.UnitPrice = '';
        this.Taxable = '';
        this.Obsolete = '';
        this.Available = 0;

        // for view data
        this.Description = '';
        this.List = 0;
        this.Price = 0;
        this.ShpQty = '';

        // for Cart
        this.SubParts = [];
        this.ProductNumberAndOrderQuantity = [];

        //Import data if set
        if (dataObject) {
            try {
                this.ProductNumber = dataObject.ProductNumber;
                this.OrderQuantity = dataObject.OrderQuantity;
                this.QuantitySelected = dataObject.QuantitySelected;
                this.QuantityBackOrderd = dataObject.QuantityBackOrderd;
                this.UnitPrice = dataObject.UnitPrice;
                this.Taxable = dataObject.Taxable;
                this.Obsolete = dataObject.Obsolete;
                this.Available = dataObject.Available;

                this.Description = dataObject.description;
                this.List = dataObject.list;
                this.Price = dataObject.price;
                this.ShpQty = dataObject.shpQty;
            }
            catch (e) {
                throw "Data import error @ Parts data.";
            }
        }
    }

    getDataObject() {
        return {
            ProductNumber: this.ProductNumber,
            OrderQuantity: this.OrderQuantity,
            QuantitySelected: this.QuantitySelected,
            QuantityBackOrderd: this.QuantityBackOrderd,
            UnitPrice: this.UnitPrice,
            Taxable: this.Taxable,
            Obsolete: this.Obsolete,
            Available: this.Available,

            Description: this.Description,
            List: this.List,
            Price: this.Price,
            ShpQty: this.ShpQty,

            SubParts: this.SubParts,
        }
    }
}