import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { mapStateToProps, mapDispatchToProps } from './Root';
import * as Constant from './Constants/Constant';
import OrderEntry from './Components/OrderEntry/OrderEntry';
import OrderList from './Components/OrderList/OrderList';
import Invoice from './Components/OrderList/Invoice';
import ContactUs from './Components/ContactUs/ContactUs';
import FileDownload from './Components/FileDownload/FileDownload';
import AppConfig from './AppConfig';

export class Tab extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.Reducer.selectedCustomerNum != '') {
            this.props.action.JbasePartsOrdersByCustomer(AppConfig.ApiUrlPartsOrder, this.props.Reducer.loginData);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const propsDiff = _.isEqual(nextProps, this.props);
        const stateDiff = _.isEqual(nextState, this.state);
        return !(propsDiff && stateDiff);
    }

    render() {
        switch (this.props.Reducer.tabStatus) {
            case Constant.STAT_ORDERLIST:
                return (
                    <div className="App">
                        <OrderList />
                    </div>
                );
            case Constant.STAT_INVOICE:
                return (
                    <div className="App">
                        <Invoice orderData={this.props.Reducer.selectOrderData} />
                    </div>
                );
            case Constant.STAT_CONTACTUS:
                return (
                    <div className="App">
                        <ContactUs />
                    </div>
                );
            default:
                return (
                    <div>No content...</div>
                );
        }
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Tab);
