import React from 'react';
import { connect } from 'react-redux';

import { Header, Icon, Button } from 'semantic-ui-react';

import { mapStateToProps, mapDispatchToProps } from '../../Root.js';
import * as Constant from '../../Constants/Constant';

export class OrderEmpty extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{ margin: '30px auto' }}>
                <Header as='h1' icon textAlign='center'>
                    <Icon name='inbox' />
                    There is no order history.
                </Header>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderEmpty);
