import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver'

import './App.css';
import { Header, Segment, Icon, Menu, Modal, Button, Image, Dimmer, Loader, Popup, Message } from 'semantic-ui-react';

import _ from 'lodash';

import * as Action from './Actions/Action';
import * as Constant from './Constants/Constant';
import Tab from './Tab';
import TermsAndConditionsModal from './Components/Login/TermsAndConditionsModal';
import AppConfig from './AppConfig';

import logo from './logo.jpg';

export class Root extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openModal: false,
            loading: false,
            loadingError: false,
        }

        this.onMenuClick = this.onMenuClick.bind(this);
        this.onLogoutClick = this.onLogoutClick.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.onCloseErrorModal = this.onCloseErrorModal.bind(this);
        this.isTermsConditionsModalOpen = this.isTermsConditionsModalOpen.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const propsDiff = _.isEqual(nextProps, this.props);
        const stateDiff = _.isEqual(nextState, this.state);
        return !(propsDiff && stateDiff);

    }

    onMenuClick(evn, data) {
        const { name } = data;
        this.props.action.menuChange(name);
    }

    isTermsConditionsModalOpen() {
        this.props.action.isTermsConditionsModal(true);
    }

    onLogoutClick() {
        this.setState({ loading: true, loadingError: false });
        let loginData = this.props.Reducer.loginData;

        fetch(AppConfig.ApiUrlPartsLogin + '/logout', {
            mode: 'cors', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(loginData.Token)
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                this.setState({ loading: false, loadingError: false, openModal: true });
                return response;
            })
            .catch((e) => {
                this.setState({ loading: false, loadingError: true, });
            });
    }

    onCloseModal() {
        this.setState({ openModal: false });
        window.parent.location.reload();
    }

    onCloseErrorModal() {
        this.setState({ loadingError: false });
        this.props.action.menuChange(Constant.STAT_ORDERLIST);
    }


    render() {
        let styleModal = { marginTop: 0, margin: 'auto', };

        let masterMenu =
            <Menu inverted icon='labeled' vertical className="masterMenu">
                <Menu.Item
                    name={Constant.STAT_ORDERLIST}
                    active={this.props.Reducer.tabStatus === Constant.STAT_ORDERLIST}
                    onClick={this.onMenuClick}
                    disabled={this.state.loading}>
                    <Icon name='list' />
                    Order<br />List
                </Menu.Item>
                <Menu.Item
                    name={Constant.STAT_CONTACTUS}
                    active={this.props.Reducer.tabStatus === Constant.STAT_CONTACTUS}
                    onClick={this.onMenuClick}
                    disabled={this.state.loading}>
                    <Icon name='mail outline' />
                    Contact<br />Us
                </Menu.Item>
            </Menu>;

        let loginData = this.props.Reducer.loginData;
        let header =
            <Segment inverted color='black'>
                <Header as='h1'>Parts Order</Header>
                <Menu inverted>
                    <Popup
                        wide='very'
                        size='huge'
                        position='bottom left'
                        content={`Customer# : ${loginData.CustomerNumber}`}
                        header={loginData.CustomerName}
                        trigger={
                            <Menu.Item
                                position='right'
                                disabled={this.state.loading}>
                                <Icon name='user' />
                                {loginData.UserId}
                            </Menu.Item>
                        }
                    />
                    <Menu.Item
                        onClick={this.isTermsConditionsModalOpen}
                        disabled={this.state.loading}>
                        <Icon name='balance' />
                        Terms and Conditions
                    </Menu.Item>
                    <Menu.Item
                        onClick={this.onLogoutClick}
                        disabled={this.state.loading}>
                        <Icon name='sign out' />
                        Logout
                    </Menu.Item>
                </Menu>
            </Segment>;

        let footer =
            <Segment as='footer' inverted color='black'>
                <Header as='h5' textAlign='center'>
                    © 2022 - 2023 JVCKENWOOD USA Corporation. All rights reserved.
                </Header>
            </Segment>;

        let logoutSuccessModal =
            <Modal basic size='small' open={this.state.openModal} style={styleModal}>
                <Header icon>
                    <Icon name='check' />
                    <h2>Logout Success</h2>
                </Header>
                <Modal.Content>Return to the login page.</Modal.Content>
                <Modal.Actions>
                    <Button size='huge' basic inverted onClick={this.onCloseModal}>
                        OK
                    </Button>
                </Modal.Actions>
            </Modal>;

        let logoutFaildModal =
            <Modal basic size='small' open={this.state.loadingError} style={styleModal}>
                <Header icon>
                    <Icon name='warning circle' />
                    <h2>Failed Logout</h2>
                </Header>
                <Modal.Content>An error occurred during the logout process. Please try Logout again.</Modal.Content>
                <Modal.Actions>
                    <Button size='huge' basic inverted onClick={this.onCloseErrorModal}>
                        OK
                    </Button>
                </Modal.Actions>
            </Modal>;

        let reloadDimmer = this.props.Reducer.reloadFlag
            ? <Dimmer active page>
                <Header as='h2' icon inverted>
                    <Icon name='exclamation triangle' />
                    {this.props.Reducer.errorMessage}
                </Header>
                <Button content="Reload" icon="redo" primary size='large' style={{ display: "block", margin: 'auto' }}
                    onClick={() => window.location.reload()} />
            </Dimmer>
            : "";
        let systemRetireModal =
            <Modal open={this.props.Reducer.isOpenSystemRetireModal} style={{ marginTop: 0, margin: 'auto' }}>
                <Modal.Content>
                    <p style={{ fontSize: 20 }}>
                        JVCKENWOOD USA Corporation (JKUSA), in alignment with our parent company JVCKENWOOD Corporation's strategies to ensure stability and sustainability for the mid and long term, will make some adjustments to our business structure. We will continue to rely on our most capable partners to help us support service efforts in the Americas, and will increase the role of partners in some cases. After reviewing many areas of our operations, we have decided to move operation and management of our parts supply chain from an internal operation to a trusted business partner of many years, United Radio. Inventory will move from Long Beach to United Radio's new facility in Salt Lake City and all facets of JKUSA's parts business will be managed by United Radio.<br /><br />
                        We plan to relocate the inventory on or about April 1, so parts operations in Long Beach closed 3/20/2023 to prepare the inventory for transport. It is expected that after stocking the inventory in Salt Lake City, United Radio will start shipping orders on or around April 17, 2023.<br /><br />
                        Existing parts account holders will be offered parts accounts with United Radio, and all parts business after April 1 will be with United Radio. JKUSA account holders should contact United Radio at <a href="mailto:urparts@unitedradio.com">urparts@unitedradio.com</a> for instructions and information regarding when accounts can be established and when order taking will begin. Account balances and invoices dated prior to 4/1/2023 will remain with JKUSA. Customers with backorders as of 3/20/2023 will be contacted regarding the status of the orders - most backorders will not be carried over and will have to be entered with United Radio.<br /><br />
                        We thank you for your understanding and apologize for any inconvenience this may cause. United Radio and JKUSA Service are committed to working with you to resolve any issues you may have and to make the transition go smoothly. We appreciate your partnership with JKUSA and look forward to helping you in your service efforts by providing continued reliable parts supply and professional customer service. JKUSA inquiries should be sent to <a href="mailto:serviceadmin@us.jvckenwood.com">serviceadmin@us.jvckenwood.com.</a>
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button content='OK' primary onClick={() => this.props.action.setIsOpenSystemRetireModal(false)} />
                </Modal.Actions>
            </Modal>

        return (
            <div>
                <Segment>
                    <Dimmer active={this.state.loading}>
                        <Loader inline='centered' size='big'>Loading ...</Loader>
                    </Dimmer>

                    <Image src={logo} size='small' />
                    {header}

                    <TermsAndConditionsModal />

                    <Message warning compact style={{ marginTop: 0 }}>
                        Notice to Customers
                        <Button content='View Detail' basic onClick={() => this.props.action.setIsOpenSystemRetireModal(true)} style={{ marginLeft: 5 }} />
                    </Message>

                    <div className='style-segment-horizontal'>
                        <div className="menuWide">
                            {masterMenu}
                        </div>
                        <Segment className="contentWide">
                            <Tab />
                        </Segment>
                    </div>

                    {footer}
                    {logoutSuccessModal}
                    {logoutFaildModal}
                    {systemRetireModal}
                    {reloadDimmer}
                </Segment>
            </div>
        );
    }
}

// state の中に store.js の combineReducers で指定したキーの State が全部入ってくる
export function mapStateToProps(state) {
    return state;
}

export function mapDispatchToProps(dispatch) {
    return {
        action: bindActionCreators(Action, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Root);