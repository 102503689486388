import React from 'react';
import { connect } from 'react-redux';

import { Menu, Input, Header, Table, Icon, Dropdown, Button, Segment, Modal, Message, Form, Dimmer, Loader, Popup, Grid, Label } from 'semantic-ui-react';

import { mapStateToProps, mapDispatchToProps } from '../../Root.js';
import 'react-datepicker/dist/react-datepicker.css';
import Functions from '../../Functions.js';
import OrderEntryForm from '../../Models/OrderEntryForm';
import SessionUserInfo from '../../Models/SessionUserInfo';
import Parts from '../../Models/Parts';
import AppConfig from '../../AppConfig';
import * as Constant from '../../Constants/Constant';

export class OrderEntry extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orderEntryForm: new OrderEntryForm(),
            partsInfo: [],
            orderQtyList: [],
            backOrder: [],
            confirmOrderList: {},
            detailErrors: {},
            errorMessage: '',

            partsSearch: '',
            partsModel: [],
            searchPartsNumber: '',
            searchDescription: '',
            enterPartNumber: '',

            loading: false,
            isOpen: false,
            openConfirmModal: false,
            getSubLoading: false,
            getSubLoadingError: false,
            confirmFlg: false,
            openAddPartsMessage: false,
            addProductName: '',

            favoriteParts: [],
            isFavoritePartsModalOpen: false,
            currentFavoritePartsModalPage: 1,
            favoritePartsNumber: '',
            favoritePartsDescription: '',
        };

        this.entryPartNumberRef = null;
        this.setEntryPartNumberRef = element => {
            this.entryPartNumberRef = element;
        }

        this.onRunPartsSearchClick = this.onRunPartsSearchClick.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.onDeleteOrderPartsClick = this.onDeleteOrderPartsClick.bind(this);
        this.onAddFileClick = this.onAddFileClick.bind(this);
        this.onNextClick = this.onNextClick.bind(this);
        this.onBackClick = this.onBackClick.bind(this);
        this.onPurchaseNowClick = this.onPurchaseNowClick.bind(this);
        this.onPageNumClick = this.onPageNumClick.bind(this);
        this.onSearchClearClickPartsNumber = this.onSearchClearClickPartsNumber.bind(this);
        this.onSearchClearClickDescription = this.onSearchClearClickDescription.bind(this);

        this.onWarehouseChange = this.onWarehouseChange.bind(this);
        this.onSearchPartsChange = this.onSearchPartsChange.bind(this);
        // this.onChangePartsOption = this.onChangePartsOption.bind(this);
        this.onQtyChange = this.onQtyChange.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.onPartsNumberSearchChange = this.onPartsNumberSearchChange.bind(this);
        this.onDescriptionSearchChange = this.onDescriptionSearchChange.bind(this);
        this.onCustomerNumChange = this.onCustomerNumChange.bind(this);

        // this.onOpenPopup = this.onOpenPopup.bind(this);
        this.onBlurFunc = this.onBlurFunc.bind(this);
        this.onOpenConfirmModal = this.onOpenConfirmModal.bind(this);
        this.onCloseConfirmModal = this.onCloseConfirmModal.bind(this);
        this.onCloseRunPartsSearchResultModal = this.onCloseRunPartsSearchResultModal.bind(this);
        this.onCloseCompleteModal = this.onCloseCompleteModal.bind(this);

        this.handleChangeFile = this.handleChangeFile.bind(this);
        this.isObsolete = this.isObsolete.bind(this);
        this.isAvailable = this.isAvailable.bind(this);
        this.setOrderParts = this.setOrderParts.bind(this);

        this.onStarClick = this.onStarClick.bind(this);
        this.onFavoriteClick = this.onFavoriteClick.bind(this);
        this.onCloseFavoriteModal = this.onCloseFavoriteModal.bind(this);
        this.onFavoritePartsFormChange = this.onFavoritePartsFormChange.bind(this);
        this.onFavoritePartsFormClearPartsNumber = this.onFavoritePartsFormClearPartsNumber.bind(this);
        this.onFavoritePartsFormClearDescription = this.onFavoritePartsFormClearDescription.bind(this);
        this.onFavoritePartsPageNumClick = this.onFavoritePartsPageNumClick.bind(this);
        this.getFavoriteIconName = this.getFavoriteIconName.bind(this);
        this.getFavoriteIconColor = this.getFavoriteIconColor.bind(this);

        this.onEnterPartNumberChange = this.onEnterPartNumberChange.bind(this);
        this.onEnterPartAddClick = this.onEnterPartAddClick.bind(this);

    }

    componentDidMount() {
        this.props.action.loginSetLoadingError(false);
        this.props.action.sessionDataError(false);
        this.props.action.favoritePartsUpdatingError(false);
        this.props.action.orderEntryAddPartLoadingError(false);
        this.props.action.partsSetSearchingError(false);

        if (this.props.Reducer.selectedCustomerNum != '') {
            let isCartUpdated = this.props.Reducer.isCartUpdated;
            if (!isCartUpdated) {
                this.props.action.callOrderDataInSession(AppConfig.ApiUrlPartsOrder, this.props.Reducer.loginData);
            }
        }

        // if (this.props.Reducer.partsMaster.length < 1) {
        //     this.props.action.getPartsMaster(AppConfig.ApiUrlPartsOrder, this.props.Reducer.loginData);
        // }

        this.entryPartNumberRef.focus();
    }

    onWarehouseChange(evn, data) {
        let orderEntryForm = Object.assign(Object.create(this.state.orderEntryForm), this.state.orderEntryForm);
        let result = false;
        result = window.confirm('Are you sure to clear order info ?');

        if (result) {
            this.props.action.favoritePartsUpdatingError(false);
            this.props.action.orderEntryAddPartLoadingError(false);
            this.props.action.partsSetSearchingError(false);
            this.props.action.deleteSessionCart(AppConfig.ApiUrlPartsOrder, this.props.Reducer.loginData);
            this.props.action.updateOrderData([], [], data.value);
            orderEntryForm.Parts = [];

            this.setState({ orderEntryForm: orderEntryForm, partsInfo: [], orderQtyList: [], confirmOrderList: [] });
        }
    }

    onSearchPartsChange(evn, data) {
        this.setState({ partsSearch: data.value })
    }

    onRunPartsSearchClick() {
        this.setState({ isOpen: false, openAddPartsMessage: false });
        this.props.action.loginSetLoadingError(false);
        this.props.action.sessionDataError(false);
        this.props.action.favoritePartsUpdatingError(false);
        this.props.action.orderEntryAddPartLoadingError(false);

        let loginData = this.props.Reducer.loginData;
        let companyId = this.props.Reducer.loginData.Company;
        let warehouse = this.props.Reducer.warehouse;
        this.props.action.JbaseRunPartsSearch(AppConfig.ApiUrlJbaseRunPartsSearch, loginData, companyId, this.state.partsSearch, warehouse);
    }

    // onChangePartsOption(evn, data) {
    //     let partsList = this.props.Reducer.partsMaster;
    //     let partsResult = partsList.filter(d => d.partsName.toLowerCase().includes(data.value.toLowerCase()))

    //     // Create Search Parts List for Popup contents (partsModel)
    //     this.setState({ partsSearch: data.value, partsModel: partsResult })
    // }

    onAddClick(evn, data) {
        this.setState({ loading: true, isOpen: false, openAddPartsMessage: false, addProductName: '' });
        this.props.action.favoritePartsUpdatingError(false);
        this.props.action.orderEntryAddPartLoadingError(false);
        this.props.action.partsSetSearchingError(false);

        let productString = data.name.trim();
        let partsInfo = [].concat(this.state.partsInfo);
        let orderQtyList = [];
        let orderQtyListForUpdate = [].concat(this.state.orderQtyList);
        let loginData = this.props.Reducer.loginData;
        let warehouse = this.props.Reducer.warehouse;

        let isPartsSelected = partsInfo.find(x => x.ProductNumber.toLowerCase() == productString.toLowerCase());
        if (isPartsSelected) {
            alert("This parts have already added. Please select other parts or delete parts.");
            this.setState({ loading: false });
            return;
        }

        // Create http request parameter and get response
        let patchData = {};
        patchData.CompanyId = this.props.Reducer.loginData.Company;
        patchData.ProductString = productString;
        patchData.CustomerNumber = loginData.CustomerNumber;
        patchData.WareHouse = warehouse;

        fetch(AppConfig.ApiUrlJbaseRunPartsSearch + '/entry', {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(loginData.Token)
            },
            body: JSON.stringify(patchData)
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                this.setState({ loading: false });
                return response;
            })
            .then((response) => response.json())
            .then((runParts) => {
                let success = runParts.result.Success;
                if (success) {
                    if (runParts.result.Products != null) {
                        let partsObj = runParts.result.Products.Product;
                        let isPartsExist = partsObj.find(x => x.ProductNumber.toLowerCase() == productString.toLowerCase());
                        if (!isPartsExist) {
                            alert("Failed to get part information, please try to add the part again.");
                            this.setState({ loading: false });
                            return;
                        }

                        let orderItem = this.createOrderParts(partsObj, isPartsExist.ProductNumber);
                        partsInfo.push(orderItem);

                        let partsQtyForUpdate = { ProductNumber: orderItem.ProductNumber, Qty: null };
                        orderQtyListForUpdate.push(partsQtyForUpdate);
                        orderQtyList = orderQtyListForUpdate.map(x => { return { ProductNumber: x.ProductNumber, Qty: x.Qty ?? 0 } });

                        this.setState({ partsInfo: partsInfo, orderQtyList: orderQtyListForUpdate });

                        this.props.action.keepOrderPartsInSession(AppConfig.ApiUrlPartsOrder, orderQtyList, this.props.Reducer.loginData, warehouse);
                        this.props.action.updateOrderData(partsInfo, orderQtyList, warehouse);

                        this.openAddPartsMessage(isPartsExist.ProductNumber);

                    } else {
                        let message = 'Could not find the part. Please change the keywords and search again.';
                        this.setState({ loading: false });
                        this.props.action.orderEntryAddPartLoadingError(true, message);
                    }
                } else {
                    let errorMessage = runParts.result.ErrorMessage;    // errorMessage = 'Failed to search for parts. Please reload the screen.';
                    this.setState({ loading: false });
                    this.props.action.orderEntryAddPartLoadingError(true, errorMessage);
                }
            })
            .catch((e) => {
                this.setState({ loading: false });
                this.props.action.orderEntryAddPartLoadingError(true, e.message);
            });
    }

    createOrderParts(partsObj, productNumber) {
        let parts = partsObj.find(x => x.ProductNumber == productNumber);

        let orderItem = new Parts();
        orderItem.ProductNumber = parts.ProductNumber;
        orderItem.Description = parts.Description;
        orderItem.List = parts.ListPrice;
        orderItem.Price = parts.Price;
        orderItem.Obsolete = parts.Obsolete;
        orderItem.Available = parts.Available;

        let subPartsList = partsObj.filter(x => x.ProductNumber != productNumber);
        let subParts = subPartsList.map(x => {
            let subPart = new Parts();
            subPart.ProductNumber = x.ProductNumber;
            subPart.Description = x.Description;
            subPart.List = x.ListPrice;
            subPart.Price = x.Price;
            subPart.Obsolete = x.Obsolete;
            subPart.Available = x.Available;

            return subPart;
        });

        orderItem.SubParts = subParts;
        return orderItem;
    }

    openAddPartsMessage(productNumber) {
        let message = 'Added ' + productNumber + ' to cart!';
        this.setState({ openAddPartsMessage: true, addProductName: message });
    }

    onDeleteOrderPartsClick(evn, data) {
        this.props.action.favoritePartsUpdatingError(false);
        this.props.action.orderEntryAddPartLoadingError(false);
        this.props.action.partsSetSearchingError(false);

        let partsInfo = [].concat(this.state.partsInfo);
        let orderQtyList = [].concat(this.state.orderQtyList);
        const { idx, productnumber } = data;

        let delOrderParts = partsInfo.filter((x, i) => i != idx);
        let parts = orderQtyList.filter(x => x.ProductNumber != productnumber);

        partsInfo = delOrderParts;
        orderQtyList = parts.map(x => { return { ProductNumber: x.ProductNumber, Qty: x.Qty ?? 0 } });
        let orderQtyListForUpdate = parts;

        this.props.action.keepOrderPartsInSession(AppConfig.ApiUrlPartsOrder, orderQtyList, this.props.Reducer.loginData, this.props.Reducer.warehouse);
        this.props.action.updateOrderData(partsInfo, orderQtyList, this.props.Reducer.warehouse);

        this.setState({ partsInfo: partsInfo, orderQtyList: orderQtyListForUpdate });
    }

    onQtyChange(evn, data) {
        let partsInfo = [].concat(this.state.partsInfo);
        let orderQtyList = [].concat(this.state.orderQtyList);
        const { idx } = data;
        // 入力値から数字だけを抜き出しす
        let value = (data.value.match(/[\d]/g) ?? []).join('');

        let num = Number(value);
        if (Number.isInteger(num)) {
            let parts = partsInfo.find((x, i) => i == idx);
            let partQty = orderQtyList.find(x => x.ProductNumber == parts.ProductNumber);
            if (partQty) {
                partQty.Qty = num;
            } else {
                let partsQty = { ProductNumber: parts.ProductNumber, Qty: num };
                orderQtyList.push(partsQty);
            }
            this.setState({ orderQtyList: orderQtyList });
        }
    }

    onValueChange(evn, data) {
        let orderEntryForm = Object.assign(Object.create(this.state.orderEntryForm), this.state.orderEntryForm);
        const { name, value } = data;
        orderEntryForm[name] = value;

        if (name == 'ShipTo') {
            let shipto = this.props.Reducer.shipInfo.filter(x => x.ShiptoNumber == value);
            if (shipto) {
                orderEntryForm.ShipVia = shipto[0].ShipDefault;
            }
        }

        this.setState({ orderEntryForm: orderEntryForm });
    }

    async onOpenConfirmModal(evn, data) {
        this.props.action.loginSetLoadingError(false);
        this.props.action.sessionDataError(false);
        this.props.action.favoritePartsUpdatingError(false);
        this.props.action.orderEntryAddPartLoadingError(false);
        this.props.action.partsSetSearchingError(false);

        let partsInfo = [].concat(this.state.partsInfo);
        if (partsInfo.length < 1) {
            alert("You need to set detail.");
            return;
        }

        this.setState({ isOpen: false });
        let orderEntryForm = await this.setOrderParts();
    }

    checkQty() {
        let orderQtyList = [].concat(this.state.orderQtyList);
        let QtyErrors = [];
        if (orderQtyList.length == 0) {
            QtyErrors.OrderQuantity = "OrderQuantity cannot be zero. Please enter OrderQuantity.";
        } else {
            orderQtyList.forEach(x => {
                if (x.Qty == null || x.Qty == "" || x.Qty <= 0) {
                    QtyErrors.OrderQuantity = "OrderQuantity cannot be zero, a negative number, or a decimal number. Please enter OrderQuantity.";
                }
            })
        }
        return QtyErrors;
    }

    onCloseConfirmModal(evn, data) {
        let orderEntryForm = Object.assign(Object.create(this.state.orderEntryForm), this.state.orderEntryForm);
        orderEntryForm.Parts = [];

        this.setState({ orderEntryForm: orderEntryForm, openConfirmModal: false, getSubLoadingError: false, errorMessage: '' });
        this.props.action.orderEntryFormSetSavingError(false);
    }

    onNextClick() {
        let orderEntryForm = Object.assign(Object.create(this.state.orderEntryForm), this.state.orderEntryForm);
        let backOrder = Object.assign(Object.create(this.state.backOrder), this.state.backOrder);
        let confirmOrderList = Object.assign(Object.create(this.state.confirmOrderList), this.state.confirmOrderList);

        // Sum order-quantity for same parts (orderEntryForm)
        orderEntryForm.Parts = this.ajustOrderQuantity(orderEntryForm.Parts, false);

        // Sum order-quantity for same parts (backOrder)
        backOrder = this.ajustOrderQuantity(backOrder, true);

        // Check if inventory is enough for order or not (parts-available)
        orderEntryForm.Parts.forEach(x => {
            let available = Number(x.Available);
            let orderQuantity = Number(x.OrderQuantity);

            // True → not enough inventory
            if (available < orderQuantity) {
                x.OrderQuantity = available;

                // Get parts-property, breakdown of quantity → { "MainParts" : productNumber, "OrderQuantity" : num }
                let productNumberAndOrderQuantityList = x.ProductNumberAndOrderQuantity;
                let isExist = false;    // Check if the parts is ordered as a main -parts
                productNumberAndOrderQuantityList.forEach(parts => {

                    if (isExist) { return; }

                    // Get main-parts and sub-parts list from comfirm modal item
                    let partsList = confirmOrderList[parts.MainParts];
                    if (partsList.length < 2) { return; }

                    // Get parts information
                    let orderParts = orderEntryForm.Parts.find(x => x.ProductNumber == parts.MainParts)
                    if (!this.isObsolete(orderParts)) {
                        orderParts = partsList[1];
                    }

                    // sum order-quantity
                    backOrder.map(xxx => {
                        if (xxx.ProductNumber == orderParts.ProductNumber) {
                            xxx.BoQty = Number(xxx.BoQty) + Number(parts.OrderQuantity);
                        }
                    });

                    isExist = confirmOrderList[x.ProductNumber] ? false : true;
                });
            }
        })

        // Delete record which OrderQuantity is 0 (OrderEntryForm.Parts only)
        // The reason for executing the action at this timing is to ajust quantity of sub-parts which main-parts is obsolete 'Y'
        let filteredOrderEntry = orderEntryForm.Parts.filter(x => x.OrderQuantity != 0);
        orderEntryForm.Parts = filteredOrderEntry;

        this.props.action.orderEntryFormSetSavingError(false);
        this.setState({ confirmFlg: true, openConfirmModal: false, orderEntryForm: orderEntryForm, backOrder: backOrder, confirmOrderList: {}, getSubLoadingError: false, errorMessage: '' });
    }

    ajustOrderQuantity(orderEntry, isBackOrder) {
        let duplicatePartsList = [];
        orderEntry.forEach((order, idx) => {
            // First run
            if (idx == 0) {
                duplicatePartsList.push(order);
            }

            // Run after the first time
            if (idx != 0) {
                // Check if product-number is contains or not
                let parts = duplicatePartsList.find(x => x.ProductNumber == order.ProductNumber);
                if (parts) {
                    // sum order-quantity
                    if (isBackOrder) {
                        parts.BoQty = Number(parts.BoQty) + Number(order.BoQty);
                    } else {
                        if (parts.Available < order.Available) {
                            parts.Available = order.Available;
                        }
                        parts.OrderQuantity = Number(parts.OrderQuantity) + Number(order.OrderQuantity);
                        parts.ProductNumberAndOrderQuantity = parts.ProductNumberAndOrderQuantity.concat(order.ProductNumberAndOrderQuantity);
                    }
                } else {
                    duplicatePartsList.push(order);
                }
            }
        });

        return duplicatePartsList;
    }

    onBackClick(evn, data) {
        let orderEntryForm = Object.assign(Object.create(this.state.orderEntryForm), this.state.orderEntryForm);
        orderEntryForm.Parts = [];

        this.setState({ orderEntryForm: orderEntryForm, confirmFlg: false });
        this.props.action.orderEntryFormSetSavingError(false);
    }

    onPurchaseNowClick(evn, data) {
        this.props.action.orderEntryFormSetSavingError(false);
        let companyId = this.props.Reducer.loginData.Company;
        let warehouse = this.props.Reducer.warehouse;

        if (this.state.orderEntryForm.Parts.length == 0 && this.state.backOrder.length == 0) {
            this.props.action.orderEntryFormSetSavingError(true, "No parts available for order, please select again.");
        } else {
            this.props.action.orderEntryFormSave(AppConfig.ApiUrlPartsOrder, warehouse, companyId, this.state.orderEntryForm, this.state.backOrder, this.props.Reducer.loginData);
        }
    }

    onCloseCompleteModal(evn, data) {
        this.props.action.closeOrderEntryCompleteModal();
        const { name } = data;
        this.props.action.menuChange(name);
    }

    // Read XML File and add Parts data
    //#region
    onAddFileClick() {
        document.getElementById('js-image-file').value = null;
        document.getElementById('js-image-file').click();
    }

    handleChangeFile() {
        let orderEntryForm = Object.assign(Object.create(this.state.orderEntryForm), this.state.orderEntryForm);
        let fileOrderEntry = new OrderEntryForm();
        let loginData = this.props.Reducer.loginData;
        let warehouse = this.props.Reducer.warehouse;
        let partsInfo = [].concat(this.state.partsInfo);
        let orderQtyList = this.state.orderQtyList.map(x => { return { ProductNumber: x.ProductNumber, Qty: x.Qty ?? 0 } });
        let that = this;
        let orderQtyListForUpdate = [].concat(this.state.orderQtyList);

        let file = document.getElementById('js-image-file').files[0];
        let fileName = file.name.replace(/ /g, '');

        // Check file extension (only xml)
        let extension = Functions.getExt(fileName);
        if (extension != 'xml') {
            return alert("XML file only.");
        }

        // Check characters used in file name
        let error = Functions.checkFileName(fileName);
        if (error) {
            return alert('Following characters are not available for file name. Please remove these characters to attach.\n' + '"　' + "'　" + '<　>　[　]　|　#　;　:　?　@　$　&　=　+　*　,　/　%　・');
        } else {
            var fr = new FileReader();
            fr.readAsText(file);
            fr.onload = function () {
                var XMLParser = require('react-xml-parser');
                var xml = new XMLParser().parseFromString(fr.result);    // Assume xmlText contains the example XML
                let customerNumber = Number(xml.getElementsByTagName('customer-number')[0].value);
                // let shipTo = xml.getElementsByTagName('ship-to')[0].value;
                // let shipVia = xml.getElementsByTagName('ship-via')[0].value;
                // let poNumber = xml.getElementsByTagName('customer-po')[0].value;
                let Items = xml.getElementsByTagName('items')[0];

                // Check the Customer Number
                // if (loginData.CustomerNumber != customerNumber) {
                //     alert("Customer # does not match. Please reload the screen.");
                //     return;
                // }

                // // Order Header
                // orderEntryForm.ShipTo = shipTo;
                // orderEntryForm.ShipVia = shipVia;
                // orderEntryForm.PurchaseOrderNumber = poNumber;

                // Items
                Items.children.map(x => {
                    let partsNumber = x.children[0];    // parts-number
                    let qty = x.children[1];            // quantity

                    let orderItem = new Parts();
                    orderItem.ProductNumber = partsNumber.value;
                    orderItem.Description = '';
                    orderItem.List = 0;
                    orderItem.Price = 0;
                    orderItem.OrderQuantity = qty.value;

                    fileOrderEntry.Parts.push(orderItem);
                })
                // that.setState({ orderEntryForm: orderEntryForm });

                // Exclude parts which is already selected
                let filteredFileOrderEntry = new OrderEntryForm();
                partsInfo.map(x => {
                    filteredFileOrderEntry.Parts = fileOrderEntry.Parts.filter(fileItem => fileItem.ProductNumber != x.ProductNumber);
                    fileOrderEntry = filteredFileOrderEntry;
                })

                // Create http request parameter and get response
                let patchData = {};
                patchData.CompanyId = that.props.Reducer.loginData.Company;
                patchData.ProductString = fileOrderEntry.Parts;
                patchData.CustomerNumber = that.props.Reducer.loginData.CustomerNumber;
                patchData.WareHouse = warehouse;

                that.setState({ loading: true, isOpen: false });
                that.props.action.favoritePartsUpdatingError(false);
                that.props.action.orderEntryAddPartLoadingError(false);
                that.props.action.partsSetSearchingError(false);

                fetch(AppConfig.ApiUrlJbaseRunPartsSearch + '/bulkOrder', {
                    mode: 'cors', method: 'post', credentials: 'include',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'csrftoken': JSON.stringify(loginData.Token)
                    },
                    body: JSON.stringify(patchData)
                })
                    .then((response) => {
                        if (!response.ok) {
                            return response.json()
                                .then(function (err) {
                                    throw new Error(err.Message);
                                });
                        }
                        that.setState({ loading: false });
                        return response;
                    })
                    .then((response) => response.json())
                    .then((runParts) => {
                        let resultProducts = runParts.result;
                        let mainPartsList = runParts.productNumberList;

                        resultProducts.map(product => {
                            mainPartsList.map(productNumber => {
                                let partsObj = product[productNumber];
                                if (!partsObj) {
                                    return;
                                }

                                let orderItem = that.createOrderParts(partsObj, productNumber);
                                partsInfo.push(orderItem);

                                let orderQuantity = fileOrderEntry.Parts.find(fileItem => fileItem.ProductNumber == productNumber).OrderQuantity;
                                let partsQty = { ProductNumber: productNumber, Qty: orderQuantity };
                                orderQtyList.push(partsQty);
                                orderQtyListForUpdate = orderQtyList.map(x => { return { ProductNumber: x.ProductNumber, Qty: x.Qty ?? 0 } });

                                that.setState({ orderEntryForm: orderEntryForm, partsInfo: partsInfo, orderQtyList: orderQtyListForUpdate });
                            });
                        });

                        that.props.action.keepOrderPartsInSession(AppConfig.ApiUrlPartsOrder, orderQtyList, that.props.Reducer.loginData, warehouse);
                        that.props.action.updateOrderData(partsInfo, orderQtyList, warehouse);
                    })
                    .catch((e) => {
                        this.setState({ loading: false });
                        this.props.action.orderEntryAddPartLoadingError(true, e.message);
                    });
            };
            fr.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
    };
    //#endregion

    // runPartsSearch result modal
    //#region
    onCloseRunPartsSearchResultModal(evn, data) {
        this.props.action.favoritePartsUpdatingError(false);
        this.props.action.orderEntryAddPartLoadingError(false);
        this.props.action.partsSetSearchingError(false);
        this.props.action.closeRunPartsSearchResultModal();
        this.setState({ searchPartsNumber: '', searchDescription: '', openAddPartsMessage: false, addProductName: '' });
    }
    onPartsNumberSearchChange(evn, data) {
        this.setState({ searchPartsNumber: data.value });
    }

    onDescriptionSearchChange(evn, data) {
        this.setState({ searchDescription: data.value });
    }

    onCustomerNumChange(evn, data) {
        let oldSelectedNum = this.props.Reducer.selectedCustomerNum;
        let selectedCustomerNum = data.value;

        this.props.action.orderEntryAddPartLoadingError(false);
        this.props.action.loginSetLoadingError(false);

        if (oldSelectedNum != selectedCustomerNum) {
            if (oldSelectedNum != '') {
                var result = window.confirm("If you change Customer#, the current input will be discarded.");
                if (result) {
                    let orderEntryForm = new OrderEntryForm();
                    this.setState({
                        orderEntryForm,
                        partsInfo: [],
                        orderQtyList: [],
                        confirmOrderList: [],
                        openAddPartsMessage: false,
                        addProductName: '',
                        enterPartNumber: '',
                        partsSearch: '',
                    });
                    this.props.action.favoritePartsUpdatingError(false);
                    this.props.action.orderEntryAddPartLoadingError(false);
                    this.props.action.partsSetSearchingError(false);

                    this.props.action.setCustomerNumber(selectedCustomerNum, this.props.Reducer.loginData);
                    this.props.action.deleteSessionCart(AppConfig.ApiUrlPartsOrder, this.props.Reducer.loginData);
                    this.props.action.updateOrderData([], [], "100");
                    this.props.action.deletePartsOrders();

                    if (selectedCustomerNum != '') {
                        this.props.action.loginConnect(AppConfig.ApiUrlPartsLogin, selectedCustomerNum, true, this.props.Reducer.loginData);
                    }
                }
            } else {
                this.props.action.favoritePartsUpdatingError(false);
                this.props.action.orderEntryAddPartLoadingError(false);
                this.props.action.partsSetSearchingError(false);

                this.props.action.setCustomerNumber(selectedCustomerNum, this.props.Reducer.loginData);
                if (selectedCustomerNum != '') {
                    this.props.action.loginConnect(AppConfig.ApiUrlPartsLogin, selectedCustomerNum, true, this.props.Reducer.loginData);
                }
            }
        }
    }

    onSearchClearClickPartsNumber() {
        this.setState({ searchPartsNumber: '' });
    }

    onSearchClearClickDescription() {
        this.setState({ searchDescription: '' });
    }

    onPageNumClick(evn, data) {
        this.props.action.selectModalPage(data.page);
    }
    //#endregion

    // onOpenPopup() {
    //     this.setState({ isOpen: true });
    // }

    isObsolete(part) {
        return part.Obsolete == 'N';
    }

    isAvailable(part, quantity) {
        return Number(part.Available) >= Number(quantity);
    }

    async setOrderParts() {
        this.setState({ detailErrors: {} });

        let orderEntryForm = Object.assign(Object.create(this.state.orderEntryForm), this.state.orderEntryForm);
        let backOrder = []; // BackOrderするパーツ一覧
        let orderQtyList = [].concat(this.state.orderQtyList); // 画面から入力されたパーツと注文数の一覧
        let partsInfo = [].concat(this.state.partsInfo); // jBASEから取得した注文するパーツの情報
        let orders = {}; // ConfirmModalに表示する、実際に購入するパーツ一覧
        let gotJbaseParts = partsInfo.map(x => JSON.parse(JSON.stringify(x))); // jBASEからrunPartsSearchで取得したパーツを入れる

        // Validation check
        let QtyErrors = this.checkQty();
        let errors = orderEntryForm.confirmValidate();
        errors = Object.assign(errors, QtyErrors);
        if (Object.keys(errors).length > 0) {
            this.setState({ detailErrors: errors });
            return;
        }

        this.setState({ getSubLoading: true, getSubLoadingError: false, errorMessage: '', openConfirmModal: true });
        // console.log(`start: Partsの在庫確認を開始`);
        await Promise.all(partsInfo.map(async (mainParts) => {
            let tempParts = []; //注文するパーツを蓄積
            let checkedPartsList = []; // 確認し終わったPartsを蓄積(Mainをパーツを含む)
            let quantity = Number(orderQtyList.find(x => x.ProductNumber == mainParts.ProductNumber).Qty); // パーツの注文数
            let backOrderProductNumber = this.isObsolete(mainParts) ? mainParts.ProductNumber : ""; //BackOrderする時のパーツ

            // Mainパーツの確認
            // #region
            // isAvailableがtrueならOrderQuantityに注文個数を代入して処理を終了する
            // isAvailableがfalseならAvailableの分だけOrderQuantityに代入して、subPartsを確認
            // isAvailable ⇒ True (enough inventory to buy the parts)
            // console.log(`*Main(${mainParts.ProductNumber})の確認`);
            let checkingParts = gotJbaseParts.find(x => x.ProductNumber == mainParts.ProductNumber);
            if (this.isAvailable(checkingParts, quantity)) {
                checkingParts.OrderQuantity = quantity;
                quantity = 0;
            } else {
                if (checkingParts.Available < 0) {
                    checkingParts.Available = 0;
                }
                checkingParts.OrderQuantity = checkingParts.Available;
                quantity = Functions.toNumberToFixed(Functions.decimalSubtraction(quantity, checkingParts.OrderQuantity), 0);
            }

            tempParts.push({...checkingParts});
            // パーツの在庫を注文数だけ減らす
            checkingParts.Available = Functions.toNumberToFixed(Functions.decimalSubtraction(checkingParts.Available, checkingParts.OrderQuantity), 0);
            mainParts.ProductNumberAndOrderQuantity = [{ "MainParts": mainParts.ProductNumber, "OrderQuantity": checkingParts.OrderQuantity }];
            checkedPartsList.push(checkingParts.ProductNumber); // 確認済みパーツリストにパーツ名を追加
            //#endregion

            // Subパーツの確認
            //#region
            if (quantity > 0 && mainParts.SubParts.length != 0) {
                // Mainパーツだけでは注文数に届かないため、Subパーツを確認する
                let subPartsList = mainParts.SubParts.map(x => JSON.parse(JSON.stringify(x))); // 今から確認する階層のSubパーツのリスト
                let loopEndFlag = false; //Subパーツ確認終わりのフラグ

                for (let count = 0; count < 4; count++) { //subは４階層まで見に行く
                    if (loopEndFlag) {
                        // Subパーツの確認を終了。終了条件は以下
                        // 1.Sub's Subが無いとき
                        // 2.4階層まで確認し終わったとき
                        // 3.今までに確認したパーツがまたSubパーツとして出てきたとき（2回目に出てきた階層まではチェックする）
                        // 4.quantityが0になったとき
                        break;
                    }

                    let tempSubParts = []; //subPartsListのパーツのSubを入れる配列
                    await Promise.all(subPartsList.map(async (subParts) => {
                        if (quantity == 0) {
                            loopEndFlag = true;
                            return; // 次のSubを調べる
                        }

                        if (checkedPartsList.includes(subParts.ProductNumber)) {
                            // 今までに確認したパーツが出てきたらその階層までのチェックで終了
                            loopEndFlag = true;
                            return; // 次のSubを調べる
                        }

                        // console.log(`--Main(${mainParts.ProductNumber})のSub${count+1}(${subParts.ProductNumber})の確認`);

                        // 対象のPartsをgotJbasePartsから探す
                        let jbaseParts = gotJbaseParts.find(x => x.ProductNumber == subParts.ProductNumber);
                        checkingParts = jbaseParts ?? subParts;

                        // isAvailableがtrueならOrderQuantityに注文個数を代入して処理を終了する
                        // isAvailableがfalseならAvailableの分だけOrderQuantityに代入して、subPartsを確認
                        // isAvailable ⇒ True (enough inventory to buy the parts)
                        if (this.isAvailable(checkingParts, quantity)) {
                            checkingParts.OrderQuantity = quantity;
                            quantity = 0;
                        } else {
                            if (checkingParts.Available < 0) {
                                checkingParts.Available = 0;
                            }
                            checkingParts.OrderQuantity = checkingParts.Available;
                            quantity = Functions.toNumberToFixed(Functions.decimalSubtraction(quantity, checkingParts.OrderQuantity), 0);
                        }

                        // OrderQtyが1以上のサブパーツのみConfirmModalに表示
                        if (checkingParts.OrderQuantity > 0) {
                            tempParts.push({...checkingParts});
                            // パーツの在庫を注文数だけ減らす
                            checkingParts.Available = Functions.toNumberToFixed(Functions.decimalSubtraction(checkingParts.Available, checkingParts.OrderQuantity), 0);
                            checkingParts.ProductNumberAndOrderQuantity = [{ "MainParts": mainParts.ProductNumber, "OrderQuantity": checkingParts.OrderQuantity }];
                        }

                        // 確認済みパーツとしてcheckedPartsに追加
                        checkedPartsList.push(subParts.ProductNumber);

                        if (quantity == 0) {
                            loopEndFlag = true;
                            return; // Subパーツの確認を終了
                        }

                        // BackOrder用のパーツがまだ見つかっていない、かつBackOrderが可能なパーツだったら今のパーツをBackOrder用のパーツにする
                        // isObsolete ⇒ True (Obsolete is 'N')
                        if (backOrderProductNumber == "" && this.isObsolete(checkingParts)) {
                            backOrderProductNumber = checkingParts.ProductNumber;
                        }

                        if (!jbaseParts) {
                            // gotJbasePartsにないパーツなので追加する
                            gotJbaseParts.push(checkingParts);

                            // まだjBASEから取ってきてないパーツなので、SubをメインパーツとしてWCFコールする
                            // Sub's Subを取得する
                            await fetch(AppConfig.ApiUrlJbaseRunPartsSearch + '/entry', {
                                mode: 'cors', method: 'post', credentials: 'include',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'csrftoken': JSON.stringify(this.props.Reducer.loginData.Token)
                                },
                                body: JSON.stringify({
                                    CompanyId: this.props.Reducer.loginData.Company,
                                    ProductString: subParts.ProductNumber,
                                    CustomerNumber: this.props.Reducer.loginData.CustomerNumber,
                                    WareHouse: this.props.Reducer.warehouse,
                                }),
                            })
                                .then(response => {
                                    if (!response.ok) {
                                        return response.json()
                                            .then(function (err) {
                                                throw new Error(err.Message);
                                            });
                                    }
                                    return response;
                                })
                                .then(response => response.json())
                                .then(runParts => {
                                    if (runParts.result.Success) {
                                        if (runParts.result.Products != null) {
                                            let partsObj = runParts.result.Products.Product;
                                            if (partsObj.length > 1) {
                                                // 一つはSub自身のため、2つ以上の場合にSub's Subがある
                                                let orderItem = this.createOrderParts(partsObj, subParts.ProductNumber);
                                                orderItem.SubParts.forEach(x => tempSubParts.push(x) );
                                            }
                                        }
                                    } else {
                                        throw new Error("Failed to get parts data, please try to click the confirm button.");
                                    }
                                    return;
                                })
                                .catch(e => {
                                    this.setState({ getSubLoading: false, getSubLoadingError: true, errorMessage: e.message });
                                    return;
                                })
                        } else {
                            jbaseParts.SubParts.forEach(x => tempSubParts.push(x));
                        }
                    }))

                    if (tempSubParts.length != 0) {
                        // 次に確認するSub's SubのリストをsubPartsListに入れる
                        subPartsList = tempSubParts;
                    } else {
                        loopEndFlag = true;
                        subPartsList = [];
                    }
                }
            }
            //#endregion

            // BackOrderの確認
            //#region
            if (quantity > 0 && backOrderProductNumber != "") {
                // MainパーツとSubパーツでは注文数に届かないため、BackOrderする
                // console.log(`--Main(${mainParts.ProductNumber})のBackOrder(${backOrderProductNumber})`);
                let backOrderItem = {
                    Main: mainParts.ProductNumber,
                    Description: mainParts.Description,
                    List: mainParts.List,
                    Price: mainParts.Price,
                    ProductNumber: backOrderProductNumber,
                    BoQty: quantity,
                };
                backOrder.push(backOrderItem);

                // tempPartsにbackOrderProductNumberのパーツが無いとBackQtyが表示されないため、無ければ追加する
                if (!tempParts.some(x => x.ProductNumber == backOrderProductNumber)) {
                    let parts = gotJbaseParts.find(x => x.ProductNumber == backOrderProductNumber);
                    parts.OrderQuantity = 0;
                    tempParts.push({...parts});
                }
            }
            //#endregion

            // 全てのパーツのAvailableが0、かつObsoleteも"Y"で、BackOrderもできない時以外に実行する
            if (!( backOrderProductNumber == "" // BackOrderができない。全てのパーツのObsoleteが"Y"
                && tempParts.length == 1 // 全SubパーツのAvailableが0(Mainパーツは必ず追加されている)(SubパーツはAvailableが0だとtempPartsに追加されない)
                && tempParts[0].OrderQuantity == 0 // Mainパーツの注文数が0
            )) {
                orders[mainParts.ProductNumber] = tempParts;
                orderEntryForm.Parts = orderEntryForm.Parts.concat(tempParts);
            }
            // console.log(`===Main(${mainParts.ProductNumber})の確認終了`);
        }));

        // console.log("completed: すべてのPartsの処理を完了");
        this.setState({ orderEntryForm, backOrder, confirmOrderList: orders, getSubLoading: false });
    };

    onBlurFunc() {
        this.props.action.favoritePartsUpdatingError(false);
        this.props.action.orderEntryAddPartLoadingError(false);
        this.props.action.partsSetSearchingError(false);

        let orderQtyList = this.state.orderQtyList.map(x => { return { ProductNumber: x.ProductNumber, Qty: x.Qty ?? 0 } });
        let orderQtyListForUpdate = [].concat(this.state.orderQtyList);
        let partsInfo = [].concat(this.state.partsInfo);

        this.props.action.keepOrderPartsInSession(AppConfig.ApiUrlPartsOrder, orderQtyList, this.props.Reducer.loginData, this.props.Reducer.warehouse);
        this.props.action.updateOrderData(partsInfo, orderQtyList, this.props.Reducer.warehouse);
    }

    onStarClick(evn, data) {
        this.setState({ openAddPartsMessage: false });
        this.props.action.favoritePartsUpdatingError(false);
        this.props.action.orderEntryAddPartLoadingError(false);
        this.props.action.partsSetSearchingError(false);

        let loginData = this.props.Reducer.loginData;
        let foundPart = this.props.Reducer.favoriteParts.find(x => x.ProductNumber == data.productnumber);
        let isAdd = foundPart ? false : true;
        let favoritePart = ({ ProductNumber: data.productnumber, Description: data.description ? data.description : "" });

        this.props.action.updateFavoriteParts(AppConfig.ApiUrlFavoriteParts, loginData, favoritePart, isAdd);
    }

    onFavoriteClick() {
        this.props.action.loginSetLoadingError(false);
        this.props.action.sessionDataError(false);
        this.props.action.favoritePartsUpdatingError(false);
        this.props.action.orderEntryAddPartLoadingError(false);
        this.props.action.partsSetSearchingError(false);

        let favoriteParts = JSON.parse(JSON.stringify(this.props.Reducer.favoriteParts));
        this.setState({
            favoriteParts,
            isFavoritePartsModalOpen: true,
            currentFavoritePartsModalPage: 1,
            openAddPartsMessage: false,
        });
    }

    onCloseFavoriteModal() {
        this.setState({ isFavoritePartsModalOpen: false, openAddPartsMessage: false });
        this.props.action.sessionDataError(false);
        this.props.action.favoritePartsUpdatingError(false);
        this.props.action.orderEntryAddPartLoadingError(false);
    }

    onFavoritePartsFormChange(evn, data) {
        this.setState({ [data.name]: data.value });
    }

    onFavoritePartsFormClearPartsNumber() {
        this.setState({ favoritePartsNumber: "" });
    }

    onFavoritePartsFormClearDescription() {
        this.setState({ favoritePartsDescription: "" });
    }

    onFavoritePartsPageNumClick(evn, data) {
        this.setState({ currentFavoritePartsModalPage: data.page });
    }

    getFavoriteIconName(productNumber) {
        return this.props.Reducer.favoriteParts.find(p => p.ProductNumber == productNumber) ? 'star' : 'star outline';
    }

    getFavoriteIconColor(productNumber) {
        return this.props.Reducer.favoriteParts.find(p => p.ProductNumber == productNumber) ? 'yellow' : 'grey';
    }

    onEnterPartNumberChange(evn, data) {
        this.setState({ enterPartNumber: data.value });
    }

    onEnterPartAddClick(evn, data) {
        this.onAddClick(evn, data);
        this.setState({ enterPartNumber: '' });
        this.entryPartNumberRef.focus();
    }

    render() {
        let styleTableWide = { width: 120 };
        let styleButton = { marginTop: 20 };
        let styleWideModal = { marginTop: 10, margin: 'auto', width: 1200 };
        let styleModal = { marginTop: 10, margin: 'auto' };
        let styleTotalTable = { width: 400 };
        let styleInputWide = { width: 150 };
        let sytleMargin = { marginTop: 0 };
        let styleClear = { clear: 'both', };
        let styleInput = { width: 300 };
        let styleSearchButton = { width: 145, marginTop: 0 };
        let stylePagemenu = { marginRight: 10, };
        let styleSearchunderBox = { width: '190px', height: '36px' };
        let styleWarningMessage = { color: 'red', fontSize: '1.2rem' };
        let styleFormPadding = { padding: 0, marginTop: 0 };
        let styleflex = { display: 'flex', 'justify-content': 'space-between' };
        let styleNoPadding = { padding: 0 };
        let styleLabelBorder = { borderWidth: '0' };
        let styleSegment = { marginTop: -15 };

        // Init
        let orderEntryForm = this.state.orderEntryForm;
        let confirmOrderList = this.state.confirmOrderList;
        let partsInfo = this.state.partsInfo;
        let backOrderList = this.state.backOrder;
        let orderQtyList = this.state.orderQtyList;
        let category = this.props.Reducer.category;
        let cart = this.props.Reducer.cart;
        let qtyListFromReducer = this.props.Reducer.orderQtyList;
        if (cart && qtyListFromReducer) {
            cart.map(cartItem => {
                let isPartsSelected = partsInfo.find(x => x.ProductNumber == cartItem.ProductNumber);
                let parts = qtyListFromReducer.find(x => x.ProductNumber == cartItem.ProductNumber);
                let orderQuantity = parts.Qty;

                if (!isPartsSelected) {
                    let partsQty = { ProductNumber: cartItem.ProductNumber, Qty: orderQuantity };

                    partsInfo.push(cartItem);
                    orderQtyList.push(partsQty);
                }
            })
        }

        // Manage disabled
        let selectShipToFlag = false;
        let isBulkOrderFlag = this.props.Reducer.loginData ? this.props.Reducer.loginData.IsBulkOrder : false;
        let selectWarehouseFlag = !this.props.Reducer.warehouse ? true : false;
        let selectCustomerNumFlag = this.props.Reducer.selectedCustomerNum ? true : false;

        // Create dropdown contents
        let dropShipToOption = [{ value: '', text: '' }];
        this.props.Reducer.shipInfo.map(x => {
            dropShipToOption.push({ value: x.ShiptoNumber, text: x.ShiptoNumber + ' : ' + x.ShiptoName + '(' + x.Address + ' ' + x.City + ', ' + x.State + ' ' + x.Zipcode + ')' });
        });

        let dropShipViaOption = [{ value: '', text: '' }];
        if (this.state.orderEntryForm.ShipTo != '' && this.state.orderEntryForm.ShipTo != null) {
            selectShipToFlag = true;
            let shipto = this.props.Reducer.shipInfo.filter(x => x.ShiptoNumber == this.state.orderEntryForm.ShipTo);
            if (shipto) {
                shipto[0].ShipVias.map(x => {
                    dropShipViaOption.push({ value: x.Shpcde, text: x.Shpvia + '($' + x.Shpchg + ')' });
                });
            }
        }

        let customerOption = [{ value: '', text: '' }];
        this.props.Reducer.customerNumList.map(x => {
            customerOption.push({ value: x, text: x });
        });

        let warehouse = this.props.Reducer.loginData.SelectableWarehouse ?
            <Dropdown placeholder='Select Warehouse'
                search selection
                name='WareHouse' value={this.props.Reducer.warehouse}
                onChange={this.onWarehouseChange}
                options={this.props.Reducer.warehouseOption}
                disabled={!selectCustomerNumFlag} />
            : <Input name='WareHouse' value={this.props.Reducer.warehouse} disabled />;

        // Table items which is created by Add-button
        let total = 0;
        let orderEntryInputTableItems = partsInfo.length > 0 ? partsInfo.map((x, idx) => {
            let partsQty = orderQtyList.find(p => p.ProductNumber == x.ProductNumber);
            let subTotal = partsQty && Functions.checkNumber(partsQty.Qty) ? Functions.decimalMultiplication(x.Price, partsQty.Qty) : 0;
            total = Functions.decimalAddition(total, subTotal);
            let iconName = this.getFavoriteIconName(x.ProductNumber);
            let iconColor = this.getFavoriteIconColor(x.ProductNumber);

            return (
                <Table.Row key={idx}>
                    <Table.Cell>{x.ProductNumber}</Table.Cell>
                    <Table.Cell>{x.Description}</Table.Cell>
                    <Table.Cell>${x.List}</Table.Cell>
                    <Table.Cell>${x.Price}</Table.Cell>
                    <Table.Cell><Input fluid value={partsQty && Functions.checkNumber(partsQty.Qty) ? partsQty.Qty : ''} onChange={this.onQtyChange} idx={idx} onBlur={() => this.onBlurFunc()} error={!!this.state.detailErrors.OrderQuantity && (partsQty.Qty == null || partsQty.Qty == 0)} disabled={!selectCustomerNumFlag} ><input tabindex="1" /></Input></Table.Cell>
                    <Table.Cell><Input label='$' fluid disabled value={subTotal} /></Table.Cell>
                    <Table.Cell textAlign='center'><Button basic productnumber={x.ProductNumber ? x.ProductNumber : ''} description={x.Description} icon={iconName} onClick={this.onStarClick} color={iconColor} tabindex="-1" /></Table.Cell>
                    <Table.Cell textAlign='center'><Button idx={idx} productnumber={x.ProductNumber ? x.ProductNumber : ''} content='Delete' onClick={this.onDeleteOrderPartsClick} color='red' tabindex="-1" /></Table.Cell>
                </Table.Row>)
        }).reverse() : <Table.Row></Table.Row>;

        // Confirm modal items
        // let partsTable = orderQtyList && Object.keys(orderEntryForm.Parts).length > 0 ? orderQtyList.map((orderPart, idx) => {
        let partsTable = orderQtyList ? orderQtyList.map((orderPart, idx) => {
            let entryPart = confirmOrderList[orderPart.ProductNumber];

            if (!entryPart) {
                return (
                    <Table key={idx}>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell><Header as='h3'>{orderPart.ProductNumber} ( Qty : {orderPart.Qty} ) </Header></Table.Cell>
                                <Table.Cell><Header color='red'>This part is not available for order</Header></Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                );
            }

            let actualOrderQty = 0;
            let tbody = entryPart.map((part, entryIdx) => {
                let backOrderPart = this.state.backOrder.find(y => y.ProductNumber == part.ProductNumber && y.Main == orderPart.ProductNumber);
                actualOrderQty += (Number(part.OrderQuantity ? part.OrderQuantity : 0) + Number(backOrderPart ? backOrderPart.BoQty : 0));
                return (
                    <Table.Row key={entryIdx}>
                        <Table.Cell>{part.ProductNumber}</Table.Cell>
                        <Table.Cell>{part.Description}</Table.Cell>
                        <Table.Cell>${part.List}</Table.Cell>
                        <Table.Cell>${part.Price}</Table.Cell>
                        <Table.Cell>{part.Obsolete == 'Y' ? 'Yes' : 'No'}</Table.Cell>
                        <Table.Cell>{part.Available}</Table.Cell>
                        <Table.Cell>{part.OrderQuantity ? part.OrderQuantity : 0}</Table.Cell>
                        <Table.Cell>{backOrderPart ? backOrderPart.BoQty : 0}</Table.Cell>
                        <Table.Cell><Input label='$' fluid disabled value={Functions.decimalMultiplication(part.Price, part.OrderQuantity)} /></Table.Cell>
                    </Table.Row>
                )
            });
            let message = orderPart.Qty != actualOrderQty
                ? <Header as='h3' color='red' style={{display: 'inline', margin: 'auto'}}>
                    {orderPart.Qty - actualOrderQty} order is not available since inventory shortage (including sub parts) and obsolete.
                </Header>
                : "";

            return (
                <Table celled key={idx}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='9'>
                                <Header as='h3' style={{display: 'flex'}}>
                                    {orderPart.ProductNumber} ( Qty : {orderPart.Qty} ) {message}
                                </Header>
                                </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell><Header as='h3'>Part#</Header></Table.HeaderCell>
                            <Table.HeaderCell><Header as='h3'>Product Description</Header></Table.HeaderCell>
                            <Table.HeaderCell><Header as='h3'>List Price</Header></Table.HeaderCell>
                            <Table.HeaderCell><Header as='h3'>Price</Header></Table.HeaderCell>
                            <Table.HeaderCell><Header as='h3'>Obsolete</Header></Table.HeaderCell>
                            <Table.HeaderCell><Header as='h3'>Available</Header></Table.HeaderCell>
                            <Table.HeaderCell><Header as='h3'>Qty</Header></Table.HeaderCell>
                            <Table.HeaderCell><Header as='h3'>Back Qty</Header></Table.HeaderCell>
                            <Table.HeaderCell><Header as='h3'>Total</Header></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {tbody}
                    </Table.Body>
                </Table>
            )
        }) : '';

        // Popup Contents
        //#region
        // let partsMessage = '';
        // let result = [];
        // if (this.state.partsSearch != '' && this.state.partsModel.length <= 10000 && this.state.partsModel.length > 0) {
        //     result = this.state.partsModel;
        // } else if (this.state.partsModel.length >= 10000) {
        //     partsMessage = <Message negative size='big'
        //         icon='warning circle'
        //         content='There are too many results to narrow down. Please narrow it down more by keywords.'
        //     />;
        // } else if (this.state.partsModel.length == 0 && this.state.partsSearch.length > 0) {
        //     partsMessage = <Message negative size='big'
        //         icon='warning circle'
        //         content='Not found, please press the Search button.'
        //     />;
        // } else {
        //     result = [];
        // }

        // let partsResult = result.map(d => {
        //     return (
        //         <Grid.Column verticalAlign='middle ' textAlign='left' width={16}>{d.partsName} : {d.description}<Button floated='right' size='tiny' color='blue' name={d.partsName} onClick={this.onAddClick}>Add</Button></Grid.Column>
        //     )
        // })
        //#endregion

        // Calculate cost
        let shippingCost = '';
        if (orderEntryForm.ShipVia != '' && orderEntryForm.ShipVia != null) {
            shippingCost = this.props.Reducer.shipInfo.find(x => x.ShiptoNumber == orderEntryForm.ShipTo).ShipVias.find(x => x.Shpcde == orderEntryForm.ShipVia).Shpchg;
        }

        // display for shipping description
        let shipToInfo = this.props.Reducer.shipInfo.find(x => x.ShiptoNumber == orderEntryForm.ShipTo);
        let address = null;
        let shipViaInfo = null;
        if (shipToInfo) {
            address = shipToInfo.Address ? shipToInfo.Address : shipToInfo.City + ' ' + shipToInfo.State + ' ' + shipToInfo.Zipcode;
            shipViaInfo = shipToInfo.ShipVias ? shipToInfo.ShipVias.find(x => x.Shpcde == orderEntryForm.ShipVia) : null;
        };
        let shippingDescription = shipToInfo && shipViaInfo ? shipToInfo.ShiptoName + ' ( ' + address + ' ) ' + '/ ' + shipViaInfo.Shpvia + ' ( $' + shipViaInfo.Shpchg + ' )' : "";

        // error message
        let errorMessage = '';
        let confirmModalErrorMessage = '';
        let searchModalErrorMessage = '';
        let favoriteModalErrorMessage = '';

        if (this.state.confirmFlg && this.props.Reducer.orderEntryFormIsSavingError) {
            errorMessage =
                <Message negative size='big'
                    icon='warning circle'
                    content={this.props.Reducer.orderEntryErrorMessage.orderConfirm}
                />;
        } else if (this.state.openConfirmModal && this.state.getSubLoadingError) {
            confirmModalErrorMessage =
                <Message negative size='big'
                    icon='warning circle'
                    content={this.state.errorMessage}
                />;
        } else if (this.props.Reducer.openRunPartsSearchResultModal) {
            searchModalErrorMessage = this.props.Reducer.sessionDataError || this.props.Reducer.favoritePartsUpdatingError || this.props.Reducer.orderEntryAddPartLoadingError ?
                <Message negative size='big'
                    icon='warning circle'
                    content={this.props.Reducer.orderEntryErrorMessage.searchModal}
                /> : "";
        } else if (this.state.isFavoritePartsModalOpen) {
            favoriteModalErrorMessage = this.props.Reducer.sessionDataError || this.props.Reducer.favoritePartsUpdatingError || this.props.Reducer.orderEntryAddPartLoadingError ?
                <Message negative size='big'
                    icon='warning circle'
                    content={this.props.Reducer.orderEntryErrorMessage.favoriteModal}
                /> : "";
        } else if (Object.keys(this.state.detailErrors).length > 0) {
            errorMessage =
                <Message negative style={styleClear} size='big'
                    icon='warning circle'
                    header='Error occured. Please fix below.'
                    list={Object.keys(this.state.detailErrors).map((k, idx) => (<p key={idx}>{this.state.detailErrors[k]}</p>))}
                />;
        } else if (this.props.Reducer.loginIsLoadingError || this.props.Reducer.sessionDataError || this.props.Reducer.favoritePartsUpdatingError || this.props.Reducer.partsSearchingError || this.props.Reducer.orderEntryAddPartLoadingError) {
            errorMessage =
                <Message negative size='big'
                    icon='warning circle'
                    content={this.props.Reducer.orderEntryErrorMessage.orderEntry}
                />;
        }

        // message
        let addPartsMessage = this.state.openAddPartsMessage ?
            <Message info
                size='big'
                header={this.state.addProductName}
            /> : "";

        let addPartsMessageExceptModal = this.state.openAddPartsMessage && !this.props.Reducer.openRunPartsSearchResultModal && !this.state.isFavoritePartsModalOpen ?
            addPartsMessage : "";

        let warningForWarehouseChange = this.props.Reducer.loginData.SelectableWarehouse ?
            <p style={styleWarningMessage}>Warning: Changing the warehouse will clear your current cart.</p>
            : "";

        // Filter runPartsSearch result
        let filterdRunSearchData = this.props.Reducer.runSearchData.filter(
            x =>
                (
                    x.Status == "T" ? category.includes(x.Category) : true
                ) && (
                    (x.ProductNumber ? x.ProductNumber : "").toLowerCase().includes(this.state.searchPartsNumber.toLowerCase())
                ) && (
                    (x.Description ? x.Description : "").toLowerCase().includes(this.state.searchDescription.toLowerCase())
                )
        )

        let runPartsSearchResult = filterdRunSearchData.map((x, idx) => {
            let itemFrom = (this.props.Reducer.currentModalPage - 1) * this.props.Reducer.itemsInModalPerPage;
            let itemTo = (this.props.Reducer.currentModalPage) * this.props.Reducer.itemsInModalPerPage - 1;
            let iconName = this.getFavoriteIconName(x.ProductNumber);
            let iconColor = this.getFavoriteIconColor(x.ProductNumber);

            if (itemFrom <= idx && idx <= itemTo) {
                return (
                    <Table.Row key={idx}>
                        <Table.Cell>{x.ProductNumber}</Table.Cell>
                        <Table.Cell>{x.Description}</Table.Cell>
                        <Table.Cell>${x.ListPrice}</Table.Cell>
                        <Table.Cell>${x.Price}</Table.Cell>
                        <Table.Cell textAlign='center'><Button basic productnumber={x.ProductNumber ? x.ProductNumber : ''} description={x.Description} icon={iconName} onClick={this.onStarClick} color={iconColor} /></Table.Cell>
                        <Table.Cell textAlign='center'><Button size='tiny' color='blue' name={x.ProductNumber} onClick={this.onAddClick}>Add</Button></Table.Cell>
                    </Table.Row>
                )
            }
        })

        //Pagenation menu (for runPartsSearch result)
        //#region
        let menuItems = [];
        let activeItem = this.props.Reducer.currentModalPage;
        let pageAround = 2;       //How many pages around current page are shown
        let currentModalPage = this.props.Reducer.currentModalPage;
        let lastPage = Math.ceil(filterdRunSearchData.length / this.props.Reducer.itemsInModalPerPage);
        if (lastPage == 0) {
            lastPage = 1;
        }

        // Current page is out of range
        if (lastPage < this.props.Reducer.currentModalPage) {
            this.props.action.selectModalPage(lastPage);
        }

        let prevPage = currentModalPage == 1 ? 1 : currentModalPage - 1;
        let nextPage = currentModalPage == lastPage ? lastPage : currentModalPage + 1;
        let wasDot = false;       // last item was ...

        // Page number
        for (let i = 1; i <= lastPage; i++) {
            if (i == 1 || i == lastPage || i == currentModalPage || Math.abs(i - currentModalPage) <= pageAround) {
                menuItems.push(
                    <Menu.Item key={i} page={i} active={activeItem == i} onClick={this.onPageNumClick} >
                        {i}
                    </Menu.Item>
                );
                wasDot = false;
            } else if (!wasDot) {
                menuItems.push(
                    <Menu.Item >
                        {'...'}
                    </Menu.Item>
                );
                wasDot = true;
            }
        }

        let pageMenu = <Menu pagination style={stylePagemenu}>
            {menuItems}
        </Menu>;
        //#endregion

        // Modal
        //#region
        let confirmModal =
            <Modal style={styleWideModal} open={this.state.openConfirmModal}>
                <Dimmer active={this.state.getSubLoading}>
                    <Loader inline='centered' size='big'>Please wait ...</Loader>
                </Dimmer>
                <Modal.Header>
                    <Header as='h2'>The parts have been replaced as follows</Header>
                </Modal.Header>
                <Modal.Content scrolling>
                    {confirmModalErrorMessage}
                    {partsTable.reverse()}
                </Modal.Content>
                <Modal.Actions>
                    <Button size='huge' color='red' onClick={this.onCloseConfirmModal} disabled={this.state.getSubLoading}>
                        <Icon name='remove' /> Cancel
                    </Button>
                    <Button size='huge' color='green' icon labelPosition='right' onClick={this.onNextClick} disabled={this.state.getSubLoading || this.state.getSubLoadingError}>
                        <Icon name='angle double right' /> Next
                    </Button>
                </Modal.Actions>
            </Modal>;

        let completeModal =
            <Modal basic size='small' open={this.props.Reducer.orderEntryCompleteModal} style={styleModal}>
                <Header icon>
                    <Icon name='paper plane' />
                    <h2>Order Success!</h2>
                </Header>
                <Modal.Content>
                    We accepted your order.
                    {this.props.Reducer.acceptOrder}
                </Modal.Content>
                <Modal.Actions>
                    <Button size='huge' basic inverted onClick={this.onCloseCompleteModal} name={Constant.STAT_ORDERLIST}>
                        <Icon name='home' />Go To Order List
                    </Button>
                </Modal.Actions>
            </Modal>;

        let runPartsSearchResultModal =
            <Modal style={styleWideModal} open={this.props.Reducer.openRunPartsSearchResultModal}>
                <Modal.Header>
                    <Header as='h2'>Parts List</Header>
                </Modal.Header>
                <Modal.Content>
                    {searchModalErrorMessage}
                    {addPartsMessage}
                    <Form>
                        <Form.Group inline>
                            <Button circular size='mini' icon='arrow left' page={prevPage} onClick={this.onPageNumClick} />
                            <Button circular size='mini' icon='arrow right' page={nextPage} onClick={this.onPageNumClick} />

                            {pageMenu}

                            <Form.Field>
                                <Input
                                    placeholder='Search Parts #'
                                    style={styleSearchunderBox}
                                    value={this.state.searchPartsNumber}
                                    onChange={this.onPartsNumberSearchChange}
                                    icon={<Icon name='delete' link
                                        onClick={this.onSearchClearClickPartsNumber} />}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Input
                                    placeholder='Search Description'
                                    style={styleSearchunderBox}
                                    value={this.state.searchDescription}
                                    onChange={this.onDescriptionSearchChange}
                                    icon={<Icon name='delete' link
                                        onClick={this.onSearchClearClickDescription} />}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form>

                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell><Header as='h3'>Part#</Header></Table.HeaderCell>
                                <Table.HeaderCell><Header as='h3'>Product Description</Header></Table.HeaderCell>
                                <Table.HeaderCell><Header as='h3'>List Price</Header></Table.HeaderCell>
                                <Table.HeaderCell><Header as='h3'>Price</Header></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {runPartsSearchResult}
                        </Table.Body>
                    </Table>
                </Modal.Content>
                <Modal.Actions>
                    <Button size='huge' color='red' onClick={this.onCloseRunPartsSearchResultModal}>
                        <Icon name='remove' /> Close
                    </Button>
                </Modal.Actions>
            </Modal>;
        //#endregion

        let confirmOrderTotal = 0;
        let confirmOrderTable = orderEntryForm.Parts ? orderEntryForm.Parts.map((orderPart, idx) => {
            let subTotal = Functions.decimalMultiplication(orderPart.Price, orderPart.OrderQuantity);
            confirmOrderTotal = Functions.decimalAddition(confirmOrderTotal, subTotal);

            return (
                <Table.Body key={idx}>
                    <Table.Row>
                        <Table.Cell >{orderPart.Description}<br />Part#: <b>{orderPart.ProductNumber}</b></Table.Cell>
                        <Table.Cell >{Functions.dollarForm(Functions.toNumberToFixed(orderPart.List))}</Table.Cell>
                        <Table.Cell >{Functions.dollarForm(Functions.toNumberToFixed(orderPart.Price))}</Table.Cell>
                        <Table.Cell >{orderPart.OrderQuantity}</Table.Cell>
                        <Table.Cell >{Functions.dollarForm(Functions.toNumberToFixed(subTotal))}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            );
        }) : '';

        let orderTotal = 0;
        orderTotal = Functions.decimalAddition(confirmOrderTotal, shippingCost);

        let confirmBackTotal = 0;
        let confirmBackTable = backOrderList ? backOrderList.map((orderPart, idx) => {
            let subTotal = Functions.decimalMultiplication(orderPart.Price, orderPart.BoQty);
            confirmBackTotal = Functions.decimalAddition(confirmBackTotal, subTotal);
            return (
                <Table.Body key={idx}>
                    <Table.Row>
                        <Table.Cell >{orderPart.Description}<br />Part#: <b>{orderPart.ProductNumber}</b></Table.Cell>
                        <Table.Cell >{Functions.dollarForm(Functions.toNumberToFixed(orderPart.List))}</Table.Cell>
                        <Table.Cell >{Functions.dollarForm(Functions.toNumberToFixed(orderPart.Price))}</Table.Cell>
                        <Table.Cell >{orderPart.BoQty}</Table.Cell>
                        <Table.Cell >{Functions.dollarForm(Functions.toNumberToFixed(subTotal))}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            );
        }) : '';

        // Favorite function
        //#region
        // Filter favoriteParts result
        let filterdFavoriteParts = this.state.favoriteParts.filter(
            x => (
                (x.ProductNumber ? x.ProductNumber : "").toLowerCase().includes(this.state.favoritePartsNumber.toLowerCase())
            ) && (
                    (x.Description ? x.Description : "").toLowerCase().includes(this.state.favoritePartsDescription.toLowerCase())
                )
        )

        let FPItemFrom = (this.state.currentFavoritePartsModalPage - 1) * this.props.Reducer.itemsInModalPerPage;
        let FPItemTo = this.state.currentFavoritePartsModalPage * this.props.Reducer.itemsInModalPerPage - 1;

        let favoritePartsBody = filterdFavoriteParts.map((x, idx) => {
            if (FPItemFrom <= idx && idx <= FPItemTo) {
                let iconName = this.getFavoriteIconName(x.ProductNumber);
                let iconColor = this.getFavoriteIconColor(x.ProductNumber);

                return (
                    <Table.Row>
                        <Table.Cell>{x.ProductNumber}</Table.Cell>
                        <Table.Cell>{x.Description}</Table.Cell>
                        <Table.Cell textAlign='center'><Button basic productnumber={x.ProductNumber ? x.ProductNumber : ''} description={x.Description} icon={iconName} onClick={this.onStarClick} color={iconColor} /></Table.Cell>
                        <Table.Cell textAlign='center'><Button size='tiny' color='blue' name={x.ProductNumber} onClick={this.onAddClick}>Add</Button></Table.Cell>
                    </Table.Row>
                );
            }
        });

        //Pagenation menu (Favorite Parts)
        //#region
        let FPMenuItems = [];
        let FPCurrentModalPage = this.state.currentFavoritePartsModalPage;
        let FPLastPage = Math.ceil(filterdFavoriteParts.length / this.props.Reducer.itemsInModalPerPage);
        if (FPLastPage == 0) { FPLastPage = 1; }

        let FPPrevPage = FPCurrentModalPage == 1 ? 1 : FPCurrentModalPage - 1;
        let FPNextPage = FPCurrentModalPage == FPLastPage ? FPLastPage : FPCurrentModalPage + 1;
        let FPWasDot = false;       // last item was ...

        // Page number
        for (let i = 1; i <= FPLastPage; i++) {
            if (i == 1 || i == FPLastPage || i == FPCurrentModalPage || Math.abs(i - FPCurrentModalPage) <= pageAround) {
                FPMenuItems.push(
                    <Menu.Item key={i} page={i} active={FPCurrentModalPage == i} onClick={this.onFavoritePartsPageNumClick} >
                        {i}
                    </Menu.Item>
                );
                FPWasDot = false;
            } else if (!FPWasDot) {
                FPMenuItems.push(
                    <Menu.Item >
                        {'...'}
                    </Menu.Item>
                );
                FPWasDot = true;
            }
        }

        let favoritePartsPageMenu = <Menu pagination style={stylePagemenu}>
            {FPMenuItems}
        </Menu>;
        //#endregion

        // Favorite Parts Modal
        //#region
        let favoritePartsModalContent = this.state.favoriteParts.length != 0 ?
            <Modal.Content>
                <Dimmer active={this.props.Reducer.favoritePartsUpdating}>
                    <Loader inline='centered' size='big'>Please wait ...</Loader>
                </Dimmer>
                {favoriteModalErrorMessage}
                {addPartsMessage}
                <Form>
                    <Form.Group inline>
                        <Button circular size='mini' icon='arrow left' page={FPPrevPage} onClick={this.onFavoritePartsPageNumClick} />
                        <Button circular size='mini' icon='arrow right' page={FPNextPage} onClick={this.onFavoritePartsPageNumClick} />

                        {favoritePartsPageMenu}

                        <Form.Field>
                            <Input
                                placeholder='Search Parts #'
                                style={styleSearchunderBox}
                                value={this.state.favoritePartsNumber}
                                name="favoritePartsNumber"
                                onChange={this.onFavoritePartsFormChange}
                                icon={<Icon name='delete' link
                                    onClick={this.onFavoritePartsFormClearPartsNumber} />}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                placeholder='Search Description'
                                style={styleSearchunderBox}
                                value={this.state.favoritePartsDescription}
                                name="favoritePartsDescription"
                                onChange={this.onFavoritePartsFormChange}
                                icon={<Icon name='delete' link
                                    onClick={this.onFavoritePartsFormClearDescription} />}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>

                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell><Header as='h3'>Part#</Header></Table.HeaderCell>
                            <Table.HeaderCell><Header as='h3'>Product Description</Header></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {favoritePartsBody}
                    </Table.Body>
                </Table>
            </Modal.Content>
            :
            <Modal.Content>
                <Header as='h1' icon textAlign='center'>
                    <Icon name="inbox" />
                    No Favorite Parts Yet...
                    <Header.Subheader>
                        Click on the stars of the parts to view them here.
                    </Header.Subheader>
                </Header>
            </Modal.Content>;

        let favoritePartsModal =
            <Modal style={styleWideModal} open={this.state.isFavoritePartsModalOpen}>
                <Modal.Header>
                    <Header as='h2'>Favorite Parts</Header>
                </Modal.Header>
                {favoritePartsModalContent}
                <Modal.Actions>
                    <Button size='huge' color='red' onClick={this.onCloseFavoriteModal}>
                        <Icon name='remove' /> Close
                    </Button>
                </Modal.Actions>
            </Modal>;
        //#endregion
        //#endregion

        // return contents
        if (!this.state.confirmFlg) {
            return (
                <div>
                    {this.props.Reducer.customerNumList.length != 1 ?
                        <h3>
                            <Dropdown
                                search selection placeholder='Select Customer#'
                                options={customerOption}
                                value={this.props.Reducer.selectedCustomerNum}
                                onChange={this.onCustomerNumChange}
                            />
                        </h3>
                        : ""}

                    <Header color='grey' as='h1' dividing>
                        Order Entry
                    </Header>

                    <Dimmer active={this.props.Reducer.partsSearching}>
                        <Loader inline='centered' size='big'>Searching ...</Loader>
                    </Dimmer>
                    <Dimmer active={this.props.Reducer.sessionDataLoading || this.props.Reducer.partsMasterLoading}>
                        <Loader inline='centered' size='big'>Parts data loading, please wait a minute.</Loader>
                    </Dimmer>
                    <Dimmer active={this.state.loading}>
                        <Loader inline='centered' size='big'>Please wait ...</Loader>
                    </Dimmer>
                    <Dimmer active={!this.props.Reducer.sessionDataLoading && (this.props.Reducer.loginIsLoading || this.props.Reducer.ordersLoading)}>
                        <Loader inline='centered' size='big'>Loading ...</Loader>
                    </Dimmer>

                    {errorMessage}

                    <Dimmer.Dimmable fluid dimmed style={styleSegment}>
                        <Dimmer active={this.props.Reducer.selectedCustomerNum == ''} >
                            <Header as='h2' inverted>
                                Please Select Customer# in Advance.
                            </Header>
                        </Dimmer>
                        <Segment basic style={styleNoPadding} >
                            <Label content="Warehouse" attached='top left' basic style={styleLabelBorder} size="large" />
                            {warehouse}
                            {warningForWarehouseChange}
                        </Segment>

                        <div style={styleflex}>
                            <Segment size="huge" compact basic style={styleNoPadding} >
                                <Label content="Enter Part Number" attached='top left' size="large" basic style={styleLabelBorder} />
                                <Input onChange={this.onEnterPartNumberChange} style={styleInput} ref={this.setEntryPartNumberRef} value={this.state.enterPartNumber} disabled={!selectCustomerNumFlag} />
                                <Button style={sytleMargin} primary size='huge' content='Add to Cart' name={this.state.enterPartNumber} onClick={this.onEnterPartAddClick} disabled={this.state.enterPartNumber == '' || !selectCustomerNumFlag} />
                            </Segment>
                            <Segment size="huge" compact basic style={styleFormPadding} >
                                <Label content="Enter Keyword" attached='top left' size="large" basic style={styleLabelBorder} />
                                <Input name='searchParts' onChange={this.onSearchPartsChange} style={styleInput} value={this.state.partsSearch} disabled={!selectCustomerNumFlag}><input tabindex="-1" /></Input>
                                <Button style={styleSearchButton} primary size='huge' content='Search' onClick={this.onRunPartsSearchClick} disabled={selectWarehouseFlag || this.state.partsSearch == null || this.state.partsSearch == '' || this.state.partsSearch.length <= 2 || !selectCustomerNumFlag} />
                                <Button style={sytleMargin} size='huge' color="yellow" content='Favorite' onClick={this.onFavoriteClick} disabled={!selectCustomerNumFlag} tabindex="-1" />

                                {/* <Popup open={this.state.isOpen}
                                    onOpen={this.onOpenPopup} className='popupWide' wide="very"
                                    trigger={<Input icon='search' style={styleSearchInput} iconPosition='left' onChange={this.onChangePartsOption} placeholder='Search parts (Enter the keyword...)' disabled={selectWarehouseFlag} />}
                                    on='focus' pinned position='bottom left' >
                                    <Grid divided className='gridHeight'>
                                        {partsResult}
                                        {partsMessage}
                                    </Grid>
                                </Popup> */}
                            </Segment>
                        </div>

                        {addPartsMessageExceptModal}

                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell><Header as='h3'>Part#</Header></Table.HeaderCell>
                                    <Table.HeaderCell><Header as='h3'>Product Description</Header></Table.HeaderCell>
                                    <Table.HeaderCell><Header as='h3'>List Price</Header></Table.HeaderCell>
                                    <Table.HeaderCell><Header as='h3'>Price</Header></Table.HeaderCell>
                                    <Table.HeaderCell><Header as='h3'>Qty</Header></Table.HeaderCell>
                                    <Table.HeaderCell><Header as='h3'>Subtotal</Header></Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {orderEntryInputTableItems}
                            </Table.Body>
                        </Table>

                        <div className='flex-floatRight'>
                            <Table definition size='large' color='black' style={styleTotalTable}>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell><Header>Total</Header></Table.Cell>
                                        <Table.Cell><Input label='$' disabled name='Total' value={total} style={styleInputWide} /></Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>

                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={4}><Header as='h3'>P.O.#</Header></Table.HeaderCell>
                                    <Table.HeaderCell width={7}><Header as='h3'>Ship To</Header></Table.HeaderCell>
                                    <Table.HeaderCell width={5}><Header as='h3'>Ship Via</Header></Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><Input fluid name='PurchaseOrderNumber' search='true' selection='true' onChange={this.onValueChange} value={orderEntryForm.PurchaseOrderNumber} error={!!this.state.detailErrors.PurchaseOrderNumber} disabled={!selectCustomerNumFlag} /></Table.Cell>
                                    <Table.Cell><Dropdown fluid search upward name='ShipTo' onChange={this.onValueChange} selection options={dropShipToOption} value={orderEntryForm.ShipTo} error={!!this.state.detailErrors.ShipTo} disabled={!selectCustomerNumFlag}></Dropdown></Table.Cell>
                                    <Table.Cell><Dropdown fluid search upward name='ShipVia' onChange={this.onValueChange} selection options={dropShipViaOption} value={orderEntryForm.ShipVia} error={!!this.state.detailErrors.ShipVia} disabled={!selectShipToFlag || !selectCustomerNumFlag}></Dropdown></Table.Cell>
                                </Table.Row>
                            </Table.Header>
                        </Table>

                        <Segment clearing vertical hidden={!isBulkOrderFlag}>
                            <Header floated='left'>
                                <Icon name='file' />
                                <Header.Content>
                                    Upload File to bulk order
                                </Header.Content>
                            </Header>
                            <input floated='left' type={'file'} id={'js-image-file'} name={'imageFile'} style={{ visibility: 'hidden', height: 0, width: 0 }} onChange={this.handleChangeFile} />
                            <Button label="Add File" style={{ fontSize: 'large' }} primary size='huge' labelPosition='right' onClick={this.onAddFileClick} disabled={selectWarehouseFlag || !selectCustomerNumFlag} icon='add' />
                        </Segment>

                        <Button color='green' size='huge' icon='cart' labelPosition='right' content='Confirm Order' floated='right' onClick={this.onOpenConfirmModal} disabled={!selectCustomerNumFlag} />
                        {confirmModal}{runPartsSearchResultModal}{favoritePartsModal}

                    </Dimmer.Dimmable>
                </div>
            );
        } else {
            return (
                <div>
                    <Dimmer active={this.props.Reducer.orderEntryFormIsSaving}>
                        <Loader inline='centered' size='big'>Saving ...</Loader>
                    </Dimmer>
                    <Header color='grey' as='h1' dividing>
                        Order Confirm
                    </Header>

                    <Message warning
                        icon='warning circle'
                        header='Order not submitted.'
                        content='Press the Purchase Now button below to submit the order.'
                    />
                    {errorMessage}

                    <Table definition size='large'>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={4}>P.O. #</Table.Cell>
                                <Table.Cell width={12} >{orderEntryForm.PurchaseOrderNumber}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Shipping</Table.Cell>
                                <Table.Cell>{shippingDescription}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>

                    <Header as='h2'>Order List</Header>
                    <Table size='large'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={5}><Header as='h3'>Product Description</Header></Table.HeaderCell>
                                <Table.HeaderCell width={3}><Header as='h3'>List Price</Header></Table.HeaderCell>
                                <Table.HeaderCell width={3}><Header as='h3'>Price</Header></Table.HeaderCell>
                                <Table.HeaderCell width={2}><Header as='h3'>Qty</Header></Table.HeaderCell>
                                <Table.HeaderCell width={3}><Header as='h3'>Subtotal</Header></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {confirmOrderTable.reverse()}
                    </Table>

                    <Header as='h2'>Back Order List</Header>
                    <Table size='large'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={5}><Header as='h3'>Product Description</Header></Table.HeaderCell>
                                <Table.HeaderCell width={3}><Header as='h3'>List Price</Header></Table.HeaderCell>
                                <Table.HeaderCell width={3}><Header as='h3'>Price</Header></Table.HeaderCell>
                                <Table.HeaderCell width={2}><Header as='h3'>Back Qty</Header></Table.HeaderCell>
                                <Table.HeaderCell width={3}><Header as='h3'>Subtotal</Header></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {confirmBackTable.reverse()}
                    </Table>

                    <div className='flex-floatRight'>
                        <Table color='black' collapsing size='large'>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell><Header as='h3'>Subtotal</Header></Table.Cell>
                                    <Table.Cell style={styleTableWide} textAlign='right'><Header as='h3'>{Functions.dollarForm(Functions.toNumberToFixed(confirmOrderTotal))}</Header></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><Header as='h3'>Shipping</Header></Table.Cell>
                                    <Table.Cell style={styleTableWide} textAlign='right'><Header as='h3'>{Functions.dollarForm(Functions.toNumberToFixed(shippingCost))}</Header></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><Header as='h2'>Order Total</Header></Table.Cell>
                                    <Table.Cell style={styleTableWide} textAlign='right'><Header as='h2'>{Functions.dollarForm(Functions.toNumberToFixed(orderTotal))}</Header></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><Header as='h3'>Order Total (include back order)</Header></Table.Cell>
                                    <Table.Cell style={styleTableWide} textAlign='right'><Header as='h3'>{Functions.dollarForm(Functions.toNumberToFixed(Functions.decimalAddition(confirmBackTotal, orderTotal)))}</Header></Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>
                    <div className='flex-floatRight'>
                        <Button color='green' size='huge' icon='check' labelPosition='left' content='Purchase Now' floated='right' style={styleButton} onClick={this.onPurchaseNowClick} disabled={this.props.Reducer.orderEntryFormIsSaving} />
                        <Button color='red' size='huge' icon='angle left' labelPosition='left' content='Back' floated='right' style={styleButton} onClick={this.onBackClick} disabled={this.props.Reducer.orderEntryFormIsSaving} />
                    </div>
                    {completeModal}
                </div>
            )
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderEntry);
