// tabStatus
export const STAT_ORDERENTRY = 'STAT_ORDERENTRY';
export const STAT_ORDERLIST = 'STAT_ORDERLIST';
export const STAT_INVOICE = 'STAT_INVOICE';
export const STAT_CONTACTUS = 'STAT_CONTACTUS';
export const STAT_FILEDOWNLOAD = 'STAT_FILEDOWNLOAD';
export const STAT_ORDERDETAIL_VIEW = 'STAT_ORDERDETAIL_VIEW';

/*  Action  */
// Login
export const ACT_RESETPASSWORDPAGE = 'ACT_RESETPASSWORDPAGE';
export const ACT_LOGINRESULT = 'ACT_LOGINRESULT';
export const ACT_LOGINISLOADING = 'ACT_LOGINISLOADING';
export const ACT_LOGINISLOADING_ERROR = 'ACT_LOGINISLOADING_ERROR';
export const ACT_RESETPARAMISLOADING = 'ACT_RESETPARAMISLOADING';
export const ACT_RESETPARAMISLOADING_ERROR = 'ACT_RESETPARAMISLOADING_ERROR';
export const ACT_OPENRESETMODAL = 'ACT_OPENRESETMODAL';
export const ACT_RESETPASSISLOADING = 'ACT_RESETPASSISLOADING';
export const ACT_RESETPASSISLOADING_ERROR = 'ACT_RESETPASSISLOADING_ERROR';
export const ACT_OPENRESETPASSMODAL = 'ACT_OPENRESETPASSMODAL';
export const ACT_SETCUSTOMERNUMBERLIST = 'ACT_SETCUSTOMERNUMBERLIST';
export const ACT_LOGINRESULTBYCUSTOMERNUM = 'ACT_LOGINRESULTBYCUSTOMERNUM';
export const ACT_SETCUSTOMERNUMBER = 'ACT_SETCUSTOMERNUMBER';
export const ACT_ISTERMSCONDITIONSMODAL = 'ACT_ISTERMSCONDITIONSMODAL';
export const ACT_SETRELOADFLAG = 'ACT_SETRELOADFLAG';
export const ACT_SETISOPENSYSTEMRETIREMODAL = 'ACT_SETISOPENSYSTEMRETIREMODAL';

// List
export const ACT_CHANGESORTBY = 'ACT_CHANGESORTBY';
export const ACT_CHANGEITEMSPERPAGE = 'ACT_CHANGEITEMSPERPAGE';
export const ACT_SELECTPAGE = 'ACT_SELECTPAGE';
export const ACT_MOVE_INVOICE = 'ACT_MOVE_INVOICE';
export const ACT_BACKTOLIST = 'ACT_BACKTOLIST';
export const ACT_THEOLDESTDATEFROMCHANGE = 'ACT_THEOLDESTDATEFROMCHANGE';

// Order Entry
export const ACT_ORDERENTRY_SAVING = 'ACT_ORDERENTRY_SAVING';
export const ACT_ORDERENTRY_SAVING_SUCCESS = 'ACT_ORDERENTRY_SAVING_SUCCESS';
export const ACT_ORDERENTRY_CLOSE_COMPLETEMODAL = 'ACT_ORDERENTRY_CLOSE_COMPLETEMODAL';
export const ACT_ORDERENTRY_SAVING_ERROR = 'ACT_ORDERENTRY_SAVING_ERROR';
export const ACT_ORDERENTRY_CART = 'ACT_ORDERENTRY_CART';
export const ACT_ORDERENTRY_CART_ERROR = 'ACT_ORDERENTRY_CART_ERROR';
export const ACT_ORDERENTRY_SELECT_MODAL_PAGE = 'ACT_ORDERENTRY_SELECT_MODAL_PAGE';
export const ACT_SESSIONDATALOADING = 'ACT_SESSIONDATALOADING';
export const ACT_ORDERENTRY_ADDPART_ERROR = 'ACT_ORDERENTRY_ADDPART_ERROR';
// export const ACT_ORDERENTRY_PARTSMASTER = 'ACT_ORDERENTRY_PARTSMASTER';
// export const ACT_ORDERENTRY_PARTSMASTER_LOADING = 'ACT_ORDERENTRY_PARTSMASTER_LOADING';
// export const ACT_ORDERENTRY_PARTSMASTER_LOADING_ERROR = 'ACT_ORDERENTRY_PARTSMASTER_LOADING_ERROR';

// Common
export const ACT_MENUCHANGE = 'ACT_MENUCHANGE';

//Contact Us
export const ACT_CONTACTUS_MAILSENDING = 'ACT_CONTACTUS_MAILSENDING';
export const ACT_CONTACTUS_MAILSENDING_SUCCESS = 'ACT_CONTACTUS_MAILSENDING_SUCCESS';
export const ACT_CONTACTUS_MAILSENDING_ERROR = 'ACT_CONTACTUS_MAILSENDING_ERROR';
export const ACT_CONTACTUS_CLOSE_SENDINGMODAL = 'ACT_CONTACTUS_CLOSE_SENDINGMODAL';

// Jbase
export const ACT_JBASE_PARTSORDERSBYCUSTOMER = 'ACT_JBASE_PARTSORDERSBYCUSTOMER';
export const ACT_JBASE_PARTSClOSEORDERS = 'ACT_JBASE_PARTSClOSEORDERS';
export const ACT_JBASE_PARTSORDERSBYCUSTOMER_LOADING = 'ACT_JBASE_PARTSORDERSBYCUSTOMER_LOADING';
export const ACT_JBASE_PARTSORDERSBYCUSTOMER_LOADING_ERROR = 'ACT_JBASE_PARTSORDERSBYCUSTOMER_LOADING_ERROR';
export const ACT_JBASE_RUNPARTSSEARCH = 'ACT_JBASE_RUNPARTSSEARCH';
export const ACT_JBASE_PARTSSEARCHING = 'ACT_JBASE_PARTSSEARCHING';
export const ACT_JBASE_PARTSSEARCHING_ERROR = 'ACT_JBASE_PARTSSEARCHING_ERROR';
export const ACT_JBASE_RUNPARTSSEARCH_CLOSE_MODAL = 'ACT_JBASE_RUNPARTSSEARCH_CLOSE_MODAL';
export const ACT_JBASE_DELETEPARTSORDERS = 'ACT_JBASE_DELETEPARTSORDERS';

// Favorite Parts
export const ACT_FAVORITEPARTS = 'ACT_FAVORITEPARTS';
export const ACT_FAVORITEPARTS_LOADING = 'ACT_FAVORITEPARTS_LOADING';
export const ACT_FAVORITEPARTS_LOADING_ERROR = 'ACT_FAVORITEPARTS_LOADING_ERROR';
