"use strict";
export default class {
    constructor(dataObject = null) {
        this.ShiptoNumber = '';
        this.ShiptoName = '';
        this.Address = '';
        this.City = '';
        this.State = '';
        this.Zipcode = '';
        this.ShipDefault = '';

        this.ShipVias = [];

        //Import data if set
        if (dataObject) {
            try {
                this.ShiptoNumber = dataObject.shiptoNumber;
                this.ShiptoName = dataObject.shiptoName;
                this.Address = dataObject.address;
                this.City = dataObject.city;
                this.State = dataObject.state;
                this.Zipcode = dataObject.zipcode;
                this.ShipDefault = dataObject.shipDefault;
            }
            catch (e) {
                throw "Data import error @ ShiptoInfo data.";
            }
        }
    }

    getDataObject() {
        return {
            ShiptoNumber: this.ShiptoNumber,
            ShiptoName: this.ShiptoName,
            Address: this.Address,
            City: this.City,
            State: this.State,
            Zipcode: this.Zipcode,
            ShipVias: this.ShipVias,
            ShipDefault: this.ShipDefault,
        }
    }
}