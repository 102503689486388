import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { unregister } from './registerServiceWorker';
import 'semantic-ui-css/semantic.min.css';
import 'babel-polyfill';
import 'url-polyfill';
import "isomorphic-fetch";
import 'react-app-polyfill/ie11';

ReactDOM.render(<App />, document.getElementById('root'));
unregister();
