"use strict";
export default class {
    constructor(dataObject = null) {
        this.Id = null;
        this.CustomerNumber = '';
        this.ProductNumber = '';
        this.ShippedQty = null;
        this.BackOrderQty = null;
        this.Cost = 0;
        this.PONumber = '';
        this.OrderNumber = '';
        this.OrderStatus = '';
        this.OrdDate = '';
        this.ShipCharge = 0;
        this.InvoiceNumber = '';
        this.ETA = '';
        this.InvDate = '';

        this.Details = [];

        //Import data if set
        if (dataObject) {
            try {
                this.Id = dataObject.id;
                this.CustomerNumber = dataObject.customerNumber;
                this.ProductNumber = dataObject.productNumber;
                this.ShippedQty = dataObject.shippedQty;
                this.BackOrderQty = dataObject.backOrderQty;
                this.Cost = dataObject.cost;
                this.PONumber = dataObject.pONumber;
                this.OrderNumber = dataObject.orderNumber;
                this.OrderStatus = dataObject.orderStatus;
                this.OrdDate = dataObject.ordDate;
                this.ShipCharge = dataObject.shipCharge;
                this.InvoiceNumber = dataObject.invoiceNumber;
                this.ETA = dataObject.eta;
                this.InvDate = dataObject.invDate;
            }
            catch (e) {
                throw "Data import error @ OrderFromJbaseCloseHeader data.";
            }
        }
    }

    getDataObject() {
        return {
            Id: this.Id,
            CustomerNumber: this.CustomerNumber,
            ProductNumber: this.ProductNumber,
            ShippedQty: this.ShippedQty,
            BackOrderQty: this.BackOrderQty,
            Cost: this.Cost,
            PONumber: this.PONumber,
            OrderNumber: this.OrderNumber,
            OrderStatus: this.OrderStatus,
            OrdDate: this.OrdDate,
            ShipCharge: this.ShipCharge,
            InvoiceNumber: this.InvoiceNumber,
            ETA: this.ETA,
            InvDate: this.InvDate,
        }
    }
}