import React from 'react';
import { connect } from 'react-redux';

import { Input, Container, Header, Table, Loader, Icon, Button, Segment, Menu, Image, Form, Grid, Dimmer, Message, Modal, Popup, List } from 'semantic-ui-react';

import { mapStateToProps, mapDispatchToProps } from '../../Root.js';
import 'react-datepicker/dist/react-datepicker.css';

import Root from '../../Root';
import logo from '../../logo.jpg';
import AppConfig from '../../AppConfig';

// Passwordのバリデーション用
// 入力可能文字：a-zA-Z0-9
// 入力可能記号：!#$%&'"()=-~^|@`[]{}:;/?_<>,.*+
const prohibitedCharacters = '!#$%&\'"()=\-~^|@`[\]{}:;\/?_<>,.\*\+';
// 入力不可な文字を洗い出すための正規表現
const prohibitedCharactersRegExp = /[^a-zA-Z0-9!#$%&\'"()=\-~^|@`[\]{}:;\/?_<>,.\*\+]/g;

export class PasswordRegistration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: { newPassword: "", confirmPassword: "" },
            errors: {},
        };
        this.onBackClick = this.onBackClick.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
    }

    onBackClick() {
        this.setState({ userInfo: { newPassword: "", confirmPassword: "" }, errors: {} });
        this.props.action.resetPassLoadingError(false);
        let url = window.location.href;
        let newUrl = url.substring(0, url.indexOf("/?"));
        window.history.pushState("", "", newUrl);
        window.location.reload();
    }

    onSubmitClick() {
        this.setState({ errors: {}, });
        this.props.action.resetPassLoadingError(false);
        let userInfo = Object.assign(Object.create(this.state.userInfo), this.state.userInfo);
        let errors = {};
        // 入力禁止文字を洗い出す
        let prohibitedValues = userInfo.newPassword.match(prohibitedCharactersRegExp) ?? [];
        if (userInfo.newPassword == "") {
            errors.newPassword = "Please enter New Password.";
        }
        else if (userInfo.newPassword.length < 8 || 20 < userInfo.newPassword.length) {
            errors.newPassword = "Password must be between 8 and 20 characters.";
        }
        else if (prohibitedValues.length != 0) {
            // 入力禁止文字を使用している
            let badValues = Array.from(new Set(prohibitedValues)).map(x => `"${x}"`);
            errors.newPassword = `The following special characters are not allowed (${badValues.join(', ')}).`;
        }
        if (userInfo.confirmPassword == "") {
            errors.confirmPassword = "Please enter Confirm New Password.";
        }
        else if (userInfo.newPassword != userInfo.confirmPassword) {
            errors.confirmPassword = "Please enter Same Password.";
        }

        if (Object.keys(errors).length > 0) {
            this.setState({ errors: errors });
        } else {
            this.props.action.resetPassword(AppConfig.ApiUrlPartsLogin, userInfo.newPassword, this.props.Reducer.reset);
        }
    }

    onCloseModal() {
        this.props.action.openResetPassModel(false);
        this.onBackClick();
    }

    onTextChange(evn, data) {
        let userInfo = Object.assign(Object.create(this.state.userInfo), this.state.userInfo);
        const { name, value } = data;
        userInfo[name] = value;

        this.setState({ userInfo });
    }

    render() {
        let styleButton = { marginTop: 20 };
        let styleRequired = { color: "red", marginLeft: 10, fontWeight: "normal" };
        let styleModal = { marginTop: 0, margin: 'auto' };
        let styleButtonLink = { background: 'none', color: 'blue' };

        let loadingFlag = this.props.Reducer.resetPassIsLoading;
        let errorMessage = this.props.Reducer.resetPassIsLoadingError ?
            <Message negative size='big'
                icon='warning circle'
                header='Error Occured.'
                content={this.props.Reducer.loginErrorMessage.passwordRegistration}
            />
            : Object.keys(this.state.errors).length > 0 ?
                <Message negative size='big'
                    icon='warning circle'
                    header='Error Occured.'
                    content={Object.keys(this.state.errors).map(k => (<p>{this.state.errors[k]}</p>))}
                />
                : "";

        let resetForm =
            <Segment textAlign='left'>
                {errorMessage}
                <Form size='big'>
                    <Popup position='left center' on='focus'
                        trigger={
                            <Form.Field>
                                <label>New Password <span style={styleRequired} >* required</span></label>
                                <Form.Input fluid icon='lock' iconPosition='left' name='newPassword' placeholder='New Password' type='password' value={this.state.userInfo.newPassword} onChange={this.onTextChange} error={this.state.errors.newPassword} />
                            </Form.Field>
                        }
                    >
                        <Popup.Header content="Password requirements" />
                        <Popup.Content>
                            <List bulleted>
                                <List.Item content="Must be between 8 and 20 characters." />
                                <List.Item content={`May include the following characters: ${prohibitedCharacters}`} />
                                <List.Item content="Cannot have any spaces." />
                            </List>
                        </Popup.Content>
                    </Popup>

                    <Form.Field>
                        <label>Confirm New Password <span style={styleRequired} >* required</span></label>
                        <Form.Input fluid icon='lock' iconPosition='left' name='confirmPassword' placeholder='Confirm New Password' type='password' value={this.state.userInfo.confirmPassword} onChange={this.onTextChange} error={this.state.errors.confirmPassword} />
                    </Form.Field>


                    <Button color='blue' fluid size='huge' style={styleButton} onClick={this.onSubmitClick} disabled={loadingFlag}>
                        Submit
                    </Button>
                    <a className='cursor-pointer' style={styleButtonLink} onClick={this.onBackClick}>Back to login</a>
                </Form>
            </Segment>;

        return (
            <div>
                <Grid textAlign='center'>
                    <Grid.Column style={{ maxWidth: 650 }}>
                        {resetForm}
                    </Grid.Column>
                </Grid>

                <Modal basic size='small' open={this.props.Reducer.resetPassModal} style={styleModal}>
                    <Header icon>
                        <Icon name='check' />
                        <h2>Complete!</h2>
                    </Header>
                    <Modal.Content>New password has been set.</Modal.Content>
                    <Modal.Actions>
                        <Button size='huge' basic inverted onClick={this.onCloseModal}>
                            <Icon name='check' />OK
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>

        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PasswordRegistration);