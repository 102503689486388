import React from 'react';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver'

import { Container, Header, Loader, Icon, Button, Segment, Image, Form, Grid, Dimmer, Message, Divider } from 'semantic-ui-react';

import { mapStateToProps, mapDispatchToProps } from '../../Root.js';
import 'react-datepicker/dist/react-datepicker.css';

import Root from '../../Root';
import ResetPassword from './ResetPassword';
import PasswordRegistration from './PasswordRegistration';
import logo from '../../logo.jpg';
import AppConfig from '../../AppConfig';
import SessionUserInfo from '../../Models/SessionUserInfo';
import FavoriteParts from '../../Models/FavoriteParts'

export class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loginInfo: { userId: "", password: "" },
            errors: {},
            session: null,
        };
        this.onResetClick = this.onResetClick.bind(this);
        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onLoginClick = this.onLoginClick.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
    }

    componentDidMount() {
        if (this.props.Reducer.reset == null) {
            this.props.action.loginSetLoadingError(false);
            this.props.action.loginSetLoading(true);

            fetch(AppConfig.ApiUrlPartsLogin,
                { mode: 'cors', credentials: 'include', }
            )
                .then((response) => {
                    if (!response.ok) {
                        return response.json()
                            .then(function (err) {
                                throw new Error(err.Message);
                            });
                    }
                    this.props.action.loginSetLoading(false);
                    return response;
                })
                .then((response) => response.json())
                .then((sessionData) => {
                    if (sessionData.userInfo == null) {
                        return;
                    }

                    let loginData = new SessionUserInfo(sessionData.userInfo);

                    if (loginData.Access) {
                        this.props.action.loginResult(loginData, sessionData.shipInfo, sessionData.category, sessionData.customerNumList);
                        this.props.action.setFavoriteParts(sessionData.favoriteParts);
                        this.props.action.setIsOpenSystemRetireModal(true);
                    } else {
                        this.props.action.loginSetLoadingError(true, loginData.Message);
                    }
                })
                .catch((e) => {
                    this.props.action.loginSetLoadingError(true, e.message);
                    this.props.action.loginSetLoading(false);
                });
        }
    }

    onResetClick() {
        this.setState({ loginInfo: { userId: "", password: "" }, errors: {} });
        this.props.action.loginSetLoadingError(false);
        this.props.action.resetPasswordPage(true);
    }

    onDocumentClick(evn, data) {
        this.props.action.loginSetLoadingError(false);
        this.props.action.loginSetLoading(true);

        let url = AppConfig.ApiUrlFileDownload + "/fetchDownloadFile/?keyName=password";

        // Fetch detailed data
        fetch(url, {
            mode: 'cors', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                return response;
            })
            .then((response) => response.blob())
            .then((blob) => {
                this.props.action.loginSetLoading(false);
                saveAs(blob, "How to set new password");
            })
            .catch((e) => {
                this.props.action.loginSetLoading(false);
                this.props.action.loginSetLoadingError(true, e.message);
            });

    }

    onLoginClick() {
        this.props.action.loginSetLoadingError(false);
        let loginInfo = Object.assign(Object.create(this.state.loginInfo), this.state.loginInfo);
        this.setState({ errors: {}, loginInfo: { userId: this.state.loginInfo.userId, password: "" } });
        let errors = {};
        if (loginInfo.userId == "") {
            errors.userId = "Please enter your User ID.";
        }
        if (loginInfo.password == "") {
            errors.password = "Please enter your Password.";
        }
        if (Object.keys(errors).length > 0) {
            this.setState({ errors: errors, });
        } else {
            this.props.action.loginAuth(AppConfig.ApiUrlPartsLogin, loginInfo);
        }
    }

    onTextChange(evn, data) {
        let loginInfo = Object.assign(Object.create(this.state.loginInfo), this.state.loginInfo);
        const { name, value } = data;
        loginInfo[name] = value;

        this.setState({ loginInfo });
    }

    render() {
        let footerStyle = { marginTop: 100 };
        let styleButton = { marginTop: 20 };
        let styleButtonLink = { background: 'none', color: 'blue', };

        let isLogined = this.props.Reducer.loginData != null ? this.props.Reducer.loginData.Access : false;
        let loadingFlag = this.props.Reducer.loginIsLoading;

        let header =
            <Segment inverted color='black'>
                <Header as='h1'>Parts Order</Header>
            </Segment>;

        let footer =
            <Segment as='footer' style={footerStyle} inverted color='black'>
                <Header as='h5' textAlign='center'>
                    © 2022 - 2023 JVCKENWOOD USA Corporation. All rights reserved.
                </Header>
            </Segment>;

        let errorMessage = this.props.Reducer.loginIsLoadingError ?
            <Message negative size='big'
                icon='warning circle'
                header='Login Error occured.'
                content={this.props.Reducer.loginErrorMessage.login}
            />
            : Object.keys(this.state.errors).length > 0 ?
                <Message negative size='big'
                    icon='warning circle'
                    header='Error occured. Please fix below.'
                    content={Object.keys(this.state.errors).map(k => (<p>{this.state.errors[k]}</p>))}
                />
                : "";

        let loginForm =
            <Segment>
                {errorMessage}
                <Form size='big'>
                    <Form.Input
                        fluid
                        label='User ID'
                        name='userId'
                        icon='user'
                        iconPosition='left'
                        placeholder='User ID'
                        onChange={this.onTextChange}
                        error={this.state.errors.userId}
                        value={this.state.loginInfo.userId}
                    />
                    <Form.Input
                        fluid
                        label='Password'
                        name='password'
                        icon='lock'
                        iconPosition='left'
                        placeholder='Password'
                        type='password'
                        onChange={this.onTextChange}
                        error={this.state.errors.password}
                        value={this.state.loginInfo.password}
                    />
                    <Button color='blue' type='submit' fluid size='huge' style={styleButton} onClick={this.onLoginClick} disabled={loadingFlag}>
                        Login
                    </Button>
                    <a className='cursor-pointer' style={styleButtonLink} onClick={this.onResetClick}>Forgot password?</a>
                </Form>
            </Segment>;

        let card =
            <Segment size='big'>
                <p>Welcome to the JVCKENWOOD USA Service Website.</p>
                <p>This is a site for authorized users, only accessible for JVCKENWOOD Service Partners with a valid User ID and Password.</p>
                <p>End users:
                    please visit the <a href="https://store.jvc.com/" target="_blank" >JVC</a> or <a href="https://store.kenwoodusa.com/" target="_blank" >Kenwood</a> Accessory Store.
                </p>
            </Segment>;

        if (isLogined) {
            return (<Root />)
        } else {
            return (
                <Segment>
                    <Image src={logo} size='small' />
                    {header}
                    <Dimmer active={this.props.Reducer.loginIsLoading || this.props.Reducer.resetParamIsLoading || this.props.Reducer.resetPassIsLoading}>
                        <Loader inline='centered' size='big'>Loading ...</Loader>
                    </Dimmer>
                    <Container>
                        {this.props.Reducer.resetPasswordPage ? <ResetPassword /> :
                            this.props.Reducer.reset != null ? <PasswordRegistration /> :
                                <Grid divided columns={2}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            {loginForm}
                                        </Grid.Column>
                                        <Grid.Column>
                                            {card}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                        }
                    </Container>
                    {footer}
                </Segment>
            );
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);