import React from 'react';
import { connect } from 'react-redux';

import { Input, Header, Table, Label, Icon, Segment, Form, Button, Dropdown, Dimmer, Loader, Message, Menu } from 'semantic-ui-react';

import { mapStateToProps, mapDispatchToProps } from '../../Root.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import OrderEmpty from './OrderEmpty';
import Functions from '../../Functions';
import AppConfig from '../../AppConfig';

export class OrderList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            txtSearchInvoice: '',
            txtSearchShipOrder: '',
            txtSearchPo: '',
            txtSearchPart: '',
            selectStatus: '',
            OrderDateFromSearch: '',
            OrderDateToSearch: '',
            InvoicerDateFromSearch: '',
            InvoiceDateToSearch: '',
        };
        this.onSearchInvoice = this.onSearchInvoice.bind(this);
        this.onSearchShipOrder = this.onSearchShipOrder.bind(this);
        this.onSearchPo = this.onSearchPo.bind(this);
        this.onSearchPart = this.onSearchPart.bind(this);
        this.onSelectStatus = this.onSelectStatus.bind(this);
        this.onOrderDateFromSearchChange = this.onOrderDateFromSearchChange.bind(this);
        this.onOrderDateToSearchChange = this.onOrderDateToSearchChange.bind(this);
        this.onInvoiceDateFromSearchChange = this.onInvoiceDateFromSearchChange.bind(this);
        this.onInvoiceDateToSearchChange = this.onInvoiceDateToSearchChange.bind(this);
        this.onSearchClearClickInvoice = this.onSearchClearClickInvoice.bind(this);
        this.onSearchClearClickShipOrder = this.onSearchClearClickShipOrder.bind(this);
        this.onSearchClearClickPo = this.onSearchClearClickPo.bind(this);
        this.onSearchClearClickPart = this.onSearchClearClickPart.bind(this);
        this.onSerachClearClickAll = this.onSerachClearClickAll.bind(this);
        this.onSortByChange = this.onSortByChange.bind(this);
        this.onItemsPerPageChange = this.onItemsPerPageChange.bind(this);
        this.onPageNumClick = this.onPageNumClick.bind(this);
        this.onTransitionInvoice = this.onTransitionInvoice.bind(this);
        this.onUpdateOrderListClick = this.onUpdateOrderListClick.bind(this);
        this.onCustomerNumChange = this.onCustomerNumChange.bind(this);
        this.onGetClosedOrdersClick = this.onGetClosedOrdersClick.bind(this);
    }

    // Search condition onChange
    //#region
    onSearchInvoice(evn, data) {
        this.setState({ txtSearchInvoice: data.value });
    }

    onSearchShipOrder(evn, data) {
        this.setState({ txtSearchShipOrder: data.value });
    }

    onSearchPo(evn, data) {
        this.setState({ txtSearchPo: data.value });
    }

    onSearchPart(evn, data) {
        this.setState({ txtSearchPart: data.value });
    }

    onSelectStatus(evn, data) {
        this.setState({ selectStatus: data.value });
    }

    onOrderDateFromSearchChange(date) {
        var today = new Date();
        var threeMonthsAgoToday = today.setMonth(today.getMonth() - 3);

        if (date != null && threeMonthsAgoToday > date) {
            this.getShipTracking(date, this.props.Reducer.loginData);
        }
        this.setState({ OrderDateFromSearch: date ? date.format('YYYY/MM/DD') : date });
    }

    onOrderDateToSearchChange(date) {
        this.setState({ OrderDateToSearch: date ? date.format('YYYY/MM/DD') : date });
    }

    onInvoiceDateFromSearchChange(date) {
        var today = new Date();
        var threeMonthsAgoToday = today.setMonth(today.getMonth() - 3);
        if (date != null && threeMonthsAgoToday > date) {
            this.getShipTracking(date, this.props.Reducer.loginData);
        }
        this.setState({ InvoicerDateFromSearch: date ? date.format('YYYY/MM/DD') : date });
    }

    onInvoiceDateToSearchChange(date) {
        this.setState({ InvoiceDateToSearch: date ? date.format('YYYY/MM/DD') : date });
    }

    getShipTracking(fromDate, loginData) {
        if (fromDate < this.props.Reducer.theOldestInvoiceOrOrderDateFrom) {
            this.props.action.onFromDateChange(new Date(fromDate));

            this.props.action.loginSetLoadingError(false);
            this.props.action.sessionDataError(false);
            this.props.action.ordersFetchDataLoadingError(false);
            this.props.action.ordersFetchDataLoading(true);

            let generatedUrl = AppConfig.ApiUrlPartsOrder + "/closeOrders";

            fetch(generatedUrl, {
                mode: 'cors', method: 'post', credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'csrftoken': JSON.stringify(loginData.Token)
                },
                body: JSON.stringify(fromDate)
            })
                .then((response) => {
                    if (!response.ok) {
                        return response.json()
                            .then(function (err) {
                                throw new Error(err.Message);
                            });
                    }
                    this.props.action.ordersFetchDataLoading(false);
                    return response;
                })
                .then((response) => response.json())
                .then((orders) => {
                    let success = orders.success;
                    if (success) {
                        this.props.action.closeOrdersFetchDataSuccess(orders);
                    } else {
                        let errorMessage = "Failed to get Order data from jBASE, please contact the IT development team.";
                        this.props.action.ordersFetchDataLoadingError(true, errorMessage);
                    }
                })
                .catch((e) => {
                    this.props.action.ordersFetchDataLoadingError(true, e.message);
                    this.props.action.ordersFetchDataLoading(false);
                });
        }
    }

    onSearchClearClickInvoice() {
        this.setState({ txtSearchInvoice: '' });
    }

    onSearchClearClickShipOrder() {
        this.setState({ txtSearchShipOrder: '' });
    }

    onSearchClearClickPo() {
        this.setState({ txtSearchPo: '' });
    }

    onSearchClearClickPart() {
        this.setState({ txtSearchPart: '' });
    }

    onSerachClearClickAll() {
        this.setState({
            txtSearchInvoice: '',
            txtSearchShipOrder: '',
            txtSearchPo: '',
            txtSearchPart: '',
            selectStatus: '',
            OrderDateFromSearch: '',
            OrderDateToSearch: '',
            InvoicerDateFromSearch: '',
            InvoiceDateToSearch: '',
        })
    }
    //#endregion

    onSortByChange(evn, data) {
        this.props.action.changeSortBy(data.value);
    }

    onItemsPerPageChange(evn, data) {
        const { value, logflag, ...props } = data;
        this.props.action.changeItemsPerPage(value, Functions.toBoolean(logflag));
    }

    onPageNumClick(evn, data) {
        const { page, logflag, ...props } = data;
        this.props.action.selectPage(page, Functions.toBoolean(logflag));
    }

    onTransitionInvoice(evn, data) {
        this.props.action.loginSetLoadingError(false);
        this.props.action.sessionDataError(false);
        this.props.action.ordersFetchDataLoadingError(false);
        let orderList = [];
        let orderFrom = evn.target.attributes['data-order'].value;
        if (orderFrom == "Open") {
            orderList = this.props.Reducer.openOrderHeaders;
        } else if (orderFrom == "Close") {
            orderList = this.props.Reducer.closeOrderHeaders;
        } else {
            orderList = this.props.Reducer.orderLogs;
        }

        let OrderNumber = evn.target.attributes['data-num'].value;
        let orderData = orderList.filter(x => x.OrderNumber == OrderNumber);

        this.props.action.transitionInvoice(orderData);
    }

    onUpdateOrderListClick() {
        this.props.action.loginSetLoadingError(false);
        this.props.action.sessionDataError(false);
        this.props.action.JbasePartsOrdersByCustomer(AppConfig.ApiUrlPartsOrder, this.props.Reducer.loginData);
    }

    onCustomerNumChange(evn, data) {
        let oldSelectedNum = this.props.Reducer.selectedCustomerNum;
        let selectedCustomerNum = data.value;

        if (oldSelectedNum != selectedCustomerNum) {
            if (oldSelectedNum != '') {
                var result = window.confirm("If you change Customer#, the current input will be discarded.");
                if (result) {
                    this.props.action.loginSetLoadingError(false);
                    this.props.action.sessionDataError(false);
                    this.props.action.ordersFetchDataLoadingError(false);
                    this.props.action.setCustomerNumber(selectedCustomerNum, this.props.Reducer.loginData);
                    this.props.action.deleteSessionCart(AppConfig.ApiUrlPartsOrder, this.props.Reducer.loginData);
                    this.props.action.updateOrderData([], [], "100");
                    this.props.action.deletePartsOrders();
                    if (selectedCustomerNum != '') {
                        this.props.action.loginConnect(AppConfig.ApiUrlPartsLogin, selectedCustomerNum, true, this.props.Reducer.loginData);
                    }
                }
            } else {
                this.props.action.loginSetLoadingError(false);
                this.props.action.sessionDataError(false);
                this.props.action.ordersFetchDataLoadingError(false);
                this.props.action.setCustomerNumber(selectedCustomerNum, this.props.Reducer.loginData);
                if (selectedCustomerNum != '') {
                    this.props.action.loginConnect(AppConfig.ApiUrlPartsLogin, selectedCustomerNum, true, this.props.Reducer.loginData);
                }
            }
        }
    }

    getFilteredOrderList(orderData, logFlag) {
        let sortBy = this.props.Reducer.sortBy;
        let that = this;
        let OrderDateFrom = that.state.OrderDateFromSearch ? Date.parse(that.state.OrderDateFromSearch) : '';
        let OrderDateTo = that.state.OrderDateToSearch ? Date.parse(that.state.OrderDateToSearch) : '';
        let InvoiceDateFrom = that.state.InvoicerDateFromSearch ? Date.parse(that.state.InvoicerDateFromSearch) : '';
        let InvoiceDateTo = that.state.InvoiceDateToSearch ? Date.parse(that.state.InvoiceDateToSearch) : '';

        return orderData.filter(
            x => (
                logFlag ? OrderDateFrom ? Date.parse(x.OrderDate) >= OrderDateFrom : true : OrderDateFrom ? Date.parse(x.OrdDate) >= OrderDateFrom : true
            ) && (
                    logFlag ? OrderDateTo ? Date.parse(x.OrderDate) <= OrderDateTo : true : OrderDateTo ? Date.parse(x.OrdDate) <= OrderDateTo : true
                ) && (
                    InvoiceDateFrom ? Date.parse(x.InvDate) >= InvoiceDateFrom : true
                ) && (
                    InvoiceDateTo ? Date.parse(x.InvDate) <= InvoiceDateTo : true
                ) && (
                    that.state.txtSearchInvoice ? (x.InvoiceNumber ? x.InvoiceNumber.toString().toLowerCase().includes(that.state.txtSearchInvoice.toString().toLowerCase()) : false) : true
                ) && (
                    that.state.txtSearchPo ? x.PONumber.toString().toLowerCase().includes(that.state.txtSearchPo.toString().toLowerCase()) : true
                ) && (
                    that.state.txtSearchShipOrder ? x.OrderNumber.toString().toLowerCase().includes(that.state.txtSearchShipOrder.toString().toLowerCase()) : true
                ) && (
                    that.state.txtSearchPart ? x.ProductNumber.toString().toLowerCase().includes(that.state.txtSearchPart.toString().toLowerCase()) : true
                )
        ).sort((a, b) => {
            switch (sortBy) {
                case 'invoice-asc':
                    return a.InvoiceNumber <= b.InvoiceNumber ? -1 : 1;
                case 'invoice-desc':
                    return a.InvoiceNumber > b.InvoiceNumber ? -1 : 1;
                case 'order-asc':
                    return a.OrderNumber <= b.OrderNumber ? -1 : 1;
                case 'order-desc':
                    return a.OrderNumber > b.OrderNumber ? -1 : 1;
                case 'orderDate-asc':
                    if (logFlag) {
                        return a.OrderDate <= b.OrderDate ? -1 : 1;
                    } else {
                        return a.OrdDate <= b.OrdDate ? -1 : 1;
                    }

                case 'orderDate-desc':
                    if (logFlag) {
                        return a.OrderDate > b.OrderDate ? -1 : 1;
                    } else {
                        return a.OrdDate > b.OrdDate ? -1 : 1;
                    }
            }
            return 0;
        });
    }

    // 3ヶ月間のClosedOrderを取得する
    onGetClosedOrdersClick() {
        let today = new Date();
        var threeMonthsAgoToday = today.setMonth(today.getMonth() - 3);
        if (threeMonthsAgoToday >= this.props.Reducer.theOldestInvoiceOrOrderDateFrom) {
            alert('Already got closed orders.');
        } else {
            this.getShipTracking(new Date(threeMonthsAgoToday), this.props.Reducer.loginData);
        }
    }

    render() {
        let styleLabel = { width: '75px' }
        let styelClearButton = { marginTop: 10 };
        let styleLinkButton = { background: 'none', color: 'blue' };
        let styelUpdateButton = { marginBottom: 5 };
        let styleSearchunderBox = { width: '190px', height: '36px' };
        let styleSortByDropdown = { minWidth: 5, marginLeft: 6, marginRight: 6, };
        let stylePagemenu = { marginRight: 10, };
        let styleItemPerPageDropdown = { minWidth: 5, };
        let styleWideTable = { width: "100%", overflowX: "auto", overflowY: "hidden" };

        let openOrderData = this.state.selectStatus != 2 ? this.getFilteredOrderList(this.props.Reducer.openOrderHeaders, false) : [];
        let closeOrderData = this.state.selectStatus != 1 ? this.getFilteredOrderList(this.props.Reducer.closeOrderHeaders, false) : [];
        let orderLogData = this.state.selectStatus != 2 ? this.getFilteredOrderList(this.props.Reducer.orderLogs, true) : [];

        let itemsPerPageOptions = [
            { text: '10/page', value: 10 },
            { text: '20/page', value: 20 },
            { text: '30/page', value: 30 },
            { text: '50/page', value: 50 },
            { text: '100/page', value: 100 },
        ];

        let sortByOptions = [
            { text: 'Invoice# (ASC)', value: 'invoice-asc', }, // Invoice#
            { text: 'Invoice# (DESC)', value: 'invoice-desc', }, // Invoice#
            { text: 'ShippingOrder# (ASC)', value: 'order-asc', },
            { text: 'ShippingOrder# (DESC)', value: 'order-desc', },
            { text: 'OrderDate (ASC)', value: 'orderDate-asc', },
            { text: 'OrderDate (DESC)', value: 'orderDate-desc', },
        ];

        let customerOption = [{ value: '', text: '' }];
        this.props.Reducer.customerNumList.map(x => {
            customerOption.push({ value: x, text: x });
        });

        // Error message
        let errorMessage = this.props.Reducer.loginIsLoadingError || this.props.Reducer.sessionDataError || this.props.Reducer.ordersLoadingError ?
            <Message negative size='big'
                icon='warning circle'
                header='Login Error occured.'
                content={this.props.Reducer.orderListErrorMessage.orderList}
            /> : "";

        //Pagenation menu
        let menuItems = [];
        let currentPage = this.props.Reducer.currentPage;

        let logFlag = false;
        if (this.props.Reducer.openOrderHeaders.length < 1 && this.props.Reducer.closeOrderHeaders.length < 1) {
            logFlag = true;
        }

        let filteredOrderList = [];
        if (logFlag) {
            filteredOrderList = orderLogData;
        } else {
            filteredOrderList = openOrderData.concat(closeOrderData);
        }

        let lastPage = Math.ceil(filteredOrderList.length / this.props.Reducer.itemsPerPage);
        if (lastPage == 0) {
            lastPage = 1;
        }

        // Current page is out of range
        if (lastPage < this.props.Reducer.currentPage) {
            this.props.action.selectPage(lastPage, logFlag);
        }

        let prevPage = currentPage == 1 ? 1 : currentPage - 1;
        let nextPage = currentPage == lastPage ? lastPage : currentPage + 1;
        let wasDot = false;

        // Page number
        let activeItem = this.props.Reducer.currentPage;
        let pageAround = 2;       //How many pages around current page are shown

        for (let i = 1; i <= lastPage; i++) {
            if (i == 1 || i == lastPage || i == currentPage || Math.abs(i - currentPage) <= pageAround) {
                menuItems.push(
                    <Menu.Item key={i} page={i} active={activeItem == i} logflag={logFlag.toString()} onClick={this.onPageNumClick} >
                        {i}
                    </Menu.Item>
                );
                wasDot = false;
            } else if (!wasDot) {
                menuItems.push(
                    <Menu.Item key={i}>
                        {'...'}
                    </Menu.Item>
                );
                wasDot = true;
            }
        }

        // page
        let pageMenu = <Menu pagination style={stylePagemenu}>
            {menuItems}
        </Menu>;

        // Check if in page
        let itemFrom = (this.props.Reducer.currentPage - 1) * this.props.Reducer.itemsPerPage;
        let itemTo = (this.props.Reducer.currentPage) * this.props.Reducer.itemsPerPage - 1;
        let openIdx = 0;

        // dropdown
        let itemsStatusOptions = [
            { text: 'All', value: '0' }, { text: 'Open', value: '1' }, { text: 'Close', value: '2' },
        ];

        // table
        let openOrderTable = openOrderData.length > 0 ?
            openOrderData.map((x, idx) => {
                return x.Details.map((y, yidx) => {
                    openIdx = idx + yidx;
                    if (itemFrom <= openIdx && openIdx <= itemTo) {
                        let orderQty = "";
                        if (x.OrderQty) {
                            orderQty = x.OrderQty;
                        } else {
                            orderQty = (Number(x.ShippedQty) + Number(x.BackOrderQty)).toString();
                        }
                        return <Table.Row key={yidx}>
                            <Table.Cell ><Label ribbon color='green'><h3 style={styleLabel}><Icon name="check" />Open</h3></Label></Table.Cell>
                            <Table.Cell >{x.InvoiceNumber ? x.InvoiceNumber : <div style={{ textAlign: "center" }}>-</div>}</Table.Cell>
                            <Table.Cell ><a className="shipping-number" style={styleLinkButton} onClick={this.onTransitionInvoice} id="order-number" data-num={x.OrderNumber} data-order={"Open"}>{x.OrderNumber}</a></Table.Cell>
                            <Table.Cell >{x.PONumber}</Table.Cell>
                            <Table.Cell >{x.ProductNumber}</Table.Cell>
                            <Table.Cell >{x.ShippedQty}</Table.Cell>
                            <Table.Cell >{x.BackOrderQty}</Table.Cell>
                            <Table.Cell >{Functions.dollarForm(Functions.toNumberToFixed(x.Cost * orderQty))}</Table.Cell>
                            <Table.Cell >{x.InvDate != null ? Functions.dateUsForm(x.InvDate) : <div style={{ textAlign: "center" }}>-</div>}</Table.Cell>
                            <Table.Cell >{x.OrdDate != null ? Functions.dateUsForm(x.OrdDate) : <div style={{ textAlign: "center" }}>-</div>}</Table.Cell>
                            <Table.Cell >{y.ShipTrackingNumber ? y.ShipTrackingNumber : <div style={{ textAlign: "center" }}>-</div>}</Table.Cell>
                        </Table.Row>
                    }
                    return null;
                })
            })
            : <Table.Row ></Table.Row>;

        let closeOrderTable = closeOrderData.length > 0 ?
            closeOrderData.map((x, idx) => {
                return x.Details.map((y, yidx) => {
                    let closeIdx = openIdx + 1 + idx + yidx;
                    if (itemFrom <= closeIdx && closeIdx <= itemTo) {
                        let orderQty = "";
                        if (x.OrderQty) {
                            orderQty = x.OrderQty;
                        } else {
                            orderQty = (Number(x.ShippedQty) + Number(x.BackOrderQty)).toString();
                        }
                        let shipTrackingNumber = <div style={{ textAlign: "center" }}>-</div>;
                        if (y.ShipTrackingNumber) {
                            let index = y.ShipTrackingNumber.indexOf(",");
                            if (index != -1) {
                                shipTrackingNumber = y.ShipTrackingNumber.substring(0, index) + "...";
                            } else {
                                shipTrackingNumber = y.ShipTrackingNumber;
                            }
                        }
                        return <Table.Row key={idx}>
                            <Table.Cell ><Label ribbon color='black'><h3 style={styleLabel}><Icon name="wait" />Close</h3></Label></Table.Cell>
                            <Table.Cell >{x.InvoiceNumber}</Table.Cell>
                            <Table.Cell ><a className="shipping-number" style={styleLinkButton} onClick={this.onTransitionInvoice} id="order-number" data-num={x.OrderNumber} data-order={"Close"}>{x.OrderNumber}</a></Table.Cell>
                            <Table.Cell >{x.PONumber}</Table.Cell>
                            <Table.Cell >{x.ProductNumber}</Table.Cell>
                            <Table.Cell >{x.ShippedQty}</Table.Cell>
                            <Table.Cell >{x.BackOrderQty}</Table.Cell>
                            <Table.Cell >{Functions.dollarForm(Functions.toNumberToFixed(x.Cost * orderQty))}</Table.Cell>
                            <Table.Cell >{x.InvDate != null ? Functions.dateUsForm(x.InvDate) : <div style={{ textAlign: "center" }}>-</div>}</Table.Cell>
                            <Table.Cell >{x.OrdDate != null ? Functions.dateUsForm(x.OrdDate) : x.OrderDate != null ? Functions.dateUsForm(x.OrderDate) : <div style={{ textAlign: "center" }}>-</div>}</Table.Cell>
                            <Table.Cell >{shipTrackingNumber}</Table.Cell>
                        </Table.Row>
                    }
                    return null;
                })
            })
            : <Table.Row ></Table.Row>;

        let orderLogTable = this.props.Reducer.openOrderHeaders.length < 1 && this.props.Reducer.closeOrderHeaders.length < 1 && orderLogData.length > 0 ?
            orderLogData.map((x, idx) => {
                if (itemFrom <= idx && idx <= itemTo) {
                    let orderQty = "";
                    if (x.ShippedQty && x.BackOrderQty) {
                        orderQty = (Number(x.ShippedQty) + Number(x.BackOrderQty)).toString();
                    }
                    return <Table.Row key={idx}>
                        <Table.Cell ><Label ribbon color='green'><h3 style={styleLabel}><Icon name="check" />Open</h3></Label></Table.Cell>
                        <Table.Cell ><div style={{ textAlign: "center" }}>-</div></Table.Cell>
                        <Table.Cell ><a className="shipping-number" style={styleLinkButton} onClick={this.onTransitionInvoice} id="order-number" data-num={x.OrderNumber} data-order={"Log"}>{x.OrderNumber}</a></Table.Cell>
                        <Table.Cell >{x.PONumber}</Table.Cell>
                        <Table.Cell >{x.ProductNumber}</Table.Cell>
                        <Table.Cell ><div style={{ textAlign: "center" }}>-</div></Table.Cell>
                        <Table.Cell ><div style={{ textAlign: "center" }}>-</div></Table.Cell>
                        <Table.Cell >{Functions.dollarForm(Functions.toNumberToFixed(x.Cost * orderQty))}</Table.Cell>
                        <Table.Cell ><div style={{ textAlign: "center" }}>-</div></Table.Cell>
                        <Table.Cell >{x.OrderDate != null ? Functions.dateUsForm(x.OrderDate) : <div style={{ textAlign: "center" }}>-</div>}</Table.Cell>
                        <Table.Cell ><div style={{ textAlign: "center" }}>-</div></Table.Cell>
                    </Table.Row>
                }
                return null;
            })
            : <Table.Row ></Table.Row>;

            let content = <OrderEmpty />;
            if (!(!this.props.Reducer.loginIsLoading && !this.props.Reducer.ordersLoading && this.props.Reducer.openOrderHeaders.length <= 0 && this.props.Reducer.closeOrderHeaders.length <= 0 && this.props.Reducer.orderLogs.length <= 0)) {
                content =<div style={styleWideTable}>
                    <Table size='large' className='listTable'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell className='listTableStatus'><Header as='h3'>Status</Header></Table.HeaderCell>
                                <Table.HeaderCell className='listTableCell'><Header as='h3'>Invoice #</Header></Table.HeaderCell>
                                <Table.HeaderCell className='listTableCell'><Header as='h3'>Shipping Order #</Header></Table.HeaderCell>
                                <Table.HeaderCell className='listTablePo'><Header as='h3'>PO #</Header></Table.HeaderCell>
                                <Table.HeaderCell className='listTablePo'><Header as='h3'>Part #</Header></Table.HeaderCell>
                                <Table.HeaderCell className='listTableQty'><Header as='h3'>Qty</Header></Table.HeaderCell>
                                <Table.HeaderCell className='listTableTotal'><Header as='h3'>Back Qty</Header></Table.HeaderCell>
                                <Table.HeaderCell className='listTableTotal'><Header as='h3'>Subtotal</Header></Table.HeaderCell>
                                <Table.HeaderCell className='listTableCell'><Header as='h3'>Invoice Date</Header></Table.HeaderCell>
                                <Table.HeaderCell className='listTableCell'><Header as='h3'>Order Date</Header></Table.HeaderCell>
                                <Table.HeaderCell className='listTableShip'><Header as='h3'>Ship Tracking #</Header></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body className="wordBreak">
                            {openOrderTable}
                            {closeOrderTable}
                            {orderLogTable}
                        </Table.Body>
                    </Table>
                </div>;
            }

        return (
            <div>
                {this.props.Reducer.customerNumList.length != 1 ?
                    <h3>
                        <Dropdown
                            search selection placeholder='Select Customer#'
                            options={customerOption}
                            value={this.props.Reducer.selectedCustomerNum}
                            onChange={this.onCustomerNumChange}
                        />
                    </h3>
                    : ""}

                <Header color='grey' as='h1' dividing>
                    Order List
                </Header>
                <Dimmer active={this.props.Reducer.ordersLoading || this.props.Reducer.loginIsLoading}>
                    <Loader inline='centered' size='big'>Loading ...</Loader>
                </Dimmer>
                {errorMessage}
                <Segment>
                    <Header as='h2'>
                        Search Condition
                    </Header>
                    <Form>
                        <Form.Group>
                            <Form.Field>
                                <label><Header as='h4'>Invoice #</Header></label>
                                <Input
                                    style={styleSearchunderBox}
                                    value={this.state.txtSearchInvoice}
                                    onChange={this.onSearchInvoice}
                                    icon={<Icon name='delete' link
                                        onClick={this.onSearchClearClickInvoice} />}
                                    className="condition"
                                />
                            </Form.Field>
                            <Form.Field>
                                <label><Header as='h4'>Shipping Order #</Header></label>
                                <Input
                                    style={styleSearchunderBox}
                                    value={this.state.txtSearchShipOrder}
                                    onChange={this.onSearchShipOrder}
                                    icon={<Icon name='delete' link
                                        onClick={this.onSearchClearClickShipOrder} />}
                                    className="condition"
                                />
                            </Form.Field>
                            <Form.Field>
                                <label><Header as='h4'>PO #</Header></label>
                                <Input
                                    style={styleSearchunderBox}
                                    value={this.state.txtSearchPo}
                                    onChange={this.onSearchPo}
                                    icon={<Icon name='delete' link
                                        onClick={this.onSearchClearClickPo} />}
                                    className="condition"
                                />
                            </Form.Field>
                            <Form.Field>
                                <label><Header as='h4'>Part #</Header></label>
                                <Input
                                    style={styleSearchunderBox}
                                    value={this.state.txtSearchPart}
                                    onChange={this.onSearchPart}
                                    icon={<Icon name='delete' link
                                        onClick={this.onSearchClearClickPart} />}
                                    className="condition"
                                />
                            </Form.Field>
                            <Form.Field>
                                <label><Header as='h4'>Status</Header></label>
                                <Dropdown
                                    search selection
                                    style={styleSearchunderBox}
                                    value={this.state.selectStatus}
                                    options={itemsStatusOptions}
                                    onChange={this.onSelectStatus}
                                    className="condition"
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field>
                                <label><Header as='h4'>Order Date From</Header></label>
                                <label>
                                    <DatePicker name='OrderDateFrom'
                                        placeholderText='Select Date'
                                        isClearable={true}
                                        dateFormat="MM/DD/YYYY"
                                        selected={this.state.OrderDateFromSearch ? moment(Functions.dateForm(this.state.OrderDateFromSearch)) : null}
                                        onChange={this.onOrderDateFromSearchChange}
                                    />
                                    <Icon className='calendar-icon' name='calendar alternate outline' />
                                </label>
                            </Form.Field>
                            <Form.Field>
                                <label><Header as='h4'>Order Date To</Header></label>
                                <label>
                                    <DatePicker name='OrderDateTo'
                                        placeholderText='Select Date'
                                        isClearable={true}
                                        dateFormat="MM/DD/YYYY"
                                        selected={this.state.OrderDateToSearch ? moment(Functions.dateForm(this.state.OrderDateToSearch)) : null}
                                        onChange={this.onOrderDateToSearchChange}
                                    />
                                    <Icon className='calendar-icon' name='calendar alternate outline' />
                                </label>
                            </Form.Field>
                            <Form.Field>
                                <label><Header as='h4'>Invoice Date From</Header></label>
                                <label>
                                    <DatePicker name='InvoiceDateFrom'
                                        placeholderText='Select Date'
                                        isClearable={true}
                                        dateFormat="MM/DD/YYYY"
                                        selected={this.state.InvoicerDateFromSearch ? moment(Functions.dateForm(this.state.InvoicerDateFromSearch)) : null}
                                        onChange={this.onInvoiceDateFromSearchChange}
                                    />
                                    <Icon className='calendar-icon' name='calendar alternate outline' />
                                </label>
                            </Form.Field>
                            <Form.Field>
                                <label><Header as='h4'>Invoice Date To</Header></label>
                                <label>
                                    <DatePicker name='InvoiceDateTo'
                                        placeholderText='Select Date'
                                        isClearable={true}
                                        dateFormat="MM/DD/YYYY"
                                        selected={this.state.InvoiceDateToSearch ? moment(Functions.dateForm(this.state.InvoiceDateToSearch)) : null}
                                        onChange={this.onInvoiceDateToSearchChange}
                                    />
                                    <Icon className='calendar-icon' name='calendar alternate outline' />
                                </label>
                            </Form.Field>
                            <Form.Field><Button color='red' size='big' icon='x' labelPosition='right' content='All Clear' style={styelClearButton} onClick={this.onSerachClearClickAll} /></Form.Field>
                        </Form.Group>
                    </Form>
                </Segment>

                <Button circular size='mini' icon='arrow left' page={prevPage} logflag={logFlag.toString()} onClick={this.onPageNumClick} />
                <Button circular size='mini' icon='arrow right' page={nextPage} logflag={logFlag.toString()} onClick={this.onPageNumClick} />
                {pageMenu}
                <Dropdown
                    placeholder='Items/page'
                    value={this.props.Reducer.itemsPerPage}
                    selection style={styleItemPerPageDropdown}
                    options={itemsPerPageOptions}
                    logflag={logFlag.toString()}
                    onChange={this.onItemsPerPageChange}
                />

                <Dropdown
                    placeholder='Sort by'
                    value={this.props.Reducer.sortBy}
                    selection
                    style={styleSortByDropdown}
                    options={sortByOptions}
                    onChange={this.onSortByChange}
                />

                <Button color='green' icon='redo' content='Update' style={styelUpdateButton} onClick={this.onUpdateOrderListClick} />

                <Button color='green' icon='download' content='Get Closed Orders' style={styelUpdateButton} onClick={this.onGetClosedOrdersClick} />
                <Label basic color='blue' pointing='left'>
                    If you need closed order data, please press the button.
                </Label>

                {content}

            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderList);
