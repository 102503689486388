"use strict";
export default class {
    constructor(dataObject = null) {
        this.Id = null;
        this.CustomerNumber = '';
        this.OrderDate = '';
        this.OrderNumber = null;
        this.PONumber = '';
        this.ProductNumber = '';
        this.Cost = 0;
        this.OrderQty = null;
        this.ReserveQty = null;
        this.ShipCharge = 0;
        this.ShipVia = '';
        this.ShipTo = '';
        this.Total = 0;

        //Import data if set
        if (dataObject) {
            try {
                this.Id = dataObject.id;
                this.CustomerNumber = dataObject.customerNumber;
                this.OrderDate = dataObject.orderDate;
                this.OrderNumber = dataObject.orderNumber;
                this.PONumber = dataObject.pONumber;
                this.ProductNumber = dataObject.productNumber;
                this.Cost = dataObject.cost;
                this.OrderQty = dataObject.orderQty;
                this.ReserveQty = dataObject.reserveQty;
                this.ShipCharge = dataObject.shipCharge;
                this.ShipVia = dataObject.shipVia;
                this.ShipTo = dataObject.shipTo;
                this.Total = dataObject.total;
            }
            catch (e) {
                throw "Data import error @ OrderLog data.";
            }
        }
    }

    getDataObject() {
        return {
            Id: this.Id,
            CustomerNumber: this.CustomerNumber,
            OrderDate: this.OrderDate,
            OrderNumber: this.OrderNumber,
            PONumber: this.PONumber,
            ProductNumber: this.ProductNumber,
            Cost: this.Cost,
            OrderQty: this.OrderQty,
            ReserveQty: this.ReserveQty,
            ShipCharge: this.ShipCharge,
            ShipVia: this.ShipVia,
            ShipTo: this.ShipTo,
            Total: this.Total,
        }
    }
}