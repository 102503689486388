"use strict";
export default class {
    constructor(dataObject = null) {
        this.Shpcde = '';
        this.Shpvia = '';
        this.Shpchg = '';

        //Import data if set
        if (dataObject) {
            try {
                this.Shpcde = dataObject.shpcde;
                this.Shpvia = dataObject.shpvia;
                this.Shpchg = dataObject.shpchg;
            }
            catch (e) {
                throw "Data import error @ ShipVia data.";
            }
        }
    }

    getDataObject() {
        return {
            Shpcde: this.Shpcde,
            Shpvia: this.Shpvia,
            Shpchg: this.Shpchg,
        }
    }
}