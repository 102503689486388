import React from 'react';
import { connect } from 'react-redux';
import AppConfig from '../../AppConfig.js';
import { saveAs } from 'file-saver'

import { Header, Segment, Button, Table, Message, Dimmer, Loader } from 'semantic-ui-react';

import { mapStateToProps, mapDispatchToProps } from '../../Root.js';
import 'react-datepicker/dist/react-datepicker.css';

export class FileDownload extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            files: [],

            loading: false,
            loadingError: false,
            errorMessage: "",
        };

        this.fetchFiles = this.fetchFiles.bind(this);
        this.fileSize = this.fileSize.bind(this);
        this.setData = this.setData.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
    }

    componentDidMount() {
        this.fetchFiles();
    }

    fetchFiles() {
        this.setState({ loading: true, loadingError: false, errorMessage: '' });

        let url = AppConfig.ApiUrlFileDownload + "/fetchFile";
        let loginData = this.props.Reducer.loginData;

        // Fetch detailed data
        fetch(url, {
            mode: 'cors', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(loginData.Token)
            },
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                return response;
            })
            .then((response) => response.json())
            .then((response) => {
                let files = [];
                files.push(this.setData(response.audio));
                files.push(this.setData(response.comms));
                this.setState({
                    files: files,
                    loading: false,
                });
            })
            .catch((e) => {
                this.setState({
                    loading: false,
                    loadingError: true,
                    errorMessage: e.message,
                });
            });
    }

    fileSize(size) {
        if (size / Math.pow(1024, 3) > 1) {
            size = Math.round(size / Math.pow(1024, 3) * 100) / 100;
            return String(size) + "GB";
        } else if (size / Math.pow(1024, 2) > 1) {
            size = Math.round(size / Math.pow(1024, 2) * 100) / 100;
            return String(size) + "MB";
        } else if (size / 1024 > 1) {
            size = Math.round(size / 1024 * 100) / 100;
            return String(size) + "KB";
        } else {
            return String(size) + "B";
        }
    }

    setData(file) {
        return {
            keyName: file.keyName,
            displayName: file.displayName,
            fileName: file.fileName,
            size: this.fileSize(file.size),
            updateDate: file.updateDate,
        }
    }

    onDownloadClick(evn, data) {
        this.setState({ loading: true, loadingError: false, errorMessage: '' });

        let url = AppConfig.ApiUrlFileDownload + "/fetchDownloadFile/?keyName=" + data.keyName;
        let loginData = this.props.Reducer.loginData;

        // Fetch detailed data
        fetch(url, {
            mode: 'cors', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(loginData.Token)
            },
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                return response;
            })
            .then((response) => response.blob())
            .then((blob) => {
                this.setState({ loading: false });
                saveAs(blob, data.fileName);
            })
            .catch((e) => {
                this.setState({ loading: false });
                alert(e.message);
            });
    }

    render() {
        let errorMessage = this.state.errorMessage != "" ?
            <Message negative size='big'
                icon='warning circle'
                content={this.state.errorMessage}
            /> : "";

        let tableBody = this.state.files.map(file => {
            return (
                <Table.Row>
                    <Table.Cell>{file.displayName}</Table.Cell>
                    <Table.Cell>{file.size}</Table.Cell>
                    <Table.Cell>{file.updateDate}</Table.Cell>
                    <Table.Cell textAlign='center'>
                        <Button primary onClick={this.onDownloadClick} keyName={file.keyName} fileName={file.fileName}>
                            Download</Button>
                    </Table.Cell>
                </Table.Row>
            );
        })

        return (
            <div>
                <Dimmer active={this.state.loading}>
                    <Loader inline='centered' size='big'>Loading ...</Loader>
                </Dimmer>

                <Header color='grey' as='h1' dividing>
                    File Download
                </Header>
                {errorMessage}
                <Segment>
                    <Header as='h2'>Resources</Header>
                    <Table celled hidden={this.state.loadingError}>
                        <Table.Header>
                            <Table.Row >
                                <Table.HeaderCell><Header as='h3'>File Name</Header></Table.HeaderCell>
                                <Table.HeaderCell><Header as='h3'>Size</Header></Table.HeaderCell>
                                <Table.HeaderCell><Header as='h3'>Updated</Header></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                            {tableBody}
                        </Table.Header>
                    </Table>
                </Segment>
            </div>
        )
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FileDownload);
