import React, { Component } from 'react';
import { Provider } from 'react-redux';
import createFinalStore from './store';

import Login from './Components/Login/Login'

const store = createFinalStore();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Login />
      </Provider>
    );
  }
}

export default App;
