class AppConfig {
}

AppConfig.ApiUrl = process.env.REACT_APP_API_URL;
AppConfig.Url = process.env.REACT_APP_URL;

// Fetch data url
AppConfig.ApiUrlPartsLogin = AppConfig.ApiUrl + "/partsOrderLogin";
AppConfig.ApiUrlContactUs = AppConfig.ApiUrl + "/contactUs";
AppConfig.ApiUrlPartsOrder = AppConfig.ApiUrl + "/partsOrder";
AppConfig.ApiUrlFileDownload = AppConfig.ApiUrl + "/FileDownload";
AppConfig.ApiUrlFavoriteParts = AppConfig.ApiUrl + "/FavoriteParts";

// connect wcf
AppConfig.ApiUrlJbasePartsOpenOrdersByCustomer = AppConfig.ApiUrl + "/jbasePartsOpenOrdersByCustomer";
AppConfig.ApiUrlJbasePartsClosedOrdersByCustomer = AppConfig.ApiUrl + "/jbasePartsClosedOrdersByCustomer";
AppConfig.ApiUrlJbaseRunPartsSearch = AppConfig.ApiUrl + "/jbaseRunPartsSearch";

export default AppConfig;