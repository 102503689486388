import React from 'react';
import { connect } from 'react-redux';

import { Header, Table, Icon, Button, Dimmer, Loader, Message } from 'semantic-ui-react';

import { mapStateToProps, mapDispatchToProps } from '../../Root.js';
import 'react-datepicker/dist/react-datepicker.css';
import Functions from '../../Functions';
import AppConfig from '../../AppConfig';
import ShippingInformation from '../../Models/jbaseOrderDetails/ShippingInformation.js';

export class Invoice extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orderData: null,
            shippingInformation: null,

            loading: true,
            loadingError: false,
            errorMessage: "",
        };
        this.onPrintClick = this.onPrintClick.bind(this);
        this.onBackToOrderList = this.onBackToOrderList.bind(this);
        this.JbaseOrderDetailsByOrderNumber = this.JbaseOrderDetailsByOrderNumber.bind(this);
    }

    componentDidMount() {
        this.props.action.favoritePartsUpdatingError(false);
        this.JbaseOrderDetailsByOrderNumber();
        this.setState({
            orderData: Object.assign(Object.create(this.props.orderData), this.props.orderData),
        });
    }

    onPrintClick() {
        var userAgent = window.navigator.userAgent.toLowerCase();
        if (userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
            document.execCommand('print', false, null);
        } else if (userAgent.indexOf('chrome') != -1) {
            window.print();
        } else if (userAgent.indexOf('firefox') != -1) {
            window.print();
        } else {
            alert('Your browser is not supported for this print function.\n Please use IE, Chrome or Firefox.');
        }
    }

    onBackToOrderList() {
        this.props.action.favoritePartsUpdatingError(false);
        this.props.action.backToOrderList();
    }

    JbaseOrderDetailsByOrderNumber() {
        let patchData = {
            companyId: this.props.Reducer.loginData.Company,
            orderNumber: this.props.orderData[0].OrderNumber,
        };

        fetch(AppConfig.ApiUrlPartsOrder + '/orderDetails', {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(this.props.Reducer.loginData.Token)
            },
            body: JSON.stringify(patchData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                this.setState({ loading: false });
                return response;
            })
            .then(response => response.json())
            .then(response => {
                let shippingInformation = new ShippingInformation(response);
                this.setState({ shippingInformation });
            })
            .catch(e => {
                this.setState({
                    loading: false,
                    loadingError: true,
                    errorMessage: e.message,
                });
            });
    }

    render() {
        let styleTableWide = { width: 120 };
        let stylePrintButton = { marginTop: 20 }

        // init
        let orderData = this.state.orderData;
        let headerData = orderData ? orderData[0] : "";
        let shipTo = "-";
        let shipVia = "-";
        if (this.state.shippingInformation) {
            let shippingInformation = this.state.shippingInformation;

            // Ship To
            let index = shippingInformation.ShipToNumber.indexOf("-");
            let shipToNumber = index != -1 ? shippingInformation.ShipToNumber.substring(index + 1) : "-";
            shipTo = `${shipToNumber} : ${shippingInformation.ShipToName}(${shippingInformation.Address} ${shippingInformation.City}, ${shippingInformation.State} ${shippingInformation.ZipCode})`;

            // Ship Via
            shipVia = `${shippingInformation.ShipVia}($${shippingInformation.Amount})`;
        }
        let shipTrackingNumbers = [];
        if (orderData) {
            orderData.forEach(order => {
                if (order.Details) {
                    order.Details.forEach(detail => {
                        let shipTrackingNumber = detail.ShipTrackingNumber;
                        if (!shipTrackingNumbers.find(x => x == shipTrackingNumber) && shipTrackingNumber != null) {
                            shipTrackingNumbers.push(shipTrackingNumber);
                        }
                    })
                }
            });
        }

        // error message
        let errorMessage = "";
        if (this.state.loadingError) {
            errorMessage = <Message negative size='big' icon='warning circle' content={this.state.errorMessage} />;
        } else if (this.props.Reducer.favoritePartsUpdatingError) {
            errorMessage = <Message negative size='big' icon='warning circle' content={this.props.Reducer.orderListErrorMessage.invoice} />
        }

        // Calculate cost
        let shipTotal = 0;
        let shipAvg = 0;
        let subTotal = 0;
        let orderTotal = 0;
        if (orderData != null) {
            orderData.map((x) => {
                let orderQty = x.OrderQty != null ? x.OrderQty : (Number(x.ShippedQty) + Number(x.BackOrderQty)).toString();
                shipTotal = Functions.decimalAddition(shipTotal, x.ShipCharge);
                subTotal = Functions.decimalAddition(subTotal, Functions.decimalMultiplication(x.Cost, orderQty));
            })

            let orderLength = orderData.length;
            shipAvg = Functions.decimalDivision(shipTotal, orderLength);
            orderTotal = Functions.decimalAddition(subTotal, shipAvg);
        }

        return (
            <div>
                <Dimmer active={this.state.loading}>
                    <Loader inline='centered' size='big'>Loading ...</Loader>
                </Dimmer>

                <Header color='grey' as='h1' dividing>
                    Shipping Order # : {headerData ? headerData.OrderNumber : ""}
                </Header>
                {errorMessage}
                <Table definition size='large'>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell width={4}>Order Status</Table.Cell>
                            <Table.Cell width={12} >{headerData.OrderStatus ? headerData.OrderStatus : "Open"}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell width={4}>P.O. #</Table.Cell>
                            <Table.Cell width={12} >{headerData ? headerData.PONumber : "-"}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Ship To</Table.Cell>
                            <Table.Cell>{shipTo}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Ship Via</Table.Cell>
                            <Table.Cell>{shipVia}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell width={4}>Invoice Number</Table.Cell>
                            <Table.Cell width={12} >{headerData.InvoiceNumber ? headerData.InvoiceNumber : "-"}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Invoice Date</Table.Cell>
                            <Table.Cell>{headerData.InvDate ? Functions.dateUsForm(headerData.InvDate) : "-"}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Ship Tracking #</Table.Cell>
                            <Table.Cell>
                                {shipTrackingNumbers.length != 0 ? shipTrackingNumbers.join(", ") : "-"}
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>

                <Table size='large'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell><Header as='h3'>Part#</Header></Table.HeaderCell>
                            <Table.HeaderCell><Header as='h3'>Price</Header></Table.HeaderCell>
                            <Table.HeaderCell><Header as='h3'>OrderQty</Header></Table.HeaderCell>
                            <Table.HeaderCell><Header as='h3'>ShipQty</Header></Table.HeaderCell>
                            <Table.HeaderCell><Header as='h3'>BackQty</Header></Table.HeaderCell>
                            <Table.HeaderCell><Header as='h3'>Subtotal</Header></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {orderData ? orderData.map((x, idx) => {
                            let orderQty = "";
                            if (x.OrderQty) {
                                orderQty = x.OrderQty;
                            } else {
                                orderQty = (Number(x.ShippedQty) + Number(x.BackOrderQty)).toString();
                            }

                            return (
                                <Table.Row key={idx}>
                                    <Table.Cell>{x.ProductNumber ? x.ProductNumber : "-"}</Table.Cell>
                                    <Table.Cell>{x.Cost != null ? Functions.dollarForm(Functions.toNumberToFixed(x.Cost)) : "-"}</Table.Cell>
                                    <Table.Cell>{orderQty != "" ? orderQty : "-"}</Table.Cell>
                                    <Table.Cell>{x.ShippedQty != null ? x.ShippedQty : "-"}</Table.Cell>
                                    <Table.Cell>{x.BackOrderQty != null ? x.BackOrderQty : "-"}</Table.Cell>
                                    <Table.Cell>{Functions.dollarForm(Functions.toNumberToFixed(x.Cost * orderQty))}</Table.Cell>
                                </Table.Row>
                            )
                        }) : <Table.Row></Table.Row>}
                    </Table.Body>
                </Table>

                <div className='flex-floatRight'>
                    <Table color='black' collapsing size='large'>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell><Header as='h3'>Subtotal</Header></Table.Cell>
                                <Table.Cell style={styleTableWide} textAlign='right'>
                                    <Header as='h3'>{Functions.dollarForm(Functions.toNumberToFixed(subTotal))}</Header>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell><Header as='h3'>Shipping</Header></Table.Cell>
                                <Table.Cell style={styleTableWide} textAlign='right'>
                                    <Header as='h3'>{Functions.dollarForm(Functions.toNumberToFixed(shipAvg))}</Header>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell><Header as='h2'>Order Total</Header></Table.Cell>
                                <Table.Cell style={styleTableWide} textAlign='right'>
                                    <Header as='h2'>{Functions.dollarForm(Functions.toNumberToFixed(orderTotal))}</Header>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
                <div className='flex-floatRight'>
                    <Button size='huge' onClick={this.onPrintClick} style={stylePrintButton}>
                        <Icon name='print' />Print
                    </Button>
                    <Button color='blue' size='huge' onClick={this.onBackToOrderList} style={stylePrintButton}>
                        <Icon name='arrow left' />Back
                    </Button>
                </div>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Invoice);
